import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from '../Sidebar/sidebar.component';
import Header from '../Header/header.component';
import CustomFunctions from '../../helper/customFunctions';

function Layout(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(
    useSelector((state) => state.auth.isLoggedIn)
  );
  const { children } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('Login', () => {
      dispatch({ type: 'LOGIN', value: false });
      setIsLoggedIn(false);
    });
  }, [isLoggedIn]);

  const code = CustomFunctions.getLocalStorage('access_token');
  useEffect(() => {
    if (code) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    dispatch({ type: 'LOGIN', value: false });
  }, [code]);

  return (
    <>
      {isLoggedIn ? (
        <div className="admin-portal-leftright-sidebar">
          <Sidebar />
          <div className="main-content side-content pt-0">
            <Header />
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default Layout;
