import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Card, Form, Col, Button, Row } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
// import generatepassword from 'generate-password';
import swal from 'sweetalert';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import { TextInput, Helmet, PhoneInput, PasswordInput } from '../../components/FormInputs';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb.component'; //eslint-disable-line
import CustomFunctions from '../../helper/customFunctions';

const validator = new SimpleReactValidator();

const PersonalDetails = (props) => {
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [tutorId, setTutorId] = useState('');
  const [count, forceUpdate] = useState(0);
  const [viewType, setViewType] = useState(false);
  const [urlSegment, setUrlSegment] = useState('');
  const [items, setItems] = useState(''); //eslint-disable-line
  const [title, setTitle] = useState('');
  const [emergencyContactName, setEmergencyContactName] = useState('');
  const [emergencyContactNumber, setEmergencyContactNumber] = useState('');
  const [address, setAddress] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordErr, setConfirmPasswordErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');

  useEffect(() => {
    async function getData() {
      const id = props.match.params.tutorId;
      if (id) {
        setTitle(id ? 'Edit Manage Tutors' : 'Add Manage Tutors');
        setTutorId(id);
        getTutorData(id);
      }

      setUrlSegment(props.match.url.split('/'));

      const breadCrumb = [
        { to: '/', label: 'Home' },
        { to: '/tutor', label: 'Tutors' },
        { label: id ? 'Edit' : 'Add' },
      ];
      setItems(breadCrumb);

      if (urlSegment && urlSegment.includes('tutorViews')) {
        setViewType(true);
      }
    }
    getData();
  }, []);

  const getTutorData = async (id) => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.tutorUrl}${id}/`
    );

    if (!CustomFunctions.checkIfEmpty(data.data, 'O')) {
      setFirstName(data.data.first_name);
      setLastName(data.data.last_name);
      setEmail(data.data.email);
      setMobile(data.data.mobile);
      setAddress(data.data.emergency_contact_address);
      setEmergencyContactName(data.data.emergency_contact_name);
      setEmergencyContactNumber(data.data.emergency_contact_number);
      setUserName(data.data.username);
    } else {
      swal(message, '', 'error');
    }
  };

  const updateTutor = async () => {
    // const password = generatepassword.generate({
    //   length: 10,
    //   numbers: true,
    // });

    const postData = {
      first_name: firstName,
      last_name: lastName,
      email,
      mobile,
      emergency_contact_name: emergencyContactName,
      emergency_contact_number: emergencyContactNumber,
      emergency_contact_address: address,
      username:userName
    };

    if(password){
      postData.new_password = password;
    }
    if(confirmPassword){
      postData.confirm_password = confirmPassword;
    }

  //  if (!tutorId) postData.password = password;

    const payload = CustomFunctions.cleanObject(postData);

    const callback = tutorId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = tutorId ? `${Api.tutorUrl}${tutorId}/` : `${Api.tutorUrl}`;
    const { data, message } = await callback(endPoint, payload);
    if (data) {
      validator.hideMessages();
      swal(data && data.message[0], '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result) {
          setDisableButton(false);
          history.push('/tutor');
        }
      });
    } else {
      setDisableButton(false);
      swal(message, '', 'error', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);

    let isValid = true;
    let confirmPassErr = '';
    let passErr = '';

     if(password && !confirmPassword){
          confirmPassErr = 'Please enter confirm password';
          setConfirmPasswordErr(confirmPassErr);
          isValid = false;
        }else{
          setConfirmPasswordErr('');
          isValid = true;
        }

        if(!password && confirmPassword){
          passErr = 'Please enter new password';
          setPasswordErr(passErr);
          isValid = false;
        }else {
          setPasswordErr('');
          isValid = true;
        }

        if(password && confirmPassword && password !== confirmPassword ){
          confirmPassErr = 'New Password and Confirm Password did not match';
          setConfirmPasswordErr(confirmPassErr);
          isValid = false;
        }

    if (validator.allValid() && isValid) {
      updateTutor();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };
  const onCancel = () => {
    validator.hideMessages();
    history.push('/tutor');
  };

  return (
    <>
      <Helmet title={title} />
      <Card>
        <Card.Body>
          <Form autoComplete="off" onSubmit={(e) => submitForm(e)}>
            <Row>
              <Col sm={6}>
                <TextInput
                  label="First Name"
                  name="firstName"
                  containerClass="form-group"
                  placeholder="First Name"
                  value={firstName || ''}
                  asterisk="*"
                  disabled={viewType ? 'disabled' : ''}
                  onInputChange={(e) => setFirstName(e.target.value)}
                  errorMessage={validator.message(
                    'firstName',
                    firstName,
                    'required|alpha_space'
                  )}
                />
              </Col>

              <Col sm={6}>
                <TextInput
                  name="lastName"
                  label="Last Name"
                  containerClass="form-group"
                  placeholder="Last Name"
                  asterisk="*"
                  value={lastName || ''}
                  disabled={viewType ? 'disabled' : ''}
                  onInputChange={(e) => setLastName(e.target.value)}
                  errorMessage={validator.message(
                    'lastName',
                    lastName,
                    'required|alpha_space'
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <TextInput
                  label="Email Address"
                  name="emailAddress"
                  containerClass="form-group"
                  placeholder="Email Address"
                  asterisk="*"
                  value={email || ''}
                  onInputChange={(e) => setEmail(e.target.value)}
                  errorMessage={validator.message(
                    'email',
                    email,
                    'required|email'
                  )}
                />
              </Col>
              <Col sm={6}>
                  <TextInput
                    label="Username"
                    name="username"
                    asterisk="*"
                    placeholder="Username"
                    value={userName || ''}
                    onInputChange={(e) => setUserName(e.target.value)}
                    disabled={viewType || tutorId ? 'disabled' : ''}
                    errorMessage={validator.message(
                      'userName',
                      userName,
                      'required|regex:^(?=.*)(?=.*[A-Za-z ])[A-Za-z0-9 ]*$',
                      {
                        messages: {
                          required: 'The Username field is required',
                          regex:
                          'Username should be either alphabet or alphanumeric.',
                        },
                      }
                    )}
                  />
              </Col>
            </Row>
            {
              tutorId && (
              <>
                <Row>
                 <Col md={6}>
                    <PasswordInput
                        onInputChange={(e) => setPassword(e.target.value)}
                        name="password"
                        optionalLabel='(Note: Keep empty If you dont want to change password)'
                        containerClass="form-group"
                        inputClass="form-control"
                        label="New Password"
                        value={password || ''}
                        type="password"
                        placeholder="Password"
                        errorMessage={passwordErr || '' }
                        switchElement="true"
                      />

                  </Col>
                   <Col md={6}>
                     <PasswordInput
                       onInputChange={(e) => setConfirmPassword(e.target.value)}
                       name="confirmPassword"
                       containerClass="form-group"
                       inputClass="form-control"
                       label="Confirm Password"
                       value={confirmPassword || ''}
                       type="password"
                       placeholder="Confirm Password"
                       errorMessage={confirmPasswordErr || '' }
                       switchElement="true"
                     />
                   </Col>
                 </Row>
                 </>
            )}
            <Row>
            <Col sm={6}>
                <div className="contact-number">
                  <PhoneInput
                    label="Mobile"
                    name="mobile"
                    asterisk="*"
                    country="gb"
                    onlyCountries={['in', 'gb']}
                    placeholder="Mobile"
                    value={mobile || ''}
                    onInputChange={(e) => setMobile(e)}
                    errorMessage={validator.message(
                      'mobile',
                      mobile,
                      'required|phone|min:12'
                    )}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <TextInput
                  label="Emergency Contact Name"
                  name="emergencyContactName"
                  asterisk="*"
                  containerClass="form-group"
                  placeholder="Emergency Contact Name"
                  value={emergencyContactName || ''}
                  onInputChange={(e) => setEmergencyContactName(e.target.value)}
                  errorMessage={validator.message(
                    'emergencyContactName',
                    emergencyContactName,
                    'required|alpha_space'
                  )}
                />
              </Col>

            </Row>
            <Row>
            <Col sm={6}>
                <div className="contact-number">
                  <PhoneInput
                    label="Emergency Contact Number"
                    name="emergencyContactNumber"
                    asterisk="*"
                    country="gb"
                    onlyCountries={['in', 'gb']}
                    placeholder="Emergency Contact Number"
                    value={emergencyContactNumber || ''}
                    onInputChange={(e) => setEmergencyContactNumber(e)}
                    errorMessage={validator.message(
                      'emergencyContactNumber',
                      emergencyContactNumber,
                      'required|phone|min:12'
                    )}
                  />
                </div>
              </Col>
              <Col sm={6}>
                <TextInput
                  label="Address"
                  name="address"
                  asterisk="*"
                  containerClass="form-group"
                  placeholder="Address"
                  value={address || ''}
                  onInputChange={(e) => setAddress(e.target.value)}
                  errorMessage={validator.message(
                    'address',
                    address,
                    'required'
                  )}
                  Row={5}
                  as="textarea"
                />
              </Col>
            </Row>
            <div className="float-end">
              <Button variant="secondary" onClick={() => onCancel()}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="ms-4"
                disabled={disableButton}
              >
                {tutorId ? ' Update' : 'Add'}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default withRouter(PersonalDetails);
