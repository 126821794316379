import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import menu from '../../helper/menu';
import UserRestrictions from '../UserRestrictions/userrestrictions.component';
import CustomFunctions from '../../helper/customFunctions';

function Sidebar() {
  const history = useHistory();
  const [activeMenu, setActiveMenu] = useState(0);
  const [activeSubPath, setActiveSubPath] = useState(0);
  const [userRole, setUserRole] = useState('');
  const onSubActiveClass = () => {
    if (activeSubPath !== window.location.pathname)
      setActiveSubPath(window.location.pathname);
  };
  const dispatch = useDispatch();
  const event = new Event('Login', { isLogin: false });

  useEffect(() => {
    const uRole = CustomFunctions.getLocalStorage('role_id');
    setUserRole(Number(uRole));
  }, []);

  return (
    <>
      <div className="main-sidebar main-sidebar-sticky side-menu ps">
        <div className="sidemenu-logo">
          <a className="main-logo" href="/">
            <img
              src="/images/ptl logo_white.png"
              className="header-brand-img desktop-logo svg"
              alt="logo"
            />
          </a>
        </div>
        <div className="main-sidebar-body">
          <ul className="nav">
            {menu.map((menuItem, menuIndex) => {
              const hasSubMenu =
                menuItem.submenu && menuItem.submenu.length > 0;
              const parentLiCls = `nav-item ${
                menuIndex === 0 || window.location.pathname === menuItem.path
                  ? 'show'
                  : ''
              }`;
              const parentActiveCls =
                (activeMenu === menuIndex &&
                  window.location.pathname === menuItem.path) ||
                window.location.pathname === menuItem.path ||
                (window.location.pathname.includes(menuItem.path) &&
                  menuItem.path.length > 1)
                  ? 'show active'
                  : '';
              const parentLinkCls = `nav-link ${hasSubMenu ? 'with-sub' : ''}`;
              return (
                <UserRestrictions
                  permittedUsers={menuItem.roleaccess}
                  roleId={userRole}
                >
                  <li className={`${parentLiCls} ${parentActiveCls}`}>
                    <Link
                      className={parentLinkCls}
                      to={
                        menuItem.redirect !== 'undefined' &&
                        menuItem.redirect === false
                          ? false
                          : menuItem.path
                      }
                      onClick={() => {
                        if (menuItem.title === 'Logout') {
                          CustomFunctions.clearLocalStorage();
                          history.push('/login');
                          document.dispatchEvent(event);
                          dispatch({ type: 'LOGIN', value: false });
                        }
                        setActiveMenu(menuIndex);
                      }}
                    >
                      <img
                        src={`/images/${menuItem.icon}`}
                        alt="Logout"
                        className="svg"
                      />
                      <span className="sidemenu-label">{menuItem.title}</span>
                    </Link>
                    {hasSubMenu && (
                      <ul className="nav-sub list-unstyled">
                        {menuItem.submenu.map((submenuItem) => {
                          return (
                            <li onClick={() => { onSubActiveClass(); }} // eslint-disable-line
                              className={`nav-sub-item ${
                                submenuItem.path === window.location.pathname
                                  ? 'active'
                                  : ''
                              }`}
                            >
                              <Link
                                className="nav-sub-link"
                                to={submenuItem.path}
                              >
                                {submenuItem.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                </UserRestrictions>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
