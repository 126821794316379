import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import CustomFunctions from '../../../helper/customFunctions';
import {
  TextInput,
  Helmet,
  PhoneInput,
  DropdownInput,
  RadioInput,
} from '../../../components/FormInputs';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import UserRestrictions from '../../../components/UserRestrictions/userrestrictions.component';

const validator = new SimpleReactValidator();
const studentValidator = new SimpleReactValidator();
const guardianValidator = new SimpleReactValidator();

const event = new Event('ProfileUpdate', { isUpdated: true });
const items = [
  { to: '/', label: 'Home' },
  { to: '/profile/edit', label: 'Edit Profile' },
];

const additionalChoices = [
  { value: 1, name: 'Ongoing classes' },
  { value: 2, name: '11 Plus Mock Exams' },
  {
    value: 3,
    name: 'Summer camp ( An introduction to the 11 Plus programme for Year 4 and 5)',
  },
  { value: 4, name: 'Easter Break (Year 11 Exam Paper practice)"' },
];

const examFocusList = [
  { value: '11 Plus', name: '11 Plus' },
  { value: '13 Plus', name: '13 Plus' },
  { value: 'GCSE', name: 'GCSE' },
  { value: 'IGCSE', name: 'IGCSE' },
];

const learningDisabilitiesList = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const allergiesList = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const knowPPLTreeLearning = [
  { value: 'Google', name: 'Google' },
  { value: 'Facebook', name: 'Facebook' },
  { value: 'Leaflet', name: 'Leaflet' },
  { value: 'Library', name: 'Library' },
  { value: 'Bus Panels', name: 'Bus Panels' },
  { value: 'Friends/Family', name: 'Friends/Family' },
  { value: 'Other', name: 'Other' },
  { value: 'E-Leaflet', name: 'E-Leaflet' },
  { value: 'Primary Times', name: 'Primary Times' },
  { value: 'Other', name: 'Other' },
];

function EditProfile() {
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [count, forceUpdate] = useState(0);
  const [title, setTitle] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [address, setAddress] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [guardianName, setGuardianName] = useState('');
  const [guardianNo, setGuardianContactNo] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [examFocus, setExamFocus] = useState(null);
  const [allergies, setAllergies] = useState();
  const [learningDisabilities, setLearningDisabilities] = useState();
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [gcseIcse, setGcseIcse] = useState('');
  const [pplTreeLearning, setPPLTreeLearningValue] = useState();
  const [activationDate, setActivateDate] = useState('');
  const [guardianEmail, setGuardianEmail] = useState('');
  const [subjectTopics, setSubjectTopics] = useState('');
  const [userRole, setUserRole] = useState();
  const [selectOptionYear, setSelectOptionYear] = useState(null);
  const [yearList, setYearList] = useState([]);
  const [studentId, setStudentId] = useState(null);
  const [showDisabilityText, setShowDisabilityText] = useState(false);
  const [showAllergiesText, setShowAllergiesText] = useState(false);
  const [disabilityText, setDisabilityText] = useState('');
  const [allergiesText, setAllergiesText] = useState('');
  const [showOtherPTLText, setShowOtherPTLText] = useState(false);
  const [otherPTLText, setOtherPTLText] = useState('');
  const [tutorSubjectList, setTutorSubjectList] = useState(''); //eslint-disable-line
  const [userName, setUserName] = useState('');

  const getProfile = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.getProfileByIdUrl}`
    );

    if (!CustomFunctions.checkIfEmpty(data.data, 'O') && userRole !== 4) {
      setFirstName(data.data.first_name);
      setLastName(data.data.last_name);
      setUserEmail(data.data.email);
      setUserMobile(data.data.mobile);
      setUserName(data.data.username);
    } else if (Number(userRole) === 4) {
      const ptlLearnArray = [];
      const examFocusArray = [];
      await getStudentSubjectTutor(data.data.class);
      setLastName(data.data.last_name);
      setFirstName(data.data.first_name);
      setEmailAddress(data.data.email);
      setContactNo(data.data.mobile);
      setGuardianName(data.data.g_name);
      setGuardianContactNo(data.data.g_contact);
      setAddress(data.data.address);
      setSchoolName(data.data.school_name);
      setSelectOptionYear({
        label: data.data.class_name,
        id: data.data.class,
        value: data.data.class,
      });
      setGcseIcse(data.data.sub_attention);
      if (data.data.exam_focus) {
        setExamFocus({
          label: data.data.exam_focus,
          id: data.data.exam_focus,
          value: data.data.exam_focus,
        });
      }
      if (data.data.exam_focus && data.data.exam_focus.length > 0) {
        data.data.exam_focus &&
          data.data.exam_focus.map((focus) => {
            return examFocusArray.push({
              label: focus,
              id: focus,
              value: focus,
            });
          });
        setExamFocus(examFocusArray);
      }
      if (data.data.additional_details) {
        const result = additionalChoices.find(
          (ad) => ad.value === data.data.additional_details
        );
        setAdditionalDetails({
          label: result ? result.name : '',
          id: data.data.additional_details,
          value: data.data.additional_details,
        });
      }
      if (data.data.peepal_tree_learn.length > 0) {
        data.data.peepal_tree_learn &&
          data.data.peepal_tree_learn.map((hear) => {
            return ptlLearnArray.push({
              label: hear,
              id: hear,
              value: hear,
            });
          });
        setPPLTreeLearningValue(ptlLearnArray);
      }
      setAllergies(`${data.data.allergies}`);
      setLearningDisabilities(`${data.data.learning_disable}`);
      setGuardianEmail(data.data.g_email);
      setActivateDate(
        data.data.activation_date
          ? CustomFunctions.getDate(data.data.activation_date)
          : ''
      );
      setSubjectTopics(data.data.subject_topics);
      if (data.data.learning_disable === true) {
        setShowDisabilityText('true');
        setDisabilityText(`${data.data.reg_known_disabilities}`);
      }
      if (data.data.allergies === true) {
        setShowAllergiesText('true');
        setAllergiesText(`${data.data.reg_known_allergies}`);
      }
      if (data.data.peepal_tree_learn_other) {
        setShowOtherPTLText(true);
        setOtherPTLText(`${data.data.peepal_tree_learn_other}`);
      }
      setUserName(data.data.username);
    } else {
      swal(data && data.message[0], '', 'error').then((result) => {
        if (result) {
          history.push('/');
        }
      });
    }
  };

  const updateProfileData = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: userEmail,
      mobile: userMobile,
      username: userName
    };
    const postData = CustomFunctions.cleanObject(payload);
    const { data, message } = await AuthApi.putDataToServer(
      `${Api.getProfileByIdUrl}`,
      postData
    );
    if (data) {
      swal(data && data.message[0], '', 'success').then((result) => {
        if (result) {
          setDisableButton(false);
          document.dispatchEvent(event);
          history.push('/');
        }
      });
    } else {
      setDisableButton(false);
      swal(message, '', 'error');
    }
  };

  const updateStudentProfileData = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      address,
      mobile: contactNo,
      email: emailAddress,
      g_name: guardianName,
      g_contact: guardianNo,
      learning_disable: learningDisabilities,
      allergies,
      peepal_tree_learn: pplTreeLearning.id,
      school_name: schoolName,
      classes: selectOptionYear.id,
      exam_focus: examFocus.id,
      additional_details: additionalDetails ? additionalDetails.id : '',
      sub_attention: gcseIcse,
      g_email: guardianEmail,
      subject_topics: subjectTopics,
      username:userName
    };

    if (learningDisabilities) payload.reg_known_disabilities = disabilityText;
    if (allergies) payload.reg_known_allergies = allergiesText;
    if (showOtherPTLText) payload.peepal_tree_learn_other = otherPTLText;

    const postData = CustomFunctions.cleanObject(payload);
    const { data, message } = await AuthApi.putDataToServer(
      `${Api.getProfileByIdUrl}`,
      postData
    );
    if (data) {
      swal(data && data.message[0], '', 'success').then((result) => {
        if (result) {
          setDisableButton(false);
          document.dispatchEvent(event);
          history.push('/');
        }
      });
    } else {
      setDisableButton(false);
      swal(message, '', 'error');
    }
  };

  useEffect(() => {
    setTitle('Edit Profile');
    const uRole = CustomFunctions.getLocalStorage('role_id');
    if (!CustomFunctions.checkIfEmpty(uRole)) {
      setUserRole(Number(uRole));
    }
    const userid = CustomFunctions.getLocalStorage('id');
    if (!CustomFunctions.checkIfEmpty(userid)) {
      setStudentId(Number(userid));
      getExamFocusList();
    }
    getYearList();
  }, []);

  useEffect(() => {
    if (userRole) getProfile();
  }, [userRole]);

  const cancel = () => {
    setUserMobile('');
    setFirstName('');
    setLastName('');
    setUserEmail('');
    history.push('/profile');
  };

  const updateProfile = (e) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);

    if (validator.allValid()) {
      updateProfileData();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const updateStudentProfile = (e) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (studentValidator.allValid()) {
      updateStudentProfileData();
    } else {
      studentValidator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const getYearList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.schoolYearUrl}?status=1`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setYearList(data.data);
    }
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList.map((mat) => {
      return yearOptions.push({
        label: mat.name,
        id: mat.id,
        value: mat.id,
      });
    });
    return yearOptions;
  };

  const getAdditionalChoices = () => {
    if (!additionalChoices) return [];
    const choicesOptions = [];
    additionalChoices.map((tech) => {
      return choicesOptions.push({
        label: tech.name,
        id: tech.value,
        value: tech.value,
      });
    });
    return choicesOptions;
  };

  const getExamFocusList = () => {
    if (!examFocusList) return [];
    const examFocusListOptions = [];
    examFocusList.map((alg) => {
      return examFocusListOptions.push({
        label: alg.name,
        id: alg.value,
        value: alg.value,
      });
    });
    return examFocusListOptions;
  };

  const getLearningDisabilities = () => {
    if (!learningDisabilitiesList) return [];
    const learningDisabilitiesOptions = [];
    learningDisabilitiesList.map((alg) => {
      return learningDisabilitiesOptions.push({
        label: alg.label,
        value: alg.value,
      });
    });
    return learningDisabilitiesOptions;
  };

  const getAllergies = () => {
    if (!allergiesList) return [];
    const allergiesOptions = [];
    allergiesList.map((alg) => {
      return allergiesOptions.push({
        label: alg.label,
        value: alg.value,
      });
    });
    return allergiesOptions;
  };

  const getKnowPPLTreeLearning = () => {
    if (!knowPPLTreeLearning) return [];
    const pplTreeLearningOptions = [];
    knowPPLTreeLearning.map((learn) => {
      return pplTreeLearningOptions.push({
        label: learn.name,
        id: learn.value,
        value: learn.value,
      });
    });
    return pplTreeLearningOptions;
  };

  const getStudentSubjectTutor = async (classid) => {
    const tutorSubjectListOptions = [];
    return new Promise(async (resolve) => {
      const { data } = await AuthApi.getDataFromServer(
        `${Api.studentTutorSubjectUrl}?id=${studentId}&class_id=${classid}`
      );

      if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
          data.data.map((stud) => {
            return tutorSubjectListOptions.push({
              subject_name: stud.subject_name,
              tutor_name: stud.tutor_full_name,
            });
          });
          setTutorSubjectList(tutorSubjectListOptions);
          resolve();
      } else {
          setTutorSubjectList('');
          resolve([]);
      }
    });
  };

  const updateGuardianProfile = (e) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);

    if (guardianValidator.allValid()) {
      updateGuardianProfileData();
    } else {
      guardianValidator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const updateGuardianProfileData = async () => {
    const payload = {
      first_name: firstName,
      mobile: userMobile,
      username: userName
    };

    const postData = CustomFunctions.cleanObject(payload);
    const { data, message } = await AuthApi.putDataToServer(
      `${Api.getProfileByIdUrl}`,
      postData
    );
    if (data) {
      swal(data && data.message[0], '', 'success').then((result) => {
        if (result) {
          setDisableButton(false);
          document.dispatchEvent(event);
          history.push('/');
        }
      });
    } else {
      setDisableButton(false);
      swal(message, '', 'error');
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Edit Profile</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Card>
        <Card.Header>
          <h4 className="text-primary mt-2 mb-2">Edit Profile</h4>
        </Card.Header>
        <UserRestrictions permittedUsers={[1, 2, 3]} roleId={userRole}>
          <Card.Body>
            <Form autoComplete="off" onSubmit={(e) => updateProfile(e)}>
              <Row>
                <h2 className="profile-title">
                  {firstName || ''} {lastName || ''}
                </h2>
                <Form.Label className="mt-4">
                  <h5>Personal Information</h5>
                </Form.Label>
                <Col sm={6}>
                  <Form.Label>First Name</Form.Label>
                  <TextInput
                    onInputChange={(e) => setFirstName(e.target.value)}
                    name="firstName"
                    containerClass="form-group"
                    inputClass="form-control"
                    value={firstName || ''}
                    placeholder="Enter your First Name"
                    errorMessage={validator.message(
                      'firstName',
                      firstName,
                      'required'
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Last Name</Form.Label>
                  <TextInput
                    onInputChange={(e) => setLastName(e.target.value)}
                    name="lastName"
                    containerClass="form-group"
                    inputClass="form-control"
                    value={lastName || ''}
                    placeholder="Enter your Last Name"
                    errorMessage={validator.message(
                      'lastName',
                      lastName,
                      'required'
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Label>Email address</Form.Label>
                  <TextInput
                    onInputChange={(e) => setUserEmail(e.target.value)}
                    name="userEmail"
                    containerClass="form-group"
                    inputClass="form-control"
                    type="email"
                    value={userEmail || ''}
                    placeholder="Enter your email"
                    errorMessage={validator.message(
                      'email',
                      userEmail,
                      'required|email'
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Username</Form.Label>
                  <TextInput
                    onInputChange={(e) => setUserEmail(e.target.value)}
                    name="userName"
                    containerClass="form-group"
                    inputClass="form-control"
                    value={userName || ''}
                    placeholder="Enter your username"
                    disabled
                  />
                </Col>
              </Row>
              <Row>
              <Col md={6}>
                  <div className="contact-number">
                    <PhoneInput
                      label="Contact No."
                      country="gb"
                      onlyCountries={['in', 'gb']}
                      placeholder="Enter your Mobile"
                      value={userMobile || ''}
                      onInputChange={(e) => setUserMobile(e)}
                      errorMessage={validator.message(
                        'mobile',
                        userMobile,
                        'required|phone|min:12'
                      )}
                    />
                  </div>
                </Col>
              </Row>
              <div className="float-end">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => cancel()}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="ms-4"
                  type="submit"
                  disabled={disableButton}
                >
                  Update
                </Button>
              </div>
            </Form>
          </Card.Body>
        </UserRestrictions>
        <UserRestrictions permittedUsers={[5]} roleId={userRole}>
          <Card.Body>
            <Form autoComplete="off" onSubmit={(e) => updateGuardianProfile(e)}>
              <Row>
                <h2 className="profile-title">
                  {firstName || ''}
                </h2>
                <Form.Label className="mt-4">
                  <h5>Personal Information</h5>
                </Form.Label>
                <Col sm={6}>
                  <TextInput
                    label="Full Name"
                    onInputChange={(e) => setFirstName(e.target.value)}
                    name="firstName"
                    containerClass="form-group"
                    inputClass="form-control"
                    value={firstName || ''}
                    placeholder="Enter your First Name"
                    errorMessage={guardianValidator.message(
                      'Full name',
                      firstName,
                      'required'
                    )}
                    asterisk="*"
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    label="Username"
                    name="userName"
                    containerClass="form-group"
                    placeholder="Username"
                    value={userName || ''}
                    asterisk="*"
                    disabled
                />
                </Col>
              </Row>
              <Row>
              <Col sm={6}>
                  <Form.Label>Email address</Form.Label>
                  <TextInput
                    onInputChange={(e) => setUserEmail(e.target.value)}
                    name="userEmail"
                    containerClass="form-group"
                    inputClass="form-control"
                    type="email"
                    value={userEmail || ''}
                    placeholder="Enter your email"
                    disabled
                  />
                </Col>
              <Col sm={6}>
                  <div className="contact-number">
                    <PhoneInput
                      label="Contact No."
                      country="gb"
                      onlyCountries={['in', 'gb']}
                      placeholder="Enter your Mobile"
                      value={userMobile || ''}
                      onInputChange={(e) => setUserMobile(e)}
                      errorMessage={guardianValidator.message(
                        'Contact number',
                        userMobile,
                        'required|phone|min:12'
                      )}
                      asterisk="*"
                    />
                  </div>
                </Col>
              </Row>
              <div className="float-end">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => cancel()}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="ms-4"
                  type="submit"
                  disabled={disableButton}
                >
                  Update
                </Button>
              </div>
            </Form>
          </Card.Body>
        </UserRestrictions>
        <UserRestrictions permittedUsers={[4]} roleId={userRole}>
          <Card.Body>
            <Form autoComplete="off" onSubmit={(e) => updateStudentProfile(e)}>
              <Row>
                <Col>
                  <h2 className="profile-title">
                    {firstName || ''} {lastName || ''}
                  </h2>
                  <div className="d-flex align-items-center justify-content-between mt-4">
                    <h5 className="mb-0">Personal Details</h5>
                    {activationDate !== '' && (
                      <>
                        <div className="p-r-2 font-weight-semibold float-end">
                          Activation Date : {activationDate || ''}
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <TextInput
                    label="First Name"
                    name="firstName"
                    containerClass="form-group"
                    placeholder="First Name"
                    value={firstName || ''}
                    onInputChange={(e) => setFirstName(e.target.value)}
                    asterisk="*"
                    errorMessage={studentValidator.message(
                      'firstName',
                      firstName,
                      'required|alpha_space'
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    containerClass="form-group"
                    placeholder="Last Name"
                    value={lastName || ''}
                    onInputChange={(e) => setLastName(e.target.value)}
                    asterisk="*"
                    errorMessage={studentValidator.message(
                      'lastName',
                      lastName,
                      'required|alpha_space'
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <TextInput
                    label="Email Address"
                    name="emailAddress"
                    containerClass="form-group"
                    placeholder="Email Address"
                    value={emailAddress || ''}
                    onInputChange={(e) => setEmailAddress(e.target.value)}
                    asterisk="*"
                    errorMessage={studentValidator.message(
                      'emailAddress',
                      emailAddress,
                      'required|email'
                    )}
                  />
                </Col>
              </Row>
              <Row>
              <Col sm={3}>
                <TextInput
                    label="Username"
                    name="userName"
                    containerClass="form-group"
                    placeholder="Username"
                    value={userName || ''}
                    onInputChange={(e) => setUserName(e.target.value)}
                    asterisk="*"
                    disabled={studentId ? 'disabled' : ''}
                />
                </Col>
                <Col sm={3}>
                  <div className="contact-number">
                    <PhoneInput
                      label="Contact No"
                      name="contactNo"
                      asterisk="*"
                      country="gb"
                      onlyCountries={['in', 'gb']}
                      placeholder="Contact No"
                      value={contactNo || ''}
                      onInputChange={(e) => setContactNo(e)}
                      errorMessage={studentValidator.message(
                        'contactNo',
                        contactNo,
                        'required|phone|min:12'
                      )}
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <TextInput
                    label="Address"
                    name="address"
                    containerClass="form-group"
                    placeholder="Address"
                    value={address || ''}
                    Row={5}
                    as="textarea"
                    onInputChange={(e) => setAddress(e.target.value)}
                    asterisk="*"
                    errorMessage={studentValidator.message(
                      'address',
                      address,
                      'required'
                    )}
                    disabled
                  />
                </Col>

              </Row>
              <Row>
                <Col>
                  <TextInput
                    label="Parent/Guardian Name"
                    name="guardianName"
                    containerClass="form-group"
                    placeholder="Parent/Guardian Name"
                    value={guardianName || ''}
                    onInputChange={(e) => setGuardianName(e.target.value)}
                    asterisk="*"
                    errorMessage={studentValidator.message(
                      'guardianName',
                      guardianName,
                      'required|alpha_space'
                    )}
                    disabled
                  />
                </Col>
                <Col>
                  <div className="contact-number">
                    <PhoneInput
                      label="Guardian Contact No"
                      name="guardianNo"
                      asterisk="*"
                      country="gb"
                      onlyCountries={['in', 'gb']}
                      placeholder="Parent/Guardian Contact No"
                      value={guardianNo || ''}
                      onInputChange={(e) => setGuardianContactNo(e)}
                      errorMessage={studentValidator.message(
                        'guardianNo',
                        guardianNo,
                        'required|phone|min:12'
                      )}
                      disabled
                    />
                  </div>
                </Col>
                <Col>
                  <TextInput
                    label="Parent/Guardian Email"
                    name="guardianEmail"
                    containerClass="form-group"
                    placeholder="Parent/Guardian Email"
                    value={guardianEmail || ''}
                    asterisk="*"
                    onInputChange={(e) => setGuardianEmail(e.target.value)}
                    errorMessage={studentValidator.message(
                      'guardianEmail',
                      guardianEmail,
                      'required|email'
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h5>Academic Details</h5>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <TextInput
                    label="Name of School"
                    name="schoolName"
                    placeholder="Name of School"
                    asterisk="*"
                    value={schoolName || ''}
                    onInputChange={(e) => setSchoolName(e.target.value)}
                    errorMessage={studentValidator.message(
                      'schoolName',
                      schoolName,
                      'required'
                    )}
                    disabled
                  />
                </Col>
                <Col sm={6}>
                  <DropdownInput
                    label="School Year"
                    options={getYearData() || []}
                    placeholder="Select School Year"
                    asterisk="*"
                    defaultValue={selectOptionYear || ''}
                    onSelectHandler={(select) => setSelectOptionYear(select)}
                    errorMessage={studentValidator.message(
                      'School Year',
                      selectOptionYear,
                      'required'
                    )}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <TextInput
                    label="Subject topics that requires further attention"
                    name="subjectTopics"
                    containerClass="form-group"
                    placeholder=""
                    value={subjectTopics || ''}
                    Row={5}
                    as="textarea"
                    onInputChange={(e) => setSubjectTopics(e.target.value)}
                    disabled
                  />
                </Col>
                <Col sm={6}>
                  <DropdownInput
                    label="Additional Details Required For"
                    options={getAdditionalChoices() || []}
                    name="additionalDetails"
                    asterisk="*"
                    placeholder="Select..."
                    defaultValue={additionalDetails || ''}
                    onSelectHandler={(select) => setAdditionalDetails(select)}
                    errorMessage={studentValidator.message(
                      'Additional Details',
                      additionalDetails,
                      'required'
                    )}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <DropdownInput
                    label="Exam Focus"
                    options={getExamFocusList() || []}
                    placeholder="Select ..."
                    defaultValue={examFocus || ''}
                    asterisk="*"
                    onSelectHandler={(select) => setExamFocus(select)}
                    errorMessage={studentValidator.message(
                      'Exam Focus',
                      selectOptionYear,
                      'required'
                    )}
                    isMulti
                    disabled
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>
                    GCSE/IGCSE Subject Board(if applicable)
                  </Form.Label>
                  <TextInput
                    name="gcseIcse"
                    containerClass="form-group"
                    placeholder=""
                    value={gcseIcse || ''}
                    onInputChange={(e) => setGcseIcse(e.target.value)}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h5>Other Details</h5>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Label>Any Known Learning Disabilities</Form.Label>
                  <span style={{ color: 'red' }}> *</span>
                  <div>
                    <RadioInput
                      onSelectRadioButton={(e) => {
                        setLearningDisabilities(e.target.value);
                      }}
                      name="learningDisabilities"
                      type="radio"
                      value={learningDisabilities || ''}
                      checked={learningDisabilities}
                      options={getLearningDisabilities()}
                      inputClass="form-check-inline"
                      asterisk="*"
                      errorMessage={studentValidator.message(
                        'Learning disability',
                        learningDisabilities,
                        'required'
                      )}
                      disabled
                    />
                    {showDisabilityText === 'true' && (
                      <TextInput
                        name="disabilityText"
                        containerClass="form-group"
                        placeholder=""
                        value={disabilityText}
                        onInputChange={(e) => setDisabilityText(e.target.value)}
                        errorMessage={
                          showDisabilityText === 'true'
                            ? validator.message(
                                'disabilityText',
                                disabilityText,
                                'required',
                                {
                                  messages: {
                                    required:
                                      'Known learn disability is required',
                                  },
                                }
                              )
                            : ''
                        }
                        disabled
                      />
                    )}
                  </div>
                </Col>
                <Col sm={6}>
                  <Form.Label>
                    Any Known Allergies/ Current Medication{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <div>
                    <RadioInput
                      onSelectRadioButton={(e) => {
                        setAllergies(e.target.value);
                      }}
                      name="Allergies"
                      type="radio"
                      value={allergies || ''}
                      checked={allergies}
                      options={getAllergies() || []}
                      inputClass="form-check-inline"
                      errorMessage={studentValidator.message(
                        'allergies',
                        allergies,
                        'required'
                      )}
                      disabled
                    />
                    {showAllergiesText === 'true' && (
                      <TextInput
                        name="allergiesText"
                        containerClass="form-group"
                        placeholder=""
                        value={allergiesText}
                        onInputChange={(e) => setAllergiesText(e.target.value)}
                        disabled
                        errorMessage={
                          showAllergiesText === 'true'
                            ? validator.message(
                                'allergiesText',
                                allergiesText,
                                'required',
                                {
                                  messages: {
                                    required:
                                      'Known allergy/current medication is required',
                                  },
                                }
                              )
                            : ''
                        }
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <DropdownInput
                      label="Where did Student hear of Peepal Tree Learning?"
                      options={getKnowPPLTreeLearning() || []}
                      placeholder="Select ..."
                      defaultValue={pplTreeLearning || ''}
                      onSelectHandler={(select) =>
                        setPPLTreeLearningValue(select)
                      }
                      asterisk="*"
                      isMulti
                      errorMessage={studentValidator.message(
                        'Where did Student hear of Peepal Tree Learning',
                        pplTreeLearning,
                        'required'
                      )}
                      disabled
                    />
                    {showOtherPTLText === true && (
                      <TextInput
                        name="otherPTLText"
                        containerClass="form-group"
                        placeholder=""
                        value={otherPTLText}
                        onInputChange={(e) => setOtherPTLText(e.target.value)}
                        disabled
                        errorMessage={
                          showOtherPTLText === true
                            ? validator.message(
                                'otherPTLText',
                                otherPTLText,
                                'required',
                                {
                                  messages: {
                                    required: 'This field is required',
                                  },
                                }
                              )
                            : ''
                        }
                      />
                    )}
                  </div>
                </Col>
              </Row>
              {tutorSubjectList.length > 0 && (
              <>
              <Row className="mt-3">
                  <Col sm={12}>
                  <h5>Class Details</h5>
                  <hr />
                  </Col>
              </Row>
              <Row className="mt-3 align-items-center font-weight-semibold">
                <Col sm={3}>
                    Subject
                </Col>
                <Col sm={3}>
                    Tutor
                </Col>
              </Row>
              { tutorSubjectList && tutorSubjectList.map((tutor)=>(
                <>
                  <Row className="mt-3 align-items-center">
                    <Col sm={3}>
                      {tutor.subject_name}
                    </Col>
                    <Col sm={3}>
                      {tutor.tutor_name}
                    </Col>
                  </Row>
                </>
              ))}
              </>
              )}
              <div className="float-end">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => cancel()}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="ms-4"
                  type="submit"
                  disabled={disableButton}
                >
                  Update
                </Button>
              </div>
            </Form>
          </Card.Body>
        </UserRestrictions>
      </Card>
      <section className="content-area" />
    </>
  );
}

export default withRouter(EditProfile);
