const menu = [
  {
    title: 'Dashboard',
    path: '/',
    icon: 'dashboard.svg',
    submenu: [],
    roleaccess: [1, 2, 3, 4],
  },
  {
    title: 'Students',
    path: '/student',
    icon: 'icon_3.svg',
    roleaccess: [1, 2, 3],
  },
  {
    title: 'Virtual Classes',
    path: '/virtualclasses',
    icon: 'icon_11.svg',
    roleaccess: [1, 2, 3, 4],
  },
  {
    title: 'Tutors',
    path: '/tutor',
    icon: 'icon_12.svg',
    roleaccess: [1, 2],
  },
  {
    title: 'Study Material',
    path: '/managestudymaterial',
    allowedUsers: ['2'],
    icon: 'icon_2.svg',
    roleaccess: [1, 2, 3, 4],
  },
  {
    title: 'Homework',
    path: '/managehomework',
    icon: 'icon_5.svg',
    roleaccess: [1, 2, 3, 4],
  },
  {
    title: 'Subject & School Years',
    path: '/learningdashboard',
    redirect: false,
    icon: 'icon_13.svg',
    submenu: [
      {
        title: 'Subjects',
        path: '/learningdashboard/subjects',
        roleaccess: [1, 2],
      },
      {
        title: 'School Years',
        path: '/learningdashboard/schoolyears',
        roleaccess: [1, 2],
      },
    ],
    roleaccess: [1, 2],
  },
  {
    title: 'Logout',
    path: '#',
    icon: 'logout.svg',
    submenu: [],
    roleaccess: [1, 2, 3, 4],
  },
];

export default menu;
