import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import CustomFunctions from '../../helper/customFunctions';
import Table from '../../components/Table/simpletable.component';
import 'react-datepicker/dist/react-datepicker.css';

const validator = new SimpleReactValidator();

const statusData = [
  {
    id: 1,
    label: 'Pending',
    classes: 'text-warning',
    color: '',
  },
  {
    id: 2,
    label: 'In Progress',
    classes: 'text-danger',
    color: 'table-red-bg',
  },
  {
    id: 3,
    label: 'In Progress',
    classes: 'text-danger',
    color: 'table-red-bg',
  },
  {
    id: 4,
    label: 'Complete',
    classes: 'text-success',
    color: '',
  },
];

function StudentHomework(props) {
  const [rows, setRows] = useState([]);
  const [homeworkId, setHomeworkId] = useState('');
  const [userRole, setUserRole] = useState('');
  const [tutorId, setTutorId] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });

  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [3],
      isDisplay: false,
    },
    {
      id: 'homeworkTitle',
      label: 'Homework Title',
      roleAccess: [3],
      render: ({ id, homeworkTitle, isDelay }) => {
        return (
          <>
            <a
              href="javascript:void(0)"
              className="p-r-2 font-weight-semibold"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.history.push(`/studenthomeworkdetails/${id}`);
              }}
            >
              {isDelay &&
                <Image src="/images/red-flag.gif" />
              }
              {homeworkTitle}
            </a>
          </>
        );
      },
    },
    {
      id: 'subject',
      label: 'Subject',
      roleAccess: [3],
      render: ({ subject }) => {
        return (
          <>
            {subject}
          </>
        );
      },
    },
    {
      id: 'date',
      label: 'Date',
      roleAccess: [3],
    },
    {
      id: 'status',
      label: 'Status',
      roleAccess: [3],
      render: ({ status, id }) => {
        return (
          <>
            <span
              className={getStatusColor(status, 'classes')}
            >
              {getStatusColor(status, 'label', id)}
            </span>
          </>
        );
      },
    },
  ];

  const getStatusColor = (status, key) => {
    const obj = statusData.filter((st) => {
      return Number(st.id) === Number(status);
    });
    if (!CustomFunctions.checkIfEmpty(obj, 'A')) {
      return obj[0][key];
    }
    return '';
  };

  useEffect(() => {
    const id = props.match.params.studentId;
    if (id) {
      setHomeworkId(id);
      const uRole = CustomFunctions.getLocalStorage('role_id');
      const userId = CustomFunctions.getLocalStorage('id');
      setTutorId(userId);
      setUserRole(Number(uRole));
      getTableData(id,userId, 1);
    }
  }, []);

  const getTableData = async (id, userId, pageNo = 0) => {


    const cPage = pageNo || pagination.current_page;

    const endPoint = `${Api.getTutorHomeworkStudentByTutorIdUrl}`;

    const params = {
      class_id : props.classid,
      tutor_id : userId,
      student_id: id,
      page: cPage,
      limit: pagination.itemsPerPage,
      sort_direction:'descending',
      sort_by:'homework__start_date',
      current_timezone: `${new Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    };

    const url = CustomFunctions.generateUrl(endPoint, params);

    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,

      });
      const row = [];

      data.data.map((student) => {
        const setRowData = {
          id: student.id,
          homeworkTitle: student.title ? student.title : '-',
          subject : student.subject_name,
          date:
            student.start_date && student.end_date
              ? `${CustomFunctions.getDMYDate(
                  student.start_date
                )} - ${CustomFunctions.getDMYDate(student.end_date)}`
              : '-',
          status: student.status,
          rowClass: Number(student.status) === 3 ? 'table-red-bg' : '',
          isDelay: student.is_delay
        };
        row.push(setRowData);
        return student;
      });
      setRows(row);
    }
  };

  validator.purgeFields();
  return (
    <>
      <section className="content-area">
        <div className="mt-4">
        <small className='text-primary h6'><i>(Note : <Image src="/images/red-flag.gif" /> - OVERDUE )</i></small>
          <Row>
            <Col className="mt-3">
              <Table
                role={Number(userRole)}
                columns={columns}
                rows={rows}
                showPagination
                paginationProps={{
                  ...pagination,
                  activePage: pagination.current_page,
                  onPageChange: (pageNo) => {
                    getTableData(homeworkId, tutorId, pageNo);
                  },
                }}
                tableClass="table-striped"
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
export default withRouter(StudentHomework);
