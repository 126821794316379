import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb.component';
import { Helmet } from '../../components/FormInputs';

const items = [
  { to: '/', label: 'Home' },
  { to: '/learningdashboard', label: 'Learning Dashboard' },
];

const LearningDashboard = () => {
  const history = useHistory();
  const [totalSubject, setTotalSubject] = useState(null);
  const [totalSchoolYear, setTotalSchoolYear] = useState(null);
  const [title, setTitle] = useState('');

  const getTotalSchoolYear = async () => {
    const endPoint = `${Api.schoolYearUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data && data.paginator) {
      setTotalSchoolYear(data.paginator.total_count);
    } else {
      swal(data && data.message[0], 'error');
    }
  };

  const getTotalSubject = async () => {
    const endPoint = `${Api.subjectUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.paginator) {
      setTotalSubject(data.paginator.total_count);
    } else {
      swal(data && data.message[0], '', 'error');
    }
  };

  useEffect(() => {
    setTitle('Learning Dashboard');
    getTotalSchoolYear(1);
    getTotalSubject(1);
  }, [getTotalSchoolYear, getTotalSubject]);

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">
            Learning Dashboard
          </h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Card>
            <Card.Header>
              {' '}
              <h4 className="text-primary mt-2 mb-2">Learning Dashboard</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <h5>Total Subjects </h5>
                  <h2>
                    <a
                      href=""
                      onClick={() =>
                        history.push('/learningdashboard/subjects')
                      }
                    >
                      {totalSubject}
                    </a>
                  </h2>
                </Col>
                <Col>
                  <h5>Total School Years </h5>
                  <h2>
                    <a
                      href=""
                      onClick={() =>
                        history.push('/learningdashboard/schoolyears')
                      }
                    >
                      {' '}
                      {totalSchoolYear}
                    </a>
                  </h2>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default withRouter(LearningDashboard);
