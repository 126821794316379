import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Card,
  Image,
  Accordion,
  Form,
} from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import Table from '../../components/Table/simpletable.component';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb.component';
import UserRestrictions from '../../components/UserRestrictions/userrestrictions.component';
import { TextInput, DropdownInput, Helmet } from '../../components/FormInputs';
import CustomFunctions from '../../helper/customFunctions';

const items = [
  { to: '/', label: 'Home' },
  { to: '/tutor', label: 'Tutors' },
];

const Tutor = () => {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [subjectList, setSubjectList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [selectOptionYear, setSelectOptionYear] = useState(null);
  const [selectOptionSubject, setSelectOptionSubject] = useState(null);
  const [cntClearFilter, setCntClearFilter] = useState(0);
  const [title, setTitle] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  const [filterErr, setFilterErr] = useState('');
  const [userRole, setUserRole] = useState('');
  let timmer = null;
  let disableButton = false;
  let searchFormSubmit = false;

  const handleActionCallback = (row) => {
    return (
      <>
        <UserRestrictions permittedUsers={[1, 2]} roleId={userRole}>
          { (row.status === 1) && (
            <a
              href="/"
              className="p-r-2"
              title="Switch User"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                switchAccount(row.id);
              }}
            >
              <img
                src="/images/login-button_gray.svg"
                width="18px"
                height="21px"
                alt="Switch Account"
              />
            </a>
          )}
        </UserRestrictions>
        <a
          href="/"
          className="p-r-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push(`tutor/tutorDetails/${row.id}`);
          }}
        >
          <Image src="/images/edit-button.svg" alt="Edit Tutor" />
        </a>

        <a
          href="/"
          className="p-r-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            deleteTutor(row.id);
          }}
        >
          <Image src="/images/delete.png" alt="Delete Tutor" />
        </a>
      </>
    );
  };

  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [1, 2],
      isDisplay: false,
    },
    {
      id: 'name',
      label: 'Name',
      roleAccess: [1, 2],
      render: ({ id, name }) => {
        return (
          <>
            <a
              href="/"
              className="p-r-2 font-weight-semibold"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`tutor/tutorDetails/${id}`);
              }}
            >
              {name}
            </a>
          </>
        );
      },
    },
    {
      id: 'years',
      label: 'School Years',
      roleAccess: [1, 2],
    },
    {
      id: 'subject',
      label: 'Subjects',
      roleAccess: [1, 2],
    },
    {
      id: 'studentsCount',
      label: 'Students Count',
      roleAccess: [1, 2],
    },
    {
      id: 'status',
      label: 'Status',
      roleAccess: [1, 2],
      render: ({ status }) => {
        return (
          <>
            <img
              src={status === 1 ? '/images/yes.png' : '/images/no-stopping.png'}
              alt="Download"
            />
          </>
        );
      },
    },
    {
      id: 'action',
      label: 'Action',
      roleAccess: [1, 2],
      render: handleActionCallback,
    },
  ];

  useEffect(() => {
    setTitle('All Tutors');
    getSubjectList();
    getYearList();
    const uRole = CustomFunctions.getLocalStorage('role_id');
    setUserRole(Number(uRole));
  }, []);

  useEffect(() => {
    getTableData(pagination.current_page);
  }, [cntClearFilter]);

  const getSubjectList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.subjectUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setSubjectList(data.data);
    }
  };

  const getYearList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.schoolYearUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setYearList(data.data);
    }
  };

  const getSubjectData = () => {
    if (!subjectList) return [];
    const subjectOptions = [];
    subjectList &&
      subjectList.map((dt) => {
        return subjectOptions.push({
          label: dt.name,
          id: dt.id,
          value: dt.id,
        });
      });
    return subjectOptions;
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList &&
      yearList.map((mat) => {
        return yearOptions.push({
          label: mat.name,
          id: mat.id,
          value: mat.id,
        });
      });
    return yearOptions;
  };

  const getTableData = async (pageNo = 0) => {
    const cPage = pageNo || pagination.current_page;

    const endPoint = `${Api.tutorUrl}`;

    const params = {
      page: cPage,
      limit: pagination.itemsPerPage,
      sort_direction: 'ascending',
      sort_by: 'first_name',
      keyword: searchFormSubmit ? keyword : '',
      class_id: selectOptionYear && searchFormSubmit ? selectOptionYear.id : '',
      subject_id:
        selectOptionSubject && searchFormSubmit ? selectOptionSubject.id : '',
    };

    const url = CustomFunctions.generateUrl(endPoint, params);

    const { data } = await AuthApi.getDataFromServer(url);

    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      const row = [];
      data.data.map((tutor) => {
        return row.push({
          id: tutor.id,
          name: tutor.full_name,
          subject: tutor.subjects_name ? tutor.subjects_name : '-',
          years: tutor.classes_name ? tutor.classes_name : '-',
          studentsCount: tutor.student_count,
          status: tutor.status,
        });
      });
      disableButton = false;
      setRows(row);
    } else {
      disableButton = false;
    }
  };

  const deleteTutor = async (id) => {
    if (id) {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure that you want to delete this record?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const { data, message } = await AuthApi.deleteDataFromServer(
            `${Api.tutorUrl}${id}/`
          );
          if (data) {
            swal(data && data.message[0], '', 'success', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
              buttons: false,
              timer: 2000,
            }).then(() => {
              swal.close();
              getTableData(pagination.current_page);
            });
          } else {
            swal(message, '', 'error', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
          }
        }
      });
    }
  };

  const onClear = () => {
    searchFormSubmit = false;
    setFilterErr('');
    setKeyword('');
    setSelectOptionSubject(null);
    setSelectOptionYear(null);
    if (disableButton) return;

    disableButton = true;

    if (timmer) clearTimeout(timmer);
    timmer = setTimeout(() => {
      setCntClearFilter(cntClearFilter + 1);
      disableButton = false;
    }, 1000);
  };

  const bulkActions = [
    {
      id: 'delete',
      actionTitle: 'Delete',
      actionCallback: (selectedIndexes) => {
        const endPoint = `${Api.studentBulkStatus}`;
        if (!CustomFunctions.checkIfEmpty(selectedIndexes, 'A')) {
          const params = { id: selectedIndexes, status: 3 };
          swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete this record?',
            icon: 'warning',
            dangerMode: true,
            buttons: true,
            closeOnClickOutside: false,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result) {
              const successHandle = (e) => {
                swal(e && e.message[0], '', 'success', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                  buttons: false,
                  timer: 2000,
                }).then(() => {
                  swal.close();
                  getTableData(pagination.current_page);
                });
              };
              const errorHandle = (e) => {
                swal(e, '', 'error', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                });
              };
              await CustomFunctions.changeStatus(
                endPoint,
                params,
                'PUT',
                successHandle,
                errorHandle
              );
            }
          });
        }
      },
    },
    {
      id: 'active',
      actionTitle: 'Active',
      actionCallback: (selectedIndexes) => {
        const endPoint = `${Api.studentBulkStatus}`;
        if (!CustomFunctions.checkIfEmpty(selectedIndexes, 'A')) {
          const params = { id: selectedIndexes, status: 1 };
          swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to update this record?',
            icon: 'warning',
            dangerMode: true,
            buttons: true,
            closeOnClickOutside: false,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result) {
              const successHandle = (e) => {
                swal(e && e.message[0], '', 'success', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                  buttons: false,
                  timer: 2000,
                }).then(() => {
                  swal.close();
                  getTableData(pagination.current_page);
                });
              };
              const errorHandle = (e) => {
                swal(e, '', 'error', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                });
              };
              await CustomFunctions.changeStatus(
                endPoint,
                params,
                'PUT',
                successHandle,
                errorHandle
              );
            }
          });
        }
      },
    },
    {
      id: 'inactive',
      actionTitle: 'Inactive',
      actionCallback: (selectedIndexes) => {
        const endPoint = `${Api.studentBulkStatus}`;
        if (!CustomFunctions.checkIfEmpty(selectedIndexes, 'A')) {
          const params = { id: selectedIndexes, status: 2 };
          swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to update this record?',
            icon: 'warning',
            dangerMode: true,
            buttons: true,
            closeOnClickOutside: false,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result) {
              const successHandle = (e) => {
                swal(e && e.message[0], '', 'success', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                  buttons: false,
                  timer: 2000,
                }).then(() => {
                  swal.close();
                  getTableData(pagination.current_page);
                });
              };
              const errorHandle = (e) => {
                swal(e, '', 'error', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                });
              };
              await CustomFunctions.changeStatus(
                endPoint,
                params,
                'PUT',
                successHandle,
                errorHandle
              );
            }
          });
        }
      },
    },
  ];

  const filterSearch = (e) => {
    e.preventDefault();
    let filterSearchErr = '';
    let isValid = true;

    if (disableButton) return;

    disableButton = true;

    if (
      CustomFunctions.checkIfEmpty(keyword) &&
      CustomFunctions.checkIfEmpty(selectOptionSubject) &&
      CustomFunctions.checkIfEmpty(selectOptionYear)
    ) {
      searchFormSubmit = false;
      filterSearchErr = 'Please select atleast one filter';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
    }

    if (isValid) {
      searchFormSubmit = true;
      setFilterErr('');
      if (timmer) clearTimeout(timmer);

      timmer = setTimeout(() => {
        getTableData(pagination.current_page);
      }, 1000);
    }
  };

  const switchAccount = (uid) => {
    CustomFunctions.switchUser(
      Api.userImpersonateUrl,
      uid,
      window.location.pathname,
      (e) => {
        swal(e, '', 'error');
      }
    );
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Tutor</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="text-primary m-0">Filters</h4>
                  <div className="d-flex align-items-center justify-content-end">
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Expand
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Card.Title>
                    <Form onSubmit={(e) => filterSearch(e)}>
                      {filterErr && (
                        <div className="p-3  bg-danger text-white bg-danger-main table-red-bg">
                          <div className="img-text">
                            <img src="images/fail.svg" alt="Success" />{' '}
                            {filterErr}
                          </div>
                        </div>
                      )}
                      <Row className="mt-3">
                        <Col sm={3}>
                          <div className="search-bar-input">
                            <TextInput
                              value={keyword}
                              placeholder="Search Keyword"
                              onInputChange={(e) => {
                                setFilterErr('');
                                setKeyword(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <DropdownInput
                            placeholder="Select Subject"
                            options={getSubjectData()}
                            inputClass="mx-0"
                            defaultValue={selectOptionSubject}
                            onSelectHandler={(select) => {
                              setFilterErr('');
                              setSelectOptionSubject(select);
                            }}
                          />
                        </Col>
                        <Col sm={3}>
                          <DropdownInput
                            placeholder="Select School Year"
                            options={getYearData()}
                            defaultValue={selectOptionYear}
                            onSelectHandler={(select) => {
                              setFilterErr('');
                              setSelectOptionYear(select);
                            }}
                          />
                        </Col>
                        <Col sm={3}>
                          <div className="filter-btn">
                            <Button
                              variant="primary"
                              type="submit"
                              className="ms-4"
                              disabled={disableButton}
                            >
                              Submit
                            </Button>
                            <Button
                              variant="primary"
                              type="button"
                              className="ms-4"
                              onClick={() => onClear()}
                            >
                              Clear
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Title>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary mt-2 mb-2">All Tutors</h4>
                <div className="d-flex align-items-center justify-content-end">
                  <a
                    href=""
                    onClick={() => {
                      history.push(`tutor/tutorDetails`);
                    }}
                    className="btn-link"
                  >
                    Add Tutor
                  </a>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="mt-3">
                  <Table
                    role={Number(userRole)}
                    columns={columns}
                    rows={rows}
                    tableClass="table-striped"
                    showPagination
                    showCheckbox
                    paginationProps={{
                      ...pagination,
                      activePage: pagination.current_page,
                      onPageChange: (pageNo) => {
                        getTableData(pageNo);
                      },
                    }}
                    bulkActionsLabel="Apply"
                    bulkActions={bulkActions}
                    dropdownContainerClass="mb-3"
                    emptyRender={() => (
                      <p className="text-center mb-0">No record found</p>
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default withRouter(Tutor);
