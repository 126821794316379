import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, Button, Row, Col, Card, Image } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import CustomFunctions from '../../../helper/customFunctions';
import s3config from '../../../constants';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import {
  TextInput,
  DropdownInput,
  DateTimePicker,
  FileInput,
  Helmet,
} from '../../../components/FormInputs';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import 'react-datepicker/dist/react-datepicker.css';
import UserRestrictions from '../../../components/UserRestrictions/userrestrictions.component';

const validator = new SimpleReactValidator();

function AddManageHomework(props) {
  const history = useHistory();
  const [selectOptionSubject, setSelectOptionSubject] = useState(null);
  const [selectOptionYear, setSelectOptionYear] = useState(null);
  const [selectOptionTutor, setSelectOptionTutor] = useState(null);
  const [selectOptionStudent, setSelectOptionStudent] = useState(null);
  const [count, forceUpdate] = useState(0);
  const [homeworkTitle, setHomeworkTitle] = useState('');
  const [homeworkDescription, setHomeworkDescription] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [subjectList, setSubjectList] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [selsubjectOptions, setSubjectOptions] = useState(null);
  const [selyearOptions, setYearOptions] = useState(null);
  const [selStudentOptions, setStudentOptions] = useState(null);
  const [studentList, setStudentList] = useState([]);
  const [homeworkId, setHomeworkId] = useState('');
  const [items, setItems] = useState('');
  const [imageBucket, setImageBucket] = useState([]);
  const [title, setTitle] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userId, setTutorId] = useState('');
  const [fileError, setFileError] = useState('');
  const [numberOfFile, setNumberOfFile] = useState([{id:0,files:[]}]);

  useEffect(async () => {
    async function getData() {
      const uRole = CustomFunctions.getLocalStorage('role_id');
      if (!CustomFunctions.checkIfEmpty(uRole)) {
        setUserRole(Number(uRole));
      }
      const uid = CustomFunctions.getLocalStorage('id');
      if (!CustomFunctions.checkIfEmpty(uid)) {
        setTutorId(Number(uid));
      }
      setTitle('Add Homework');
      const id = props.match.params.homeworkId;
      if (id) {
        setTitle('Edit Homework');
        setHomeworkId(Number(id));
      }
      const breadCrumb = [
        { to: '/', label: 'Home' },
        { to: '/managehomework', label: 'Homework' },
        { label: id ? 'Edit' : 'Add' },
      ];
      setItems(breadCrumb);
      await getSubjectList();
    }
    getData();
  }, []);

  useEffect(() => {
    if (homeworkId) {
      getHomework(homeworkId);
    }
  }, [homeworkId]);

  const getSubjectList = () => {
    return new Promise(async (resolve) => {
      const { data } = await AuthApi.getDataFromServer(
        `${Api.getTutorClassSubjectByUrl}?type=all`
      );

      if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
        setSubjectList([...data.data]);
        resolve();
      }
    });
  };

  const getSubjectData = (classid, tutorid) => {
    if (!subjectList) return [];
    const subjectOptions = [];
    subjectList &&
      subjectList.map((subject) => {
        if (Number(subject.id) === Number(tutorid)) {
          subject &&
            subject.tutor_subjects &&
            subject.tutor_subjects.map((dt) => {
              if (Number(dt.class_id) === Number(classid)) {
                return subjectOptions.push({
                  label: dt.subject_name,
                  id: dt.subject_id,
                  value: dt.subject_id,
                });
              }
              return subjectOptions;
            });
        }
        return subject;
      });
    const key = 'id';
    const uniqueSubjects = [
      ...new Map(subjectOptions.map((item) => [item[key], item])).values(),
    ];
    setSubjectOptions(uniqueSubjects);
    setSelectOptionSubject('');
    setSelectOptionStudent('');
    return uniqueSubjects;
  };

  const getYearData = (tutorid) => {
    if (!subjectList) return [];
    const yearOptions = [];
    subjectList &&
      subjectList.map((subject) => {
        if (Number(subject.id) === Number(tutorid)) {
          subject &&
            subject.tutor_subjects &&
            subject.tutor_subjects.map((dt) => {
              return yearOptions.push({
                label: dt.class_name,
                id: dt.class_id,
                value: dt.class_id,
              });
            });
        }
        return subject;
      });
    const key = 'id';
    const uniqueYears = [
      ...new Map(yearOptions.map((item) => [item[key], item])).values(),
    ];
    setYearOptions(uniqueYears);
    setSelectOptionYear('');
    setSelectOptionSubject('');
    setSelectOptionStudent('');
    return uniqueYears;
  };

  const getTutorData = () => {
    if (!subjectList) return [];
    const tutorOptions = [];
    subjectList &&
      subjectList.map((subject) => {
        return tutorOptions.push({
          label: `${subject.first_name} ${subject.last_name}`,
          id: subject.id,
          value: subject.id,
        });
      });
    const key = 'id';
    const uniqueTutorArray = [
      ...new Map(tutorOptions.map((item) => [item[key], item])).values(),
    ];
    return uniqueTutorArray;
  };

  const getHomework = async (id = 0) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.getManageHomeworkByUrl}${id}/`
    );

    if (!CustomFunctions.checkIfEmpty(data && data.data, 'O')) {
      const images = [];
      const studentArray = [];

      const studentListArray = await getTutorSubjectClassData(
        data.data.tutor_id,
        data.data.class_id,
        data.data.subject_id
      );

      setSelectOptionSubject({
        label: data.data.subject_name,
        id: data.data.subject_id,
        value: data.data.subject_id,
      });
      setSelectOptionYear({
        label: data.data.class_name,
        id: data.data.class_id,
        value: data.data.class_id,
      });
      setSelectOptionTutor({
        label: data.data.tutor_name,
        id: data.data.tutor_id,
        value: data.data.tutor_id,
      });

      studentListArray &&
        studentListArray.map((stud) => {
          data.data.students &&
            data.data.students.map((i) => {
              if (Number(stud.id) === Number(i)) {
                return studentArray.push({
                  label: stud.full_name,
                  id: stud.id,
                  value: stud.id,
                });
              }
              return i;
            });
          return stud;
        });

      setSelectOptionStudent(studentArray);
      setHomeworkTitle(data.data.title);
      setFromDate(new Date(data.data.start_date.toLocaleString()));
      setToDate(new Date(data.data.end_date.toLocaleString()));
      setHomeworkDescription(data.data.description);
      data &&
        data.data &&
        data.data.attachements &&
        data.data.attachements.map((attach) => {
          images.push(attach.file_url);
          return attach;
        });
      setImageBucket(images);
    }
  };

  const handleImageFileUpload = (e) => {
    const fileUrls = imageUrl;
    e &&
      e.files &&
      e.files.map((file) => {
        if (file.response && !fileUrls.includes(file.response.Location)) {
          fileUrls.push(file.response.Location);
        }
        return file;
      });
    setImageUrl([...fileUrls]);
  };

  const addFiles = () =>{
    const maxLength = Math.max(...numberOfFile.map((o)=> { return o.id; }));
    setNumberOfFile([...numberOfFile,{id:maxLength+1,files:[]}]);
  };

  const removeFiles = (i) =>{
    numberOfFile.splice(i, 1);
    setNumberOfFile(JSON.parse(JSON.stringify(numberOfFile)));
  };

  const postHomework = async () => {
    const selectStudentId = [];
      selectOptionStudent &&
       selectOptionStudent.map((op) => {
        return selectStudentId.push(op.value);
      });
      const postData = {
        subject: selectOptionSubject.id,
        classes: selectOptionYear.id,
        description: homeworkDescription,
        title: homeworkTitle,
        class_mode: 1,
        start_date: CustomFunctions.formatSimpleDateTime(fromDate),
        end_date:  CustomFunctions.formatSimpleDateTime(toDate),
        student: selectStudentId,
        tutor_id: selectOptionTutor ? selectOptionTutor.id : userId,
        attachements: [],
      };

    if (imageUrl.length > 0 || imageBucket.length > 0) {
      postData.attachements = [...imageUrl,...imageBucket];
    }
    const payload = CustomFunctions.cleanObject(postData);
    const callback = homeworkId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = homeworkId
      ? `${Api.getManageHomeworkByUrl}${homeworkId}/`
      : `${Api.getManageHomeworkByUrl}`;

    const { data, message } = await callback(endPoint, payload);

    if (data) {
      validator.hideMessages();
      swal(data && data.message[0], '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result) {
          setDisableButton(false);
          setSelectOptionSubject(null);
          setSelectOptionYear(null);
          setSelectOptionTutor(null);
          setHomeworkTitle('');
          setToDate('');
          setFromDate('');
          setHomeworkDescription('');
          history.push('/managehomework');
        }
      });
    } else {
      setDisableButton(false);
      swal(message, '', 'error', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  };

  const onSubmitHomework = (e) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);

    if (validator.allValid()) {
      postHomework();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const onDeleteHandler = (index) => {
    const buck = [];
    const ind = imageBucket.splice(index, 1);
    imageBucket &&
      imageBucket.map((bucket) => {
        buck.push(bucket);
        return bucket;
      });
    if (ind) {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure that you want to delete this record?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result) {
          setImageBucket(buck);
        }
      });
    }
  };

  const onCancel = () => {
    validator.hideMessages();
    setSelectOptionSubject(null);
    setSelectOptionYear(null);
    setHomeworkTitle('');
    setToDate('');
    setFromDate('');
    setSelectOptionTutor(null);
    setSelectOptionStudent(null);
    setHomeworkDescription('');
    history.push('/managehomework');
  };

  const getTutorSubjectClassData = async (tutorId, classId, subjectId) => {
    if (!studentList) return [];
    const studentOptions = [];

    return new Promise(async (resolve) => {
      const endPoint = `${Api.studentUrl}`;

      const params = {
        class_id: classId,
        subject_id: subjectId,
        tutor_id: tutorId,
        status : 1,
        type  : 'all'
      };

      const url = CustomFunctions.generateUrl(endPoint, params);
      const { data } = await AuthApi.getDataFromServer(url);
      if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
        data.data.map((stud) => {
          return studentOptions.push({
            label: stud.full_name,
            id: stud.id,
            value: stud.id,
          });
        });
        setStudentList(data.data);
        setStudentOptions([...studentOptions]);
        resolve(data.data);
      } else {
        setSelectOptionStudent('');
        setStudentOptions(null);
        resolve([]);
      }
    });
  };

  useEffect(() => {
    if (Number(userRole) === 3 && Number(userId)) {
      getYearData(userId);
    }
  }, [subjectList]);

  validator.purgeFields();
  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Homework</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Card>
            <Card.Header>
              <h5 className="mt-2 mb-2">{title}</h5>
            </Card.Header>
            <Card.Body>
              <Form autoComplete="off" onSubmit={(e) => onSubmitHomework(e)}>
                <Row>
                  <Col className="col-md-6">
                    <TextInput
                      onInputChange={(e) => setHomeworkTitle(e.target.value)}
                      name="homeworkTitle"
                      containerClass="form-group"
                      inputClass="form-control"
                      label="Homework Title"
                      asterisk="*"
                      value={homeworkTitle || ''}
                      placeholder="Title"
                      errorMessage={validator.message(
                        'Title',
                        homeworkTitle,
                        'required|alpha_num_space'
                      )}
                    />
                    <Col className="col-md-12">
                    { numberOfFile && numberOfFile.map((file, i)=>(
                      <div key={file.id}>
                        <Row>
                          <Col className= {(numberOfFile.length > 1) ? 'col-md-10' : 'col-md-12'}>
                            <FileInput
                              title= {i > 0 ? '' : 'Attach Document'}
                              optionalLabel={i > 0 ? '' :'(Allowed file size - 25 MB, file types - PDF, JPG, PNG, DOC, TXT, XLS)'}
                              onSelectFile={(e) => {
                                numberOfFile[i].files = e;
                                handleImageFileUpload(e);
                                setNumberOfFile([...numberOfFile]);
                              }}
                              isS3
                              showUploadProgress
                              accept=".xls,.txt,.pdf,.doc,image/jpeg,image/png,"
                              s3config={s3config}
                              allowedFileSize={25 * 1024 * 1024}
                              uploadUrl={Api.fileUploadUrl}
                              payloadKey="file_name"
                              onError={(error) => setFileError(error)}
                              errorMessage={fileError}
                            />
                            </Col>
                            <Col sm={2}>
                              {(numberOfFile.length > 1) ?
                                <Image
                                  src="/images/fail.svg"
                                  className="close-file-icon"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    removeFiles(i);
                                  }}
                                />
                              :''}
                            </Col>
                          </Row>
                        </div>
                        ))
                      }
                       <Button
                          variant="primary"
                          type="button"
                          className="mt-2 mb-2"
                          onClick={()=>addFiles()}
                        >
                        Add More
                      </Button>
                      <Row>
                        <Col>
                          {homeworkId
                            ? imageBucket &&
                              imageBucket.map((img, index) => (
                                <span className="span-container attchment-edit-image">
                                  {img && (
                                    <a
                                      className="ms-2"
                                      href={img}
                                      target="blank"
                                    >
                                      {img.length > 0
                                        ? decodeURI(
                                            img.split(decodeURI(Api.s3url))
                                          ).substring(1)
                                        : ''}
                                    </a>
                                  )}
                                  <Image
                                    src="/images/delete.png"
                                    className="ms-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onDeleteHandler(index);
                                    }}
                                  />
                                </span>
                              ))
                            : ''}
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                  <Col className="col-md-6">
                    <TextInput
                      onInputChange={(e) =>
                        setHomeworkDescription(e.target.value)
                      }
                      name="homeworkDescription"
                      containerClass="form-group"
                      asterisk="*"
                      label="Description"
                      value={homeworkDescription || ''}
                      Row={5}
                      as="textarea"
                      placeholder="Write here"
                      errorMessage={validator.message(
                        'Description',
                        homeworkDescription,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6">
                    <DateTimePicker
                      label="From"
                      dateFormat="dd/MM/yyyy"
                      selected={fromDate}
                      className="form-control"
                      onChange={(e) => {
                        const myFutureDate = new Date(e);
                        myFutureDate.setDate(myFutureDate.getDate() + 7);
                        setToDate(myFutureDate);
                        setFromDate(e);
                      }}
                      asterisk="*"
                      placeholder="DD-MM-YYYY"
                      errorMessage={validator.message(
                        'fromDate',
                        fromDate,
                        'required'
                      )}
                      disablePast
                      minDate={new Date()}
                    />
                  </Col>
                  <Col className="col-md-6">
                    <DateTimePicker
                      label="To"
                      dateFormat="dd/MM/yyyy"
                      selected={toDate}
                      asterisk="*"
                      placeholder="DD-MM-YYYY"
                      className="form-control"
                      onChange={(e) => {
                        setToDate(e);
                      }}
                      errorMessage={validator.message(
                        'toDate',
                        toDate,
                        'required'
                      )}
                      minDate={fromDate}
                    />
                  </Col>
                  <UserRestrictions permittedUsers={[1, 2]} roleId={userRole}>
                    <Col className="col-md-6">
                      <DropdownInput
                        label="Tutor Name"
                        asterisk="*"
                        placeholder="Select Tutor Name"
                        options={getTutorData() || []}
                        defaultValue={selectOptionTutor || ''}
                        onSelectHandler={(select) => {
                          getYearData(select.id);
                          setSelectOptionTutor(select);
                          setSubjectOptions(null);
                          setStudentOptions(null);
                        }}
                        errorMessage={
                          CustomFunctions.hasUserRestrictions([1, 2])
                            ? validator.message(
                                'Tutor',
                                selectOptionTutor,
                                'required'
                              )
                            : ''
                        }
                        disabled={homeworkId ? true : ''}
                      />
                    </Col>
                  </UserRestrictions>
                  <Col className="col-md-6">
                    <DropdownInput
                      label="School Year"
                      placeholder="Select School Year"
                      asterisk="*"
                      defaultValue={selectOptionYear || ''}
                      options={selyearOptions || []}
                      onSelectHandler={(select) => {
                        const tid = selectOptionTutor
                          ? selectOptionTutor.id
                          : userId;
                        getSubjectData(select.id, tid);
                        setSelectOptionYear(select);
                        setStudentOptions(null);
                      }}
                      errorMessage={validator.message(
                        'School Year',
                        selectOptionYear,
                        'required'
                      )}
                      disabled={homeworkId ? true : ''}
                    />
                  </Col>
                  <Col className="col-md-6">
                    <DropdownInput
                      label="Subject"
                      placeholder="Select Subject"
                      asterisk="*"
                      options={selsubjectOptions || []}
                      defaultValue={selectOptionSubject || ''}
                      onSelectHandler={(select) => {
                        const tid = selectOptionTutor
                          ? selectOptionTutor.id
                          : userId;
                        setSelectOptionSubject(select);
                        getTutorSubjectClassData(
                          tid,
                          selectOptionYear.id,
                          select.id
                        );
                      }}
                      errorMessage={validator.message(
                        'Subject',
                        selectOptionSubject,
                        'required'
                      )}
                      disabled={homeworkId ? true : ''}
                    />
                  </Col>
                  <Col
                    className={`${userRole === 2 || userRole === 1}? 'col-md-12': 'col-md-6'}`}
                  >
                    <DropdownInput
                      label="Student Name"
                      placeholder="Select Student Name"
                      options={selStudentOptions || []}
                      defaultValue={selectOptionStudent || ''}
                      onSelectHandler={(select) =>
                        setSelectOptionStudent(select)
                      }
                      asterisk="*"
                      isMulti
                      errorMessage={validator.message(
                        'Student',
                        selectOptionStudent,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <div className="float-end">
                  <Button variant="secondary" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="ms-4"
                    disabled={disableButton}
                  >
                    {homeworkId ? 'Update' : 'Submit'}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
export default withRouter(AddManageHomework);
