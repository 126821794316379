import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import { Helmet } from '../../../components/FormInputs';

function ForgotPassword() {
  const validator = useRef(new SimpleReactValidator());
  const [count, forceUpdate] = useState(0);
  const [username, setUsername] = useState('');
  const [title, setTitle] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle('Recover Password');
    dispatch({ type: 'LOGIN', value: false });
  }, []);

  const resetData = () => {
    setUsername('');
  };

  const sendResetPasswordEmail = async () => {
    const payload = {
      username: username.trim(),
    };
    const { data, message } = await AuthApi.postDataToServer(
      `${Api.forgotPasswordUrl}`,
      payload
    );

    if (data && data.success === true) {
      setDisableButton(false);
      swal('Link successfully sent to your Email.', '', 'success');
      await resetData();
    } else {
      setDisableButton(false);
      swal(message, '', 'error');
    }
  };

  const validateForm = (e) => {
    e.preventDefault();
    if (disableButton) return;

    setDisableButton(true);
    if (validator.current.allValid()) {
      sendResetPasswordEmail();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="login-page recover-password">
        <div className="logo text-center">
          <a href="#">
            {' '}
            <img src="/images/PTL_Logo.png" alt="img" />
          </a>
        </div>
        <form className="login-form" onSubmit={(e) => validateForm(e)}>
          <div className="title text-center">
            <h1>Recover Password</h1>
          </div>
          <div className="form-group">
            <img src="/images/user.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              value={username || ''}
              type="text"
              name="username"
              placeholder="Username/Email Address"
              onChange={(e) => setUsername(e.target.value)}
            />
              {validator.current.message('Username', username, 'required', {
              className: 'text-danger',
            })}
          </div>
          <div className="text-center mt-3">
            <Button
              variant="btn btn-lg btn-primary"
              type="submit"
              disabled={disableButton}
            >
              Reset
            </Button>
          </div>
          <a href="/login" className="back-to-login">
            Back to Login
          </a>
        </form>
      </div>
    </>
  );
}

export default withRouter(ForgotPassword);
