import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Card,
  Image,
  Accordion,
  Form,
} from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import CustomFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import {
  TextInput,
  DropdownInput,
  Helmet,
  DateTimePicker
} from '../../../components/FormInputs';
import Table from '../../../components/Table/simpletable.component';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import 'react-datepicker/dist/react-datepicker.css';
import UserRestrictions from '../../../components/UserRestrictions/userrestrictions.component';

const items = [
  { to: '/', label: 'Home' },
  { to: '/managestudymaterial', label: 'Study Material' },
];

const validator = new SimpleReactValidator();

function ManageStudyMaterial() {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectOptionSubject, setSelectOptionSubject] = useState(null);
  const [selectOptionYear, setSelectOptionYear] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [cntClearFilter, setCntClearFilter] = useState(0);
  const [userRole, setUserRole] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  const [filterErr, setFilterErr] = useState('');
  const [title, setTitle] = useState('');
  let timmer = null;
  let disableButton = false;
  const [selectOptionTutor, setSelectOptionTutor] = useState(null);
  const [tutorList, setTutorList] = useState([]);
  let searchFormSubmit = false;
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [classId, setClassId] = useState('');

  const handleActionCallback = ({ id }) => {
    return (
      <>
        <a
          href="/"
          className="p-r-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push(`/managestudymaterial/edit/${id}`);
          }}
        >
          <Image src="/images/edit-button.svg" alt="Edit Tutor" />
        </a>
        <a
          href="/"
          className="p-r-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            deleteStudyMaterial(id);
          }}
        >
          <Image src="images/delete.png" />
        </a>
      </>
    );
  };

  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [1, 2, 3],
      isDisplay: false,
    },
    {
      id: 'title',
      label: 'Title',
      roleAccess: [1, 2, 3, 4],
    },
    {
      id: 'year',
      label: 'School Year',
      roleAccess: [1, 2, 3],
    },
    {
      id: 'subject',
      label: 'Subject',
      roleAccess: [1, 2, 3, 4],
    },
    {
      id: 'tutor_name',
      label: 'Tutor Name',
      roleAccess: [1, 2, 4],
    },
    {
      id: 'attachment',
      label: 'Attachment',
      roleAccess: [1, 2, 3, 4],
      render: ({ attachment }) => {
        return (
          <>
            {' '}
            {attachment &&
              attachment.map((img) => (
                <span className="span-container attachment-wrap">
                  <a
                    className="attachment-link-color"
                    href={img.file_url}
                    target="blank"
                    dangerouslySetInnerHTML={{
                      __html: img.file_url
                        ? decodeURI(
                            img.file_url.split(decodeURI(Api.s3url))
                          ).substring(1)
                        : '-',
                    }}
                  />
                </span>
              ))}
          </>
        );
      },
    },
    {
      id: 'studentCount',
      label: 'Student Count',
      roleAccess: [1, 2, 3],
    },
    {
      id: 'uploadedDate',
      label: 'Date',
      roleAccess: [1, 2, 3, 4],
    },
    {
      id: 'action',
      label: 'Action',
      roleAccess: [1, 2, 3],
      render: handleActionCallback,
    },
  ];

  useEffect(() => {
    async function getData() {
      const uRole = CustomFunctions.getLocalStorage('role_id');
      const userId = CustomFunctions.getLocalStorage('id');
      if (Number(uRole) === 4){
        await getStudentClassIdByStudentId(userId);
      }
      if (!CustomFunctions.checkIfEmpty(uRole)) {
        setUserRole(Number(uRole));
      }
      setTitle('Study Material');
      getSubjectList();
      getYearList();
      getTutorList();
    }
    getData();
  }, []);

  useEffect(() => {
    if (userRole) getTableData(pagination.current_page);
  }, [userRole, cntClearFilter, classId]);

  const getStudentClassIdByStudentId = async (userid) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.studentUrl}${userid}/`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'O')) {
      setClassId(data.data.class);
    }
  };

  const getTableData = async (pageNo = 0) => {
    const cPage = pageNo || pagination.current_page;
    const endPoint = `${Api.getManageStudyMaterialByUrl}`;
    const params = {
      page: cPage,
      limit: pagination.itemsPerPage,
      keyword: searchFormSubmit ? searchKeyword : '',
      class_id: selectOptionYear && searchFormSubmit ? selectOptionYear.id : `${classId}`,
      subject_id:
        selectOptionSubject && searchFormSubmit ? selectOptionSubject.id : '',
      sort_direction: 'descending',
      tutor_id:
        selectOptionTutor && searchFormSubmit ? selectOptionTutor.id : '',
      start_date:
        fromDate ? CustomFunctions.getYearDate(fromDate) : '',
      end_date: toDate ? CustomFunctions.getYearDate(toDate) : '',
    };
    const url = CustomFunctions.generateUrl(endPoint, params);

    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      const row = [];
      data.data.map((dt) => {
        const setRowData = {
          id: dt.id,
          title: dt.title ? dt.title : '-',
          year: dt.class_name ? dt.class_name : '-',
          subject: dt.subject_name ? dt.subject_name : '-',
          tutor_name: dt.tutor_name ? dt.tutor_name : '-',
          attachment: dt.attachements ? dt.attachements : [],
          studentCount: dt.count ? dt.count : '-',
          uploadedDate: dt.created_at ?  CustomFunctions.getDMYDate(dt.created_at) :'-',
        };
        row.push(setRowData);
        return dt;
      });
      disableButton = false;
      setRows(JSON.parse(JSON.stringify(row)));
    } else {
      disableButton = false;
    }
  };

  const getSubjectList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.subjectUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setSubjectList(data.data);
    }
  };

  const getYearList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.schoolYearUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setYearList(data.data);
    }
  };

  const deleteStudyMaterial = (id) => {
    if (id) {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure that you want to delete this record?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const { data, message } = await AuthApi.deleteDataFromServer(
            `${Api.getManageStudyMaterialByUrl}${id}/`
          );
          if (data) {
            swal(data && data.message[0], '', 'success', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
              buttons: false,
              timer: 2000,
            }).then(() => {
              swal.close();
              getTableData(pagination.current_page);
            });
          } else {
            swal(message, '', 'error', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
          }
        }
      });
    }
  };

  const getSubjectData = () => {
    if (!subjectList) return [];
    const subjectOptions = [];
    subjectList &&
      subjectList.map((dt) => {
        return subjectOptions.push({
          label: dt.name,
          id: dt.id,
          value: dt.id,
        });
      });
    return subjectOptions;
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList &&
      yearList.map((mat) => {
        return yearOptions.push({
          label: mat.name,
          id: mat.id,
          value: mat.id,
        });
      });
    return yearOptions;
  };

  const onClear = () => {
    searchFormSubmit = false;
    setFilterErr('');
    setSearchKeyword('');
    setSelectOptionSubject(null);
    setSelectOptionYear(null);
    setSelectOptionTutor(null);
    setToDate('');
    setFromDate('');
    if (disableButton) return;

    disableButton = true;

    if (timmer) clearTimeout(timmer);
    timmer = setTimeout(() => {
      setCntClearFilter(cntClearFilter + 1);
      disableButton = false;
    }, 1000);
  };

  const bulkActions = [
    {
      id: 'delete',
      actionTitle: 'Delete',
      actionCallback: (selectedIndexes) => {
        const endPoint = `${Api.getBulkStatus}`;
        if (!CustomFunctions.checkIfEmpty(selectedIndexes, 'A')) {
          const params = { id: selectedIndexes };
          swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete this record?',
            icon: 'warning',
            dangerMode: true,
            buttons: true,
            closeOnClickOutside: false,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result) {
              const successHandle = (e) => {
                swal(e && e.message[0], '', 'success', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                  buttons: false,
                  timer: 2000,
                }).then(() => {
                  swal.close();
                  getTableData(pagination.current_page);
                });
              };
              const errorHandle = (e) => {
                swal(e, '', 'error', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                });
              };
              await CustomFunctions.changeStatus(
                endPoint,
                params,
                'POST',
                successHandle,
                errorHandle
              );
            }
          });
        }
      },
    },
  ];

  const filterSearch = (e) => {
    e.preventDefault();
    let filterSearchErr = '';
    let isValid = true;

    if (disableButton) return;

    disableButton = true;

    if (
      CustomFunctions.checkIfEmpty(searchKeyword) &&
      CustomFunctions.checkIfEmpty(selectOptionSubject) &&
      CustomFunctions.checkIfEmpty(selectOptionYear) &&
      CustomFunctions.checkIfEmpty(selectOptionTutor) &&
      CustomFunctions.checkIfEmpty(fromDate) &&
      CustomFunctions.checkIfEmpty(toDate)
    ) {
      searchFormSubmit = false;
      filterSearchErr = 'Please select atleast one filter';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
    }

    if (!CustomFunctions.checkIfEmpty(fromDate)) {
      if (CustomFunctions.checkIfEmpty(toDate)) {
        filterSearchErr = 'Please select To date';
        setFilterErr(filterSearchErr);
        isValid = false;
        disableButton = false;
      }
    } else if (!CustomFunctions.checkIfEmpty(toDate)) {
      filterSearchErr = 'Please select From date';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
    }
    if (isValid) {
      searchFormSubmit = true;
      setFilterErr('');
      if (timmer) clearTimeout(timmer);

      timmer = setTimeout(() => {
        getTableData(pagination.current_page);
      }, 1000);
    }
  };

  validator.purgeFields();

  const getTutorList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.tutorUrl}?type=all`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setTutorList(data.data);
    }
  };

  const getTutorData = () => {
    if (!tutorList) return [];
    const tutorOptions = [];
    tutorList.map((dt) => {
      return tutorOptions.push({
        label: dt.full_name,
        id: dt.id,
        value: dt.id,
      });
    });
    return tutorOptions;
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Study Material</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="text-primary m-0">Filters</h4>
                  <div className="d-flex align-items-center justify-content-end">
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Expand
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Card.Title>
                    <Form onSubmit={(e) => filterSearch(e)}>
                      {filterErr && (
                        <div className="p-3  bg-danger text-white bg-danger-main table-red-bg">
                          <div className="img-text">
                            <img src="images/fail.svg" alt="Success" />{' '}
                            {filterErr}
                          </div>
                        </div>
                      )}
                      <Row className="mt-3">
                        <Col sm={userRole !== 4 && userRole !== 3 ? 3 : 4}>
                          <div className="search-bar-input">
                            <TextInput
                              value={searchKeyword}
                              placeholder="Search keyword"
                              onInputChange={(e) => {
                                setFilterErr('');
                                setSearchKeyword(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={userRole !== 4 && userRole !== 3 ? 3 : 4}>
                          <DropdownInput
                            placeholder="Select Subject"
                            options={getSubjectData()}
                            defaultValue={selectOptionSubject}
                            onSelectHandler={(select) => {
                              setFilterErr('');
                              setSelectOptionSubject(select);
                            }}
                          />
                        </Col>
                        <UserRestrictions
                          permittedUsers={[1, 2, 4]}
                          roleId={userRole}
                        >
                          <Col sm={userRole !== 4 ? 3 : 4}>
                            <DropdownInput
                              placeholder="Select Tutor Name"
                              options={getTutorData()}
                              defaultValue={selectOptionTutor}
                              onSelectHandler={(select) => {
                                setFilterErr('');
                                setSelectOptionTutor(select);
                              }}
                            />
                          </Col>
                        </UserRestrictions>
                        <UserRestrictions
                          permittedUsers={[1, 2, 3]}
                          roleId={userRole}
                        >
                          <Col sm={userRole !== 4 && userRole !== 3 ? 3 : 4}>
                            <DropdownInput
                              placeholder="Select School Year"
                              options={getYearData()}
                              defaultValue={selectOptionYear}
                              onSelectHandler={(select) => {
                                setFilterErr('');
                                setSelectOptionYear(select);
                              }}
                            />
                          </Col>
                        </UserRestrictions>

                        <UserRestrictions
                          permittedUsers={[3]}
                          roleId={userRole}
                        >
                          <Col sm={4}>
                            <DateTimePicker
                              label="From"
                              className="form-control"
                              selected={fromDate}
                              placeholder="DD-MM-YYYY"
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => {
                                setFilterErr('');
                                setFromDate(e);
                              }}
                            />
                          </Col>
                          <Col sm={4}>
                            <DateTimePicker
                              className="form-control"
                              label="To"
                              selected={toDate}
                              placeholder="DD-MM-YYYY"
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => {
                                setFilterErr('');
                                setToDate(e);
                              }}
                              minDate={fromDate}
                            />
                          </Col>
                        </UserRestrictions>
                     <Col className="mt-4">
                        <div className="filter-btn filter-clear-btn separate-submit-clear-btn">
                          <Button
                            variant="primary"
                            type="submit"
                            className="ms-4"
                            disabled={disableButton}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="primary"
                            type="button"
                            className="ms-4"
                            onClick={() => onClear()}
                          >
                            Clear
                          </Button>
                        </div>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Title>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary mt-2 mb-2">Study Material</h4>
                <div className="d-flex align-items-center justify-content-end">
                  <UserRestrictions
                    permittedUsers={[1, 2, 3]}
                    roleId={userRole}
                  >
                    <a
                      href=""
                      onClick={() => history.push('/managestudymaterial/add')}
                      className="btn-link"
                    >
                      Add Study Material
                    </a>
                  </UserRestrictions>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="mt-3">
                  <Table
                    role={Number(userRole)}
                    columns={columns}
                    rows={rows}
                    showPagination
                    showCheckbox={Number(userRole) === 4 ? '' : 'showCheckbox'}
                    paginationProps={{
                      ...pagination,
                      activePage: pagination.current_page,
                      onPageChange: (pageNo) => {
                        getTableData(pageNo);
                      },
                    }}
                    bulkActionsLabel="Apply"
                    bulkActions={Number(userRole) === 4 ? '' : bulkActions}
                    tableClass="table-striped"
                    dropdownContainerClass="mb-3"
                    emptyRender={() => (
                      <p className="text-center mb-0">No record found</p>
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
export default withRouter(ManageStudyMaterial);
