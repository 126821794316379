import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import PersonalDetails from './personalDetails.component';
import WorkDetails from './workDetails.component';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb.component';
import { Helmet } from '../../components/FormInputs';

const items = [
  { to: '/', label: 'Home' },
  { to: '/tutor/', label: 'Tutor' },
  { to: '', label: 'Details' },
];

const TutorDetails = (props) => {
  const [tutorId, setTutorId] = useState(null);
  const [title, setTitle] = useState('');
  const [item, setItem] = useState('');

  useEffect(() => {
    const tutId = props.match.params.tutorId;
    setItem('Add Tutor');
    if (tutId) {
      setTitle(tutId ? 'View Manage Tutors' : 'Manage Tutors');
      setItem('Edit Tutor');
      setTutorId(tutId);
    }
  }, []);

  return (
    <>
      <Helmet title={item || title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Manage Tutors</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area personal-work-tab-details">
        <div className="mt-5">
          <h5>{item}</h5>
          <Tabs
            defaultActiveKey="personalDetails"
            id="uncontrolled-tab-example"
          >
            <Tab eventKey="personalDetails" title="Personal Details">
              <PersonalDetails />
            </Tab>
            {tutorId && (
              <Tab eventKey="workDetails" title="Work Details">
                <WorkDetails />
              </Tab>
            )}
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default withRouter(TutorDetails);
