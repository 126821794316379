import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Button, Row, Col, Image } from 'react-bootstrap';
import swal from 'sweetalert';
import { BarGraph } from '../FormInputs';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import Table from '../Table/simpletable.component';
import CustomFunctions from '../../helper/customFunctions';
import UserRestrictions from '../UserRestrictions/userrestrictions.component';

const statusColor = [
  {
    id: 1,
    label: 'Pending',
    classes: 'text-warning',
  },
  {
    id: 2,
    label: 'In Progress',
    classes: 'text-danger',
  },
  {
    id: 3,
    label: 'In Progress',
    classes: 'text-info',
  },
  {
    id: 4,
    label: 'Complete',
    classes: 'text-success',
  },
];

const classAttendanceBackgroundColor = [
        '#004084',
        '#862238',
        '#004084',
        '#862238',
        '#004084',
        '#862238',
        '#004084',
        '#862238',
        '#004084',
        '#862238',
        '#004084',
        '#862238',
      ];

function RightContainer() {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [homeworkRows, setHomeworkRows] = useState([]);
  const [studentRows, setStudentRows] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [homeworkTitle, setHomeworkTitle] = useState('');
  const [className, setClassName] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [homeworkId, setHomeworkId] = useState('');
  const [graphData, setGraphData] = useState({});
  const [classId, setClassId] = useState('');
  const [userId, setUserId] = useState('');
  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [1, 2, 3, 4],
      isDisplay: false,
    },
    {
      id: 'title',
      label: 'Class Title',
      roleAccess: [1, 2, 3, 4],
    },
    {
      id: 'date',
      label: 'Date & Time',
      roleAccess: [1, 2, 3, 4],
    },
    {
      id: 'joinNow',
      label: 'Join',
      roleAccess: [1, 2, 3, 4],
      render: ({ joinNowLink, id, sessionId}) => {
        return (
          <>
            <span className="span-container">
              {joinNowLink ? (
                    <Button
                      variant="primary"
                      type="button"
                      className="ms-4 btn-info"
                      onClick={() => getJoinNowLink( (Number(userRole) === 4) ? sessionId : id)}
                    >
                      Join Now
                    </Button>
              ) : (
                '---'
              )}
            </span>
          </>
        );
      },
    },
  ];

  const studentColumns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [1, 2],
      isDisplay: false,
    },
    {
      id: 'studentName',
      label: 'Student Name',
      roleAccess: [1, 2],
      render: ({ studentName, isDelay }) => {
        return (
          <>
              {isDelay &&
                <Image src="/images/red-flag.gif" />
              }
              {studentName}
          </>
        );
      },
    },
    {
      id: 'year',
      label: 'Year',
      roleAccess: [1, 2],
    },
  ];

  const homeworkColumns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [3, 4],
      isDisplay: false,
    },
    {
      id: 'studentName',
      label: 'Student Name',
      roleAccess: [1, 2, 3],
      render: ({ studentName, isDelay }) => {
        return (
          <>
              {isDelay &&
                <Image src="/images/red-flag.gif" />
              }
              {studentName}
          </>
        );
      },
    },
    {
      id: 'HomeworkTitle',
      label: 'Homework Title',
      roleAccess: [4],
      render: ({ HomeworkTitle, isDelay }) => {
        return (
          <>
              {isDelay &&
                <Image src="/images/red-flag.gif" />
              }
              {HomeworkTitle}
          </>
        );
      },

    },
    {
      id: 'submission',
      label: 'Status',
      roleAccess: [4],
      render: ({ submission, id }) => {
        return (
           <>
           <span
             className={getStatusColor(submission, 'classes')}
           >
             {getStatusColor(submission, 'label', id)}
           </span>
         </>
        );
      },
    },
    {
      id: 'status',
      label: 'Status',
      roleAccess: [1, 2, 3],
      render: ({ status }) => {
        return (
          <>
            <span
              className={getStatusColor(status, 'classes')}
            >
              {getStatusColor(status, 'label')}
            </span>
          </>
        );
      },
    },
  ];

  const getStatusColor = (status, key) => {
    const obj = statusColor.filter((st) => {
      return Number(st.id) === Number(status);
    });
    if (!CustomFunctions.checkIfEmpty(obj, 'A')) {
      return obj[0][key];
    }
    return '';
  };

  useEffect(() => {
    async function getData() {
      const uRole = CustomFunctions.getLocalStorage('role_id');
      const id = CustomFunctions.getLocalStorage('id');
        if (!CustomFunctions.checkIfEmpty(uRole)) {
          setUserRole(Number(uRole));
        }
        if (!CustomFunctions.checkIfEmpty(id)) {
          setUserId(Number(id));
        }
        if (Number(uRole) === 2 || Number(uRole) === 1) {
          getStudentData(1);
          getMonthlyJoiningData(1);
        }
        else if (Number(uRole) === 4) {
          await getStudentClassIdByStudentId(id);
          await getClassAttendanceData(1);
          await getVirtualClassTableData(1);
          await getHomeworkData(1);
        //  await getHomeworkSubmissionData(1);
        }
        else if (Number(uRole) === 3) {
          getTableData(1);
          getHomeworkData(1);
          getHomeworkSubmissionData(1);
        }
    }
    getData();
  }, [homeworkId]);

  useEffect(() => {
    if (userRole) getHomeworkSubmissionData(1);
  }, [userRole, classId, userId]);

  const getStudentClassIdByStudentId = async (userid) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.studentUrl}${userid}/`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'O')) {
      setClassId(data.data.class);
    }
  };

  const getVirtualClassTableData = async (pageNo = 0) => {
    const cPage = pageNo;
    const endPoint = `${Api.virtualClassStudentUrl}`;
    if(Number(userRole) === 4 && (!userId || !classId)){
      return;
    }
    const params = {
      page: cPage,
      status_ids :`${2},${3}`,
      limit: 5,
      class_id : classId,
      sort_direction: 'descending',
      sort_by: 'session__start_time',
    };
    const url = CustomFunctions.generateUrl(endPoint, params);
    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      const row = [];
      data.data.map((session) => {
        const setRowData = {
          id: session.id,
          sessionId:session.session_id,
          title: session.title ? session.title : '-',
          date: session.start_time
            ? `${CustomFunctions.getDMYDate(
                session.start_time
              )} ${session.start_time.slice(11, 16)}`
            : '-',
          joinNowLink : session.join_now,
        };
        row.push(setRowData);
        return session;
      });
      setRows(row);
    }
  };

  const getTableData = async (pageNo = 0) => {
    const cPage = pageNo;
    const endPoint = `${Api.getVirtualClassByUrl}`;

    const params = {
      page: cPage,
      status_ids :`${2},${3}`,
      limit: 5,
      sort_direction: 'descending',
      sort_by: 'start_time',
    };
    const url = CustomFunctions.generateUrl(endPoint, params);

    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      const row = [];
      data.data.map((session) => {
        const setRowData = {
          id: session.id,
          title: session.title ? session.title : '-',
          date: session.start_time
            ? `${CustomFunctions.getDMYDate(
                session.start_time
              )} ${session.start_time.slice(11, 16)}`
            : '-',
          joinNowLink : session.join_now,
          presenterUrl: session.presenter_url || session.attendee_url,
        };
        row.push(setRowData);
        return session;
      });
      setRows(row);
    }
  };

  const getStudentData = async (pageNo = 0) => {
    const cPage = pageNo;

    const endPoint = `${Api.studentUrl}`;

    const params = {
      page: cPage,
      limit: 5,
      sort_direction: 'descending',
      sort_by: 'student__activation_date',
    };

    const url = CustomFunctions.generateUrl(endPoint, params);

    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      const row = [];
      data.data.map((dt) => {
        const setRowData = {
          id: dt.id,
          studentName: dt.full_name,
          year: dt.class_name,
        };
        row.push(setRowData);
        return dt;
      });
      setStudentRows(row);
    }
  };

  const getHomeworkSubmissionData = async (pageNo = 0) => {
    const studentId = CustomFunctions.getLocalStorage('id');
    const cPage = pageNo;
    if (Number(userRole) === 4 && (!userId || !classId )) {
        return;
    }
    const endPoint = `${Api.getTutorHomeworkStudentByTutorIdUrl}`;

    const params = {
      homework_id: (Number(userRole) === 3) ? homeworkId :'',
      student_id: (Number(userRole) === 4) ? studentId :'',
      page: cPage,
      class_id : classId,
      limit: 5,
      sort_direction: 'descending',
      current_timezone: `${new Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    };

    const url = CustomFunctions.generateUrl(endPoint, params);

    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      const row = [];
      data.data.map((dt) => {
        const setRowData = {
          id: dt.id,
          studentName: dt.student_name,
          status: dt.status,
          HomeworkTitle: dt.title,
          submission : dt.status,
          rowClass: (Number(dt.status) === 4 && Number(userRole) === 4) ? 'table-neon-bg' : '',
          isDelay : dt.is_delay
        };
        row.push(setRowData);
        return dt;
      });
      setHomeworkRows(row);
    }
  };

  const getHomeworkData = async (pageNo = 0) => {
    const cPage = pageNo;
    const endPoint = `${Api.getManageHomeworkByUrl}`;
    const params = {
      page: cPage,
      limit: 1,
      sort_direction: 'descending',
      current_timezone: `${new Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    };
    const url = CustomFunctions.generateUrl(endPoint, params);
    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      data.data &&
        data.data.map((dt) => {
          setHomeworkTitle(dt.title);
          setSubjectName(dt.subject_name);
          setClassName(dt.class_name);
          setHomeworkId(dt.id);
          return dt;
        });
    }
  };

  const getMonthlyJoiningData = async () => {
    const endPoint = `${Api.adminDashboardUrl}`;
    const params = {
      activation_date_year:`${CustomFunctions.getBackYearDate()}`,
      created_at_year:`${CustomFunctions.getBackYearDate()}`
    };

    const url = CustomFunctions.generateUrl(endPoint, params);

    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      const studentXCoordinate = data.data.activation_count_student.x_axis;
      const studentYCoordinate = data.data.activation_count_student.y_axis;
      const tutorYCoordinate   = data.data.created_count_tutor.y_axis;
      setGraphData({
          labels: studentXCoordinate,
          datasets: [
            {
              label: 'Students',
              backgroundColor: '#004084',
              borderWidth: 2,
              data: studentYCoordinate
            },
            {
              label: 'Tutor',
              backgroundColor: '#862238',
              borderWidth: 2,
              data: tutorYCoordinate
            }
          ]
      });
    }
  };

  const getClassAttendanceData = async () => {
    const endPoint = `${Api.classAttendanceUrl}`;
    if(Number(userRole) === 4 && (!classId || !userId)){
      return;
    }
    const params = {
       start_time_year: `${CustomFunctions.getBackYearDate()}`,
       class_id: classId
    };
    const url = CustomFunctions.generateUrl(endPoint, params);
    const { data } = await AuthApi.getDataFromServer(url);

    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      const y = data.data.start_time_student.y_axis;
      const yData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const xCoordinate = data.data.start_time_student.x_axis;
      const yCoordinate = JSON.stringify(y) === JSON.stringify(yData)?'':data.data.start_time_student.y_axis;

      setGraphData({
        labels: xCoordinate,
        datasets:[{ data: yCoordinate, backgroundColor: classAttendanceBackgroundColor}]
      });
    }
  };

  const viewHomeworkSubmission = () => {
    if(Number(userRole) === 4) {
      history.push('/managehomework');
    } else {
      history.push(`/studenthomeworkstatus/${homeworkId}`);
    }
  };

  const getJoinNowLink = async (id) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.joinNowLinkUrl}${id}/`
    );

    if (!CustomFunctions.checkIfEmpty(data && data.data, 'O')) {
      const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

      if(data.success){
        // if(isChrome === true){
        //   const win =   window.open(data.data.join_url, '_blank');
        //   win.focus();
        // }else if(isFirefox === true || isSafari === true){
        //   const win =   window.location.assign(data.data.join_url);
        //   win.focus();
        // }else{
        //   const a = document.createElement('a');
        //   a.setAttribute('href', data.data.join_url);
        //   a.setAttribute('target', '_blank');
        //   a.click();
        // }
        if(isChrome === true) {
          const win =   window.open(data.data.join_url, '_blank');
          win.focus();
        } else {
          window.location.assign(data.data.join_url);
        }
      } else {
        swal(data.message[0], '', 'error', {
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
      }
    }
    return null;
  };

  return (
    <>
      <UserRestrictions permittedUsers={[3, 4]} roleId={userRole}>
        <Card>
          <Card.Header>
            <h5 className="font-weight-semibold text-muted">
              Upcoming Class Session
            </h5>
            <Button
              className="ms-2"
              variant="primary"
              onClick={()=>  history.push('/virtualclasses')}
              >
              View Details
            </Button>
          </Card.Header>
          <Card.Body>
            <Table
              role={Number(userRole)}
              columns={columns}
              rows={rows}
              tableClass="table-striped"
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </UserRestrictions>
      <UserRestrictions permittedUsers={[3, 4]} roleId={userRole}>
        <Card>
          <Card.Header>
            <h5 className="font-weight-semibold text-muted">
              Homework Submission<br/>
              <small className='text-primary h6'><i>(Note : <Image src="/images/red-flag.gif" /> - OVERDUE )</i></small>
            </h5>
            <Button
              className="ms-4"
              variant="primary"
              onClick={()=> viewHomeworkSubmission()}
              >
              View Details
            </Button>
          </Card.Header>
          <Card.Body>
            <Row>
              <UserRestrictions permittedUsers={[3]} roleId={userRole}>
              <div className="mt-2 mb-2 homework-tutorial-title">
                <span>{homeworkTitle}
                </span>
                  <div className="homework-badge-gray">
                    <Row>
                      <Col sm={6}>
                        <p>
                          <span>School Year: </span>
                          {className || ''}
                        </p>
                      </Col>
                      <Col sm={6}>
                        <p>
                          <span>Subject:</span> {subjectName || ''}
                        </p>
                      </Col>
                    </Row>
                  </div>
              </div>
              </UserRestrictions>
              <Col className="mt-3">
                <Table
                  role={Number(userRole)}
                  columns={homeworkColumns}
                  rows={homeworkRows}
                  tableClass="table-striped"
                  dropdownContainerClass="mb-3"
                  emptyRender={() => (
                    <p className="text-center mb-0">No record found</p>
                  )}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </UserRestrictions>
      <UserRestrictions permittedUsers={[1, 2, 4]} roleId={userRole}>
      <Card>
        <Card.Header>
          <h5 className="font-weight-semibold text-muted">{(Number(userRole) === 2 || Number(userRole) === 1 )? 'Monthly Joining' :'Class Attendance'}</h5>
        </Card.Header>
        <Card.Body>
          <BarGraph
            data={graphData}
            options={{
              legend: {
                labels: {
                  usePointStyle: true,
                  boxWidth: 6
                },
                display: (Number(userRole) === 2 || Number(userRole) === 1)? Boolean(true) : Boolean(false),
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontSize: 7,
                      beginAtZero: true,
                      autoSkip: false,
                      maxRotation: 45,
                      minRotation: 45,
                    },
                    align: 'center',
                    scaleFontSize: 10,
                    barPercentage: 0.4,
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    barPercentage: 0.4,
                    gridLines: {
                      display: false,
                    },
                   ticks: {
                      padding: 25,
                    },
                  },
                ],
              },
            }}
          />
        </Card.Body>
      </Card>
      </UserRestrictions>
      <UserRestrictions permittedUsers={[1, 2]} roleId={userRole}>
        <Card>
          <Card.Header>
            <h5 className="font-weight-semibold text-muted">
              Student Registration
            </h5>
          </Card.Header>
          <Card.Body>
            <Row>
              <Table
                role={Number(userRole)}
                columns={studentColumns}
                rows={studentRows}
                tableClass="table-striped"
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Row>
          </Card.Body>
        </Card>
      </UserRestrictions>
    </>
  );
}

export default RightContainer;
