import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import CustomFunctions from '../../helper/customFunctions';
import './header.css';

function Header() {
  const code = CustomFunctions.getLocalStorage('access_token');
  const history = useHistory();
  const dispatch = useDispatch();
  const event = new Event('Login', { isLogin: false });
  const newdate = new Date();
  const d = newdate.getDate();
  const m = newdate.getMonth() + 1;
  const y = newdate.getFullYear();
  const currentDate =
    (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y; //eslint-disable-line

  const [userName, setUserName] = useState('');
  const [userMasquerade, setUserMasquerade] = useState(false);

  const getProfile = async () => {
    if (!code) return;

    const { data } = await AuthApi.getDataFromServer(
      `${Api.getProfileByIdUrl}`
    );

    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setUserName(
        data.data.last_name
          ?`${data.data.first_name} ${data.data.last_name}`
          : `${data.data.first_name}`
      );
    } else {
      dispatch({ type: 'LOGIN', value: false });
      history.push('/login');
    }
  };

  useEffect(() => {
    if (!code) {
      const path = window.location.pathname.split('/');
      const extractedPath = path.length > 1 ? path[1].toString() : '';
      if (
        extractedPath !== 'login' &&
        extractedPath !== 'forgot-password' &&
        extractedPath !== 'password' &&
        extractedPath !== 'user-verify-email' &&
        extractedPath !== 'registration'
      ) {
        history.push('/login');
      }
    } else {
      dispatch({ type: 'LOGIN', value: true });
    }
    document.addEventListener('ProfileUpdate', () => {
      getProfile();
    });
    getProfile();

    const masquerade = CustomFunctions.checkUserSwitch();
    setUserMasquerade(masquerade);
  }, []);

  const switchUserBack =

  (obj) => {
    obj.preventDefault();
    swal({
      text: 'Please enter your password.',
      icon: 'warning',
      content: {
        element: 'input',
        attributes: {
          placeholder: 'password',
          type: 'password',
          id: 'txtPassword',
          name: 'txtPassword',
        },
      },
      closeOnClickOutside: false,
      closeModal: false,
      buttons: {
        confirm: {
          text: 'Login',
          value: true,
          visible: true,
          className: '',
          closeModal: false,
        },
        cancelbutton: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'swal-button swal-button--confirm',
          closeModal: true,
        },
      },
    })
      .then((result) => {
        const password = document.getElementById('txtPassword').value;
        if (!result && typeof result === 'object') return;
        if (!password) throw new Error('password required');

        const adminRedirectUrl =
          CustomFunctions.getLocalStorage('masqueradeRedirect');
        const { username } = userMasquerade;

        CustomFunctions.loginUser(
          Api.loginUrl,
          username,
          password,
          adminRedirectUrl,
          () => {
            swal.stopLoading();
            dispatch({ type: 'LOGIN', value: true });
            window.location.href = adminRedirectUrl;
          },
          (e) => {
            swal.stopLoading();
            swal(e, '', 'error');
          }
        );
      })
      .catch((e) => {
        swal(e.message, '', 'error');
        swal.close();
      });
  };

  return (
    <>
      <div className="main-header side-header sticky sticky-pin">
      {userMasquerade && (
          <div className="Masquerade-wrapper container-fluid d-flex align-items-center justify-content-between h-5">
            <div className="main-header-left">
              <span>
                You are logged in as {userName && <> {userName || ''}.</>}
              </span>
            </div>
            <div className="main-header-right">
              <a
                className="back-login"
                href="javascript:void(0);"
                onClick={switchUserBack}
              >
                Back to{' '}
                {CustomFunctions.ucFirst(userMasquerade.role_name || '')}&nbsp;
                <img
                  src="/images/login-button.svg"
                  className="svg"
                  alt="back"
                  width="20px"
                  height="20px"
                />
              </a>
            </div>
          </div>
        )}
        <div className="container-fluid d-flex align-items-center justify-content-between h-100">
          <div className="main-header-left">
            <a className="main-header-menu-icon" href="javascript:void(0)" id="mainSidebarToggle">
              <span />
            </a>
          </div>
          <div className="main-header-right">
            <div className="day-date">
              <h5>{currentDate}</h5>
            </div>
            <div className="profile-name-img d-flex align-items-center">
              <h6>{userName}</h6>
            </div>
            <div className="dropdown d-md-flex header-settings">
              <a
                href="javascript:void(0)"
                className="nav-link icon"
                data-toggle="sidebar-right"
                data-target=".sidebar-right"
              >
                <img src="/images/profile-setting.svg" alt="profile" />
              </a>
              <div className="dropdown-menu">
                <div className="header-navheading text-center">
                  <h5 className="main-notification-title">{userName}</h5>
                </div>
                <ul className="m-0 list-unstyled p-0">
                  <li>
                    <a className="dropdown-item border-top" href="/profile">
                      <img src="/images/user.svg" alt="User" className="svg" />{' '}
                      My Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/profile/edit">
                      <img src="/images/edit.svg" alt="Edit" className="svg" />{' '}
                      Edit Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/change-password">
                      <img
                        src="/images/change-password.svg"
                        alt="Change"
                        className="svg"
                      />{' '}
                      Change Password
                    </a>
                  </li>
                  {userMasquerade && (
                    <li>
                      <a
                        className="dropdown-item"
                        href=""
                        onClick={switchUserBack}
                      >
                        <img
                          src="/images/login-button_gray.svg"
                          width="18px"
                          height="18px"
                          alt="Switch Account"
                        />{' '}
                        Back to{' '}
                        {CustomFunctions.ucFirst(
                          userMasquerade.role_name || ''
                        )}
                        {userMasquerade.first_name && (
                          <>
                            <br />
                            <small className="pl18">
                              (
                              {`${userMasquerade.first_name || ''} ${
                                userMasquerade.last_name || ''
                              }`}
                              )
                            </small>
                          </>
                        )}
                      </a>
                    </li>
                  )}
                  <li>
                    <a
                      className="dropdown-item"
                      href=""
                      onClick={() => {
                        CustomFunctions.clearLocalStorage();
                        document.dispatchEvent(event);
                        dispatch({ type: 'LOGIN', value: false });
                        history.push('/login');
                      }}
                    >
                      <img
                        src="/images/logout-profile.svg"
                        alt="Logout"
                        className="svg"
                      />{' '}
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Header;
