import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './containers/dashboard/Dashboard/dashboard.component';
import Login from './containers/authentication/Login/login.component';
import ForgotPassword from './containers/authentication/ForgotPassword/forgotpassword.component';
import ResetPassword from './containers/authentication/ForgotPassword/resetpassword.component';
import Profile from './containers/profile/Profile/profile.component';
import EditProfile from './containers/profile/Profile/editProfile.component';
import Subject from './containers/learningdashboard/Subjects/subject.component';
import SchoolYear from './containers/learningdashboard/SchoolYear/schoolYear.component';
import ManageStudyMaterial from './containers/managestudymaterial/ManageStudyMaterial/mangeStudyMaterial.component';
import ManageHomework from './containers/managehomework/ManageHomework/mangeHomework.component';
import LearningDashboard from './containers/learningdashboard/learningDashboard.component';
import ManagePassword from './containers/managepassword/ManagePassword/managePassword.component';
import Student from './containers/student/student.component';
import StudentDetails from './containers/student/personalDetails.component';
import AddStudent from './containers/student/addStudent.component';
import Tutor from './containers/tutor/tutor.component';
import TutorDetails from './containers/tutor/tutorDetails.component';
import AddManageHomework from './containers/managehomework/ManageHomework/addMangeHomework.component';
import AddManageStudyMaterial from './containers/managestudymaterial/ManageStudyMaterial/addManageStudyMaterial.component';
import StudentHomeworkStatus from './containers/managehomework/ManageHomework/studentHomeworkStatus.component';
import StudentHomeworkDetails from './containers/managehomework/ManageHomework/studentHomeworkDetails.component';
import NotFound from './containers/not-found/notFound.component';
import StudentProfileDetails from './containers/student/studentDetails.component';
import VirtualClasses from './containers/virtualclassess/VirtualClasses/virtualClassess.component';
import AddVirtualClasses from './containers/virtualclassess/VirtualClasses/addVirtualClasses.component';
import EmailVerify from './containers/authentication/EmailVerify/emailverify.component';
import StudentSignUp from './containers/authentication/Registration/studentSignUp.component';
import StudentAcademicDetails from './containers/student/studentAcademicDetails.component';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/login" exact component={Login} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/password/reset/:token" exact component={ResetPassword} />
      <Route path="/learningdashboard/locations" exact component={Location} />
      <Route path="/learningdashboard/subjects" exact component={Subject} />
      <Route path="/student" exact component={Student} />
      <Route
        path="/learningdashboard/schoolyears"
        exact
        component={SchoolYear}
      />
      <Route path="/profile" exact component={Profile} />
      <Route path="/profile/edit" exact component={EditProfile} />
      <Route
        path="/managestudymaterial"
        exact
        component={ManageStudyMaterial}
      />
      <Route path="/managehomework" exact component={ManageHomework} />
      <Route path="/managehomework/add" exact component={AddManageHomework} />
      <Route
        path="/managehomework/edit/:homeworkId"
        exact
        component={AddManageHomework}
      />
      <Route
        path="/studenthomeworkstatus/:statusId"
        exact
        component={StudentHomeworkStatus}
      />
      <Route path="/learningdashboard" exact component={LearningDashboard} />
      <Route path="/change-password" exact component={ManagePassword} />
      <Route path="/student/addStudent" exact component={AddStudent} />
      <Route path="/student/addStudent/:id" exact component={AddStudent} />
      <Route
        path="/student/studentDetails/:id"
        exact
        component={StudentDetails}
      />
      <Route path="/tutor" exact component={Tutor} />
      <Route path="/tutor/tutorDetails/" exact component={TutorDetails} />
      <Route
        path="/tutor/tutorDetails/:tutorId"
        exact
        component={TutorDetails}
      />
      <Route path="/tutor/tutorViews/:tutorId" exact component={TutorDetails} />
      <Route
        path="/managestudymaterial/add"
        exact
        component={AddManageStudyMaterial}
      />
      <Route
        path="/managestudymaterial/edit/:studyId"
        exact
        component={AddManageStudyMaterial}
      />
      <Route
        path="/studenthomeworkdetails/:refId"
        exact
        component={StudentHomeworkDetails}
      />
      <Route
        path="/student/studentHomeworkDetails/:studentId"
        exact
        component={StudentProfileDetails}
      />
      <Route path="/virtualclasses" exact component={VirtualClasses} />
      <Route path="/virtualclasses/add" exact component={AddVirtualClasses} />
      <Route
        path="/virtualclasses/edit/:virtualClassId"
        exact
        component={AddVirtualClasses}
      />
      <Route path="/user-verify-email/:token" exact component={EmailVerify} />
      <Route path="/registration" exact component={StudentSignUp} />
      <Route
        path="/student/studentAcademicDetails/:studentId"
        exact
        component={StudentAcademicDetails}
      />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
