import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import CustomFunctions from '../../helper/customFunctions';
import Table from '../../components/Table/simpletable.component';
import 'react-datepicker/dist/react-datepicker.css';

const columns = [
  {
    id: 'id',
    label: 'Id',
    roleAccess: [2, 4],
    isDisplay: false,
  },
  {
    id: 'classTitle',
    label: 'Class Title',
    roleAccess: [2, 4],
    render: ({ classTitle }) => {
      return (
        <>
          <span className="span-container">{classTitle}</span>
        </>
      );
    },
  },
  {
    id: 'date',
    label: 'Date',
    roleAccess: [2, 4],
  },
  {
    id: 'attachment',
    label: 'Attachment',
    roleAccess: [2, 4],
    render: ({ attachment }) => {
      return (
        <>
          {' '}
          {attachment
            ? attachment.map((img) => (
                <span className="span-container">
                  <a className="text-info attachment-link-color" href={img.file_url} target="blank">
                    {img.file_url
                      ? decodeURI(
                          img.file_url.split(decodeURI(Api.s3url))
                        ).substring(1)
                      : '-'}
                  </a>
                </span>
              ))
            : '-'}
        </>
      );
    },
  },
  {
    id: 'attendance',
    label: 'Attendance',
    roleAccess: [2, 4],
    render: ({ attendance }) => {

      return (
        <>
          <span
            className={`${attendance !== 'Absent' ? '' : 'text-danger'}`}
          >
            {attendance}
          </span>
        </>
      );
    },
  },
];

function StudentVirtualClass(props) {
  const [rows, setRows] = useState([]);
  const [studentId, setStudentId] = useState('');
  const [userRole, setUserRole] = useState('');
  const [classId, setClassId] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  const { yearId } = props;

  useEffect(() => {
    const id = props.match.params.studentId;
    setClassId(yearId);
    if (id) {
      const uRole = CustomFunctions.getLocalStorage('role_id');
      setStudentId(id);
      if (!CustomFunctions.checkIfEmpty(uRole)) {
        setUserRole(Number(uRole));
      }
      getTableData(id,yearId,1);
    }
  }, [yearId]);

  const getTableData = async (id, classid, pageNo = 0) => {
    const page = pageNo || pagination.current_page;
    const endPoint = `${Api.virtualClassStudentUrl}`;
    const params = {
      student_id: id,
      class_id: classid,
      page,
      sort_direction: 'descending',
      sort_by: 'session__start_time',
      limit: pagination.itemsPerPage,
    };
    
    const url = CustomFunctions.generateUrl(endPoint, params);

    const { data } = await AuthApi.getDataFromServer(url);

    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      const row = [];
      data.data.map((dt) => {
        row.push({
          id: dt.id,
          classTitle: dt.title,
          date: CustomFunctions.getDMYDate(dt.start_time),
          attachment: dt.attachements,
          attendance: dt.attendance_report,
          attendeeUrl: dt.attendee_url,
        });
        return dt;
      });
      setRows(row);
    }
  };

  return (
    <>
      <section className="content-area">
        <div className="mt-5">
          <Row>
            <Col className="mt-3">
              <Table
                role={Number(userRole)}
                columns={columns}
                rows={rows}
                showPagination
                paginationProps={{
                  ...pagination,
                  activePage: pagination.current_page,
                  onPageChange: (pageNo) => {
                    getTableData(studentId, classId, pageNo);
                  },
                }}
                tableClass="table-striped"
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
export default withRouter(StudentVirtualClass);
