import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Row, Col, Card, Form, Image, Button } from 'react-bootstrap';
import swal from 'sweetalert';
import { DropdownInput, Helmet } from '../../components/FormInputs';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import CustomFunctions from '../../helper/customFunctions';

const WorkDetails = (props) => {
  const history = useHistory();
  const [subjectList, setSubjectList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [title, setTitle] = useState('');
  const [schoolYearSubject, setSchoolYearSubject] = useState([
    {
      selectOptionSubject: [],
      selectOptionYear: '',
      subjectErr: '',
      yearErr: '',
    },
  ]);

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setTitle('Manage Tutors');
    getSubjectList();
    getYearList();
    getClassSubjectData();
  }, []);

  const getSubjectList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.subjectUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setSubjectList(data.data);
    }
  };

  const getYearList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.schoolYearUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setYearList(data.data);
    }
  };

  const getSubjectData = () => {
    if (!subjectList) return [];
    const subjectOptions = [];
    subjectList.map((sub) => {
      return subjectOptions.push({
        label: sub.name,
        id: sub.id,
        value: sub.id,
      });
    });
    return subjectOptions;
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList.map((yr) => {
      return yearOptions.push({
        label: yr.name,
        id: yr.id,
        value: yr.id,
      });
    });
    return yearOptions;
  };

  const addSchoolYearSubject = () => {
    const allSchoolYearTutors = [...schoolYearSubject];
    allSchoolYearTutors.push({
      selectOptionSubject: [],
      selectOptionYear: '',
      subjectErr: '',
      yearErr: '',
    });
    setSchoolYearSubject([...allSchoolYearTutors]);
  };

  const removeschoolYearSubject = (index) => {
    const allSchoolYearSubject = [...schoolYearSubject];
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this record?',
      icon: 'warning',
      dangerMode: true,
      buttons: true,
      closeOnClickOutside: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result) {
        allSchoolYearSubject.splice(index, 1);
        setSchoolYearSubject([...allSchoolYearSubject]);
      }
    });
  };

  const onCancel = () => {
    history.push('/tutor');
  };

  const updateStudentShoolYearSubject = async () => {
    const tutorSubjectYearArr = [];

    if (schoolYearSubject.length > 0) {
      schoolYearSubject.map((st) => {
        const subjectArr = [];
        st &&
          st.selectOptionSubject &&
          st.selectOptionSubject.map((subj) => {
            subjectArr.push(Number(subj.id));
            return subjectArr;
          });

        tutorSubjectYearArr.push({
          classes: st.selectOptionYear.id,
          subject: subjectArr,
        });
        return st;
      });
    }

    const postData = {
      tutor: Number(props.match.params.tutorId),
      tutor_subjects: tutorSubjectYearArr,
    };

    const callback = AuthApi.postDataToServer;
    const endPoint = `${Api.getTutorClassSubjectByUrl}`;
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      swal(data && data.message[0], '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result) {
          setDisableButton(false);
          history.push('/tutor');
        }
      });
    } else {
      setDisableButton(false);
      swal(message, '', 'error', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let isValid = true;
    if (disableButton) return;

    setDisableButton(true);
    if (schoolYearSubject.length > 0) {
      schoolYearSubject.map((st, index) => {
        schoolYearSubject[index].subjectErr = '';
        schoolYearSubject[index].yearErr = '';

        if (!st.selectOptionYear) {
          schoolYearSubject[index].yearErr = 'Please select school year';
          isValid = false;
        }

        if (
          st.selectOptionSubject === '' ||
          st.selectOptionSubject.length === 0
        ) {
          schoolYearSubject[index].subjectErr = 'Please select subject';
          isValid = false;
        }

        if (schoolYearSubject.length > 1 && isValid) {
          const validYearSubject = schoolYearSubject.filter((j) => {
            if (
              JSON.stringify(st.selectOptionSubject.map((k) => k.id).sort()) ===
                JSON.stringify(j.selectOptionSubject.map((k) => k.id).sort()) &&
              Number(st.selectOptionYear.id) === Number(j.selectOptionYear.id)
            ) {
              return true;
            }
            return false;
          });

          let lastIndex = 0;

          schoolYearSubject.map((l, i) => {
            if (
              schoolYearSubject.filter(
                (j) =>
                  JSON.stringify(
                    st.selectOptionSubject.filter((k) => k.id).sort()
                  ) ===
                    JSON.stringify(
                      j.selectOptionSubject.filter((k) => k.id).sort()
                    ) &&
                  Number(st.selectOptionYear.id) ===
                    Number(j.selectOptionYear.id)
              )
            ) {
              lastIndex = i;
            }
            return lastIndex;
          });

          if (validYearSubject.length > 1 && lastIndex === index) {
            schoolYearSubject[index].subjectErr =
              'Duplicate subject & class not allowed';
            isValid = false;
            setDisableButton(false);
          }
        }
        setSchoolYearSubject([...schoolYearSubject]);
        return schoolYearSubject;
      });
    }

    if (isValid) {
      updateStudentShoolYearSubject();
    }
  };

  const getClassSubjectData = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.getTutorClassSubjectByUrl}${props.match.params.tutorId}/`
    );

    if (data && data.data && data.data.tutor_subjects) {
      const classSubjectObj = {};
      data.data.tutor_subjects &&
        data.data.tutor_subjects.map((classSubject) => {
          if (!classSubjectObj[classSubject.class_id]) {
            classSubjectObj[classSubject.class_id] = {
              subjectErr: '',
              yearErr: '',
            };
            classSubjectObj[classSubject.class_id].selectOptionSubject = [
              {
                label: `${classSubject.subject_name}`,
                id: classSubject.subject_id,
                value: `${classSubject.subject_id}`,
              },
            ];
            classSubjectObj[classSubject.class_id].selectOptionYear = {
              label: `${classSubject.class_name}`,
              id: classSubject.class_id,
              value: classSubject.class_id,
            };
          } else {
            classSubjectObj[classSubject.class_id].selectOptionSubject.push({
              label: `${classSubject.subject_name}`,
              id: classSubject.subject_id,
              value: `${classSubject.subject_id}`,
            });
          }

          return classSubjectObj;
        });
      setSchoolYearSubject(Object.values(classSubjectObj));
    }
  };

  return (
    <>
      <Helmet title={title} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form autoComplete="off" onSubmit={(e) => submitForm(e)}>
              <Row className="mt-3 align-items-center">
                {schoolYearSubject &&
                  schoolYearSubject.map((st, index) => (
                    <>
                      <Col sm={5}>
                        <DropdownInput
                          label="School Year"
                          options={getYearData()}
                          asterisk="*"
                          placeholder="Select School Year"
                          defaultValue={st.selectOptionYear}
                          onSelectHandler={(select) => {
                            schoolYearSubject[index].selectOptionSubject = '';
                            schoolYearSubject[index].selectOptionYear = select;
                            setSchoolYearSubject([...schoolYearSubject]);
                          }}
                          errorMessage={schoolYearSubject[index].yearErr}
                        />
                      </Col>
                      <Col sm={5}>
                        <DropdownInput
                          label="Subject"
                          options={getSubjectData()}
                          asterisk="*"
                          placeholder="Select Subject"
                          defaultValue={st.selectOptionSubject}
                          onSelectHandler={(select) => {
                            schoolYearSubject[index].selectOptionSubject =
                              select;
                            setSchoolYearSubject([...schoolYearSubject]);
                          }}
                          isMulti
                          errorMessage={schoolYearSubject[index].subjectErr}
                        />
                      </Col>
                      <Col sm={2} className="mt-10">
                        {index !== 0 && (
                          <a
                            href="/"
                            className="p-r-2"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              removeschoolYearSubject(index);
                            }}
                          >
                            <Image src="/images/delete.png" />
                          </a>
                        )}
                      </Col>
                    </>
                  ))}
              </Row>
              <Row>
                <Col sm={6}>
                  <Button
                    variant="primary"
                    onClick={() => addSchoolYearSubject()}
                  >
                    Add School Year
                  </Button>
                </Col>
              </Row>
              <div className="float-end">
                <Button variant="secondary" onClick={() => onCancel()}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  disabled={disableButton}
                  className="ms-4"
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default withRouter(WorkDetails);
