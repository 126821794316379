import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Form } from 'react-bootstrap';

export default function PhoneInputComponent(props) {
  const {
    label,
    country,
    value,
    onInputChange,
    placeholder,
    disabled,
    name,
    containerClass,
    errorMessage,
    onlyCountries,
    asterisk,
  } = props;

  return (
    <>
      <Form.Group className={containerClass}>
        <Form.Label>
          {label}
          <span style={{ color: 'red' }}>{asterisk}</span>
        </Form.Label>
        <PhoneInput
          placeholder={placeholder}
          country={country}
          value={value}
          name={name}
          disabled={disabled}
          onlyCountries={onlyCountries}
          onChange={(e) => {
            if (onInputChange) onInputChange(e);
          }}
        />
        <p style={{ color: 'red' }}> {errorMessage} </p>
      </Form.Group>
    </>
  );
}
