import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { Helmet } from '../../../components/FormInputs';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import CustomFunctions from '../../../helper/customFunctions';

function EmailVerify(props) {
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();

  const verifyToken = async (tkn) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.verifyEmailUrl}?token=${tkn}`
    );

    if (data && data.success) {
        swal('Thank you for your verification, please check your email for login details.', '', 'success', {
          closeOnClickOutside: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result) {
            localStorage.removeItem('isLoaded');
            props.history.push('/login');
          }
        });
      } else {
        localStorage.removeItem('isLoaded');
        swal('Invalid Token', '', 'error');
        props.history.push('/login');
      }
    };

  useEffect(() => {
    setTitle('Email Verification');
    dispatch({ type: 'LOGIN', value: false });
    CustomFunctions.clearLocalStorage();
    const isLoaded = localStorage.getItem('isLoaded');
    const matchUrltoken = props.match.params.token;
      if (!matchUrltoken) {
        window.location = '/login';
      }
      if(!isLoaded){
        localStorage.setItem('isLoaded', true);
        verifyToken(matchUrltoken);
      }
    }, []);

  return (
    <>
      <Helmet title={title} />
      <div className="login-page recover-password">
        <div className="logo text-center">
          <a href="#">
            {' '}
            <img src="/images/PTL_Logo.png" alt="img" />
          </a>
        </div>
        <div className="title text-center">
          <h5>Email Verification</h5>
        </div>
      </div>
    </>
  );
}

export default withRouter(EmailVerify);
