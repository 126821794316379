import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Form, Col, Row } from 'react-bootstrap';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import { TextInput, Helmet, PhoneInput } from '../../components/FormInputs';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb.component';
import CustomFunctions from '../../helper/customFunctions';

const additionalChoices = [
  { value: 1, name: 'Ongoing classes' },
  { value: 2, name: '11 Plus Mock Exams' },
  {
    value: 3,
    name: 'Summer camp ( An introduction to the 11 Plus programme for Year 4 and 5)',
  },
  { value: 4, name: 'Easter Break (Year 11 Exam Paper practice)"' },
];

const items = [
  { to: '/', label: 'Home' },
  { to: '/student', label: 'Students' },
];

const PersonalDetails = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [address, setAddress] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [guardianName, setGuardianName] = useState('');
  const [guardianNo, setGuardianContactNo] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [year, setYear] = useState(null);
  const [examFocus, setExamFocus] = useState(null);
  const [allergies, setAllergies] = useState();
  const [learningDisabilities, setLearningDisabilities] = useState();
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [gcseIcse, setGcseIcse] = useState('');
  const [pplTreeLearning, setPPLTreeLearningValue] = useState();
  const [activationDate, setActivateDate] = useState('');
  const [guardianEmail, setGuardianEmail] = useState('');
  const [title, setTitle] = useState('');
  const [subjectTopics, setSubjectTopics] = useState('');
  const [disabilityText, setDisabilityText] = useState('');
  const [allergiesText, setAllergiesText] = useState('');
  const [showOtherPTLText, setShowOtherPTLText] = useState(false);
  const [otherPTLText, setOtherPTLText] = useState('');
  const [showDisabilityText, setShowDisabilityText] = useState(false);
  const [showAllergiesText, setShowAllergiesText] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    setTitle('View Student');
    getStudentDetails();
  }, []);

  const getStudentDetails = async () => {
    const endPoint = `${Api.studentUrl}${props.match.params.id}/`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (!CustomFunctions.checkIfEmpty(data.data, 'O')) {
      setFirstName(data.data.first_name);
      setLastName(data.data.last_name);
      setEmailAddress(data.data.email);
      setContactNo(data.data.mobile);
      setGuardianName(data.data.guardian_name);
      setGuardianContactNo(data.data.guardian_contact);
      setAddress(data.data.address);
      setSchoolName(data.data.school_name);
      setYear(data.data.class_name);
      setGcseIcse(data.data.sub_attention);

      if (data.data.exam_focus) {
        setExamFocus(data.data.exam_focus);
      }

      if (data.data.additional_details) {
        const result = additionalChoices.find((ad) => {
          return ad.value === data.data.additional_details;
        });
        setAdditionalDetails(result.name);
      }
      if (data.data.peepal_tree_learn) {
        setPPLTreeLearningValue(data.data.peepal_tree_learn);
      }
      setAllergies(data.data.allergies);
      setLearningDisabilities(data.data.learning_disable);
      setActivateDate(
        data.data.activation_date
          ? CustomFunctions.getDate(data.data.activation_date)
          : ''
      );
      setGuardianEmail(data.data.guardian_email);
      setSubjectTopics(data.data.subject_topics);

      if (data.data.learning_disable === true) {
        setShowDisabilityText('true');
        setDisabilityText(`${data.data.reg_known_disabilities}`);
      }
      if (data.data.allergies === true) {
        setShowAllergiesText('true');
        setAllergiesText(`${data.data.reg_known_allergies}`);
      }
      if (data.data.peepal_tree_learn_other) {
        setShowOtherPTLText(true);
        setOtherPTLText(`${data.data.peepal_tree_learn_other}`);
      }
      setUserName(data.data.username);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Students</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary mt-2 mb-2">{title}</h4>
                {activationDate !== '' && (
                  <>
                    <div className="p-r-2 font-weight-semibold float-end">
                      Activation Date : {activationDate || ''}
                    </div>
                  </>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <h5 className="mb-3">Personal Details</h5>
                  <hr />
                  <Col sm={3}>
                    <Form.Label className="required-field">
                      First Name
                    </Form.Label>
                    <TextInput
                      name="firstName"
                      containerClass="form-group"
                      placeholder="First Name"
                      value={firstName || ''}
                      disabled
                    />
                  </Col>
                  <Col sm={3}>
                    <Form.Label className="required-field">
                      Last Name
                    </Form.Label>
                    <TextInput
                      name="lastName"
                      containerClass="form-group"
                      value={lastName || ''}
                      disabled
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label className="required-field">
                      Email Address
                    </Form.Label>
                    <TextInput
                      name="emailAddress"
                      containerClass="form-group"
                      value={emailAddress || ''}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Label className="required-field">Username</Form.Label>
                    <TextInput
                      name="username"
                      containerClass="form-group"
                      value={userName || ''}
                      disabled
                    />
                  </Col>
                  <Col sm={3}>
                    <div className="contact-number">
                      <PhoneInput
                        label="Contact No"
                        name="contactNo"
                        country="gb"
                        onlyCountries={['in', 'gb']}
                        placeholder="Contact No"
                        value={contactNo || ''}
                        disabled="true"
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Form.Label className="required-field">Address</Form.Label>
                    <TextInput
                      name="address"
                      containerClass="form-group"
                      value={address || ''}
                      disabled
                    />
                  </Col>
                  
                </Row>
                <Row>
                  <Col>
                    <Form.Label className="required-field">
                      Parent/Guardian Name
                    </Form.Label>
                    <TextInput
                      name="guardianName"
                      containerClass="form-group"
                      value={guardianName || ''}
                      disabled
                    />
                  </Col>
                  <Col>
                    <div className="contact-number">
                      <PhoneInput
                        label="Guardian Contact No"
                        name="guardianNo"
                        country="gb"
                        onlyCountries={['in', 'gb']}
                        placeholder="Parent/Guardian Contact No"
                        value={guardianNo || ''}
                        disabled="true"
                      />
                    </div>
                  </Col>
                  <Col>
                    <Form.Label className="required-field">
                      Parent/Guardian Email.
                    </Form.Label>
                    <TextInput
                      name="guardianEmail"
                      containerClass="form-group"
                      placeholder="Parent/Guardian Email"
                      value={guardianEmail || ''}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <h5>Academic Details</h5>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Label className="required-field">
                      Name of School
                    </Form.Label>
                    <TextInput
                      name="schoolName"
                      value={schoolName || ''}
                      disabled
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Label className="required-field">
                      School Year
                    </Form.Label>
                    <TextInput
                      containerClass="form-group"
                      value={year || ''}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <TextInput
                      label="Subject topics that requires further attention"
                      name="subjectTopics"
                      containerClass="form-group"
                      value={subjectTopics || ''}
                      Row={5}
                      as="textarea"
                      disabled
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label className="required-field">
                      Additional Details
                    </Form.Label>
                    <TextInput
                      containerClass="form-group"
                      value={additionalDetails || ''}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Label className="required-field">
                      Exam Focus
                    </Form.Label>
                    <TextInput
                      containerClass="form-group"
                      value={examFocus || ''}
                      disabled
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Label>
                      GCSE/IGCSE Subject Board(if applicable)
                    </Form.Label>
                    <TextInput
                      name="gcseIcse"
                      containerClass="form-group"
                      placeholder=""
                      value={gcseIcse || ''}
                      onInputChange={(e) => setGcseIcse(e.target.value)}
                      disabled
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <h5>Other Details</h5>
                    <hr />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Label>Any Known Learning Disabilities</Form.Label>
                    <div>
                      <TextInput
                        containerClass="form-group"
                        value={learningDisabilities === true ? 'Yes' : 'No'}
                        disabled
                      />
                      {showDisabilityText === 'true' && (
                        <TextInput
                          name="disabilityText"
                          containerClass="form-group"
                          value={disabilityText}
                          disabled
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Form.Label>
                      Any Known Allergies/ Current Medication
                    </Form.Label>
                    <div>
                      <TextInput
                        containerClass="form-group"
                        value={allergies === true ? 'Yes' : 'No'}
                        disabled
                      />
                      {showAllergiesText === 'true' && (
                        <TextInput
                          name="allergiesText"
                          containerClass="form-group"
                          value={allergiesText}
                          disabled
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Label>
                      Where did Student hear of Peepal Tree Learning?
                    </Form.Label>
                    <div>
                      <TextInput
                        containerClass="form-group"
                        value={pplTreeLearning || ''}
                        disabled
                      />
                      {showOtherPTLText === true && (
                        <TextInput
                          name="otherPTLText"
                          containerClass="form-group"
                          value={otherPTLText}
                          disabled
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default withRouter(PersonalDetails);
