import React from 'react';
import { Form } from 'react-bootstrap';

export default function RadioComponent(props) {
  const {
    id,
    // value,
    onSelectRadioButton,
    type,
    disabled,
    options,
    inputClass,
    name,
    containerClass,
    checked,
    errorMessage,
  } = props;

  return (
    <>
      <Form.Group className={containerClass}>
        {options &&
          options.map((op) => (
            <Form.Check
              id={id}
              type={type}
              name={name}
              value={op && op.value}
              onChange={(e) => {
                if (onSelectRadioButton) onSelectRadioButton(e);
              }}
              label={op.label}
              disabled={disabled}
              className={inputClass}
              checked={checked === op.value}
            />
          ))}
        <p style={{ color: 'red' }}> {errorMessage} </p>
      </Form.Group>
    </>
  );
}
