import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CustomFunctions from './helper/customFunctions';
import Routes from './routes';
import Layout from './components/Layout/layout.component';

const token = CustomFunctions.getLocalStorage('access_token');

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(token ? true : false); // eslint-disable-line

  const checkLoggedIn = () => {
    if (!token) {
      const path = window.location.pathname.split('/');
      const extractedPath = path.length > 1 ? path[1].toString() : '';
     
      if (
        extractedPath !== 'login' &&
        extractedPath !== 'forgot-password' &&
        extractedPath !== 'password' &&
        extractedPath !== 'user-verify-email' &&
        extractedPath !== 'registration'
      ) {
        history.push('/login');
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(true);
      }
    } else {
      setIsLoggedIn(true);
      dispatch({ type: 'LOGIN', value: true });
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, [isLoggedIn, token]);

  return (
    <>
      {isLoggedIn ? (
        <Layout>
          <Switch>
            <Route path="/" component={Routes} />
          </Switch>
        </Layout>
      ) : (
        ''
      )}
    </>
  );
}

export default App;
