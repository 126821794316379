import { useEffect, useState } from 'react';
import Doughnut from 'stark-doughnut-graph';
import { withRouter, useHistory } from 'react-router-dom';
import { Tabs, Tab, Card, Row, Col } from 'react-bootstrap';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import CustomFunctions from '../../helper/customFunctions';
import StudentHomework from './studentHomework.component';
import StudentVirtualClass from './studentVirtualClass.component';
import StudentStudyMaterial from './studentStudyMaterial.component';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb.component';
import { Helmet } from '../../components/FormInputs';

const items = [
  { to: '/', label: 'Home' },
  { to: '', label: 'Students' },
];

const status = [
  { value: 'complete', label: 'Complete' },
  { value: 'incomplete', label: 'Incomplete' },
  { value: 'in_progress', label: 'Inprogress' },
];

const attendance = [
  { value: 'present', label: 'Present' },
  { value: 'absent', label: 'Absent' },
];

const StudentDetails = (props) => {
  const history = useHistory();
  const [studentName, setStudentName] = useState('');
  const [studentClassName, setStudentClassName] = useState('');
  const [title, setTitle] = useState('');
  const [doughnutData, setDoughnutData] = useState([]);
  const [centerPercentage, setCenterPercentage] = useState('');
  const [homeworkGraphData, setHomeworkGraphData] = useState([]);
  const [homeworkLegendData, setHomeworkGraphLegendData] = useState([]);
  const [legendData, setLegendData] = useState([]);
  const [virtualClassGraphData, setVirtualClassGraphData] = useState([]);
  const [virtualClassGraphLegendData, setVirtualClassGraphLegendData] = useState([]);
  const [studentId, setStudentId] = useState(null);
  const [type, setType] = useState('Homework');
  const [legendsColorCode, setLegegendsColorCode] = useState([]);
  const [dataColorCode, setDataColorCode] = useState([]);
  const [displayGraph,setDisplayGraph] = useState(true);
  const [classId,setStudentClassId] = useState(null);

  useEffect(async () => {
    const StudentId = props.match.params.studentId;
    if (!Number(StudentId)) {
      history.push('/');
      return;
    }
    setTitle('Student Profile');
    setStudentId(StudentId);
    getStudentList(StudentId);
    getHomeworkGraphData(StudentId);
  }, []);

  useEffect(() => {
    setDoughnutData(
      type === 'Homework' ? homeworkGraphData : virtualClassGraphData
    );
    setLegendData(
      type === 'Homework' ? homeworkLegendData : virtualClassGraphLegendData
    );
  }, [homeworkGraphData, virtualClassGraphData]);

  const getStudentList = async (id) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.studentUrl}${id}/`);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setStudentClassName(data.data.class_name);
      await setStudentClassId(data.data.class);
      setStudentName(data.data.full_name);
    }
  };

  const getHomeworkGraphData = (id) => {
    const tutorId = CustomFunctions.getLocalStorage('id');
    return new Promise(async (resolve) => {
      const { data } = await AuthApi.getDataFromServer(
        `${Api.graphApiHomework}?tutor_id=${tutorId}&student_id=${id}`
      );
      setType('Homework');

      const homeworkGraphArray = [];
      const homeworkLegendArray = [];
      const legendsColorScale = [];
      setCenterPercentage('');

      if (!CustomFunctions.checkIfEmpty(data, 'O')) {
        let statusName = '';
        const homeWorkGraphRecords = Object.entries(data.data);
        const average = homeWorkGraphRecords.filter(
          (key) => key[0] === 'average'
        );

        if (average.length) {
          setCenterPercentage(`${average[0][1]}%`);
          homeWorkGraphRecords.pop();
        }

        homeWorkGraphRecords.map((dt) => {
          return homeworkGraphArray.push({
            x: dt[0],
            y: dt[1],
          });
        });

        homeWorkGraphRecords.map((dt, i) => {
          legendsColorScale.push(getHomeworkColorCode(i));
           status && status.map((st)=>{
            if (st.value === dt[0])
            statusName = st.label;
            return st;
          });
          return homeworkLegendArray.push({
            name: `${statusName} ${dt[1]}%`,
            symbol: { fill: getHomeworkColorCode(i) },
          });
        });
        setHomeworkGraphLegendData(homeworkLegendArray);
        setHomeworkGraphData(homeworkGraphArray);
        setLegegendsColorCode(legendsColorScale);
        setDataColorCode(legendsColorScale);
        resolve();
      } else {
        setHomeworkGraphLegendData(homeworkLegendArray);
        setHomeworkGraphData(homeworkGraphArray);
        setLegegendsColorCode(legendsColorScale);
        setDataColorCode(legendsColorScale);
        resolve();
      }
    });
  };

  const getHomeworkColorCode = (index) => {
    let code = '';
    switch (index) {
      case 0:
        code = '#24CBD3';
        break;
      case 1:
        code = '#F975C3';
        break;
      case 2:
        code = '#F4C222';
        break;
      default:
        code = '#24CBD3';
    }
    return code;
  };

  const getVirtualColorCode = (index) => {
    let code = '';
    switch (index) {
      case 0:
        code = '#24CBD3';
        break;
      case 1:
        code = '#F975C3';
        break;
      default:
        code = '#24CBD3';
    }
    return code;
  };

  const getVirtualGraphData = (id) => {
    // eslint-disable-line
    return new Promise(async (resolve) => {
      const { data } = await AuthApi.getDataFromServer(
        `${Api.graphApiVirtualClass}?student_id=${id}`
      );

      setType('Virtualclass');

      const virtualClassGraphArray = [];
      const VirtualClassLegendArray = [];
      const legendsColorScale = [];
      setCenterPercentage('');

      if (!CustomFunctions.checkIfEmpty(data, 'O')) {

        const virtualClassGraphRecords = Object.entries(data.data);
        const average = virtualClassGraphRecords.filter(
          (key) => key[0] === 'average'
        );
        if (average.length) {
          setCenterPercentage(`${average[0][1]}%`);
          virtualClassGraphRecords.pop();
        }

        virtualClassGraphRecords.map((dt) => {
          return virtualClassGraphArray.push({
            x: dt[0],
            y: dt[1],
          });
        });

        let attendanceName = '';
        virtualClassGraphRecords.map((dt, i) => {
          legendsColorScale.push(getVirtualColorCode(i));
          attendance && attendance.map((attend)=>{
            if (attend.value === dt[0])
            attendanceName = attend.label;
            return attend;
          });
          return VirtualClassLegendArray.push({
            name: `${attendanceName} ${dt[1]}%`,
            symbol: { fill: getVirtualColorCode(i) },
          });
        });
        setVirtualClassGraphLegendData(VirtualClassLegendArray);
        setVirtualClassGraphData(virtualClassGraphArray);
        setLegegendsColorCode(legendsColorScale);
        setDataColorCode(legendsColorScale);
        resolve();
      }else {
        setVirtualClassGraphLegendData(VirtualClassLegendArray);
        setVirtualClassGraphData(virtualClassGraphArray);
        setLegegendsColorCode(legendsColorScale);
        setDataColorCode(legendsColorScale);
        resolve();
      }
    });
  };

  const handleTabSelect = (key) => {
    if (key === 'studentHomework') {
      setDisplayGraph(true);
      getHomeworkGraphData(studentId);
    } else if(key === 'StudentVirtualClass') {
      setDisplayGraph(true);
      getVirtualGraphData(studentId);
    } else if (key === 'StudentStudyMaterial') {
      setDisplayGraph(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Student Profile</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <Card>
          <Card.Header>
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="text-primary mt-2 mb-2">Student Profile</h4>
              <div className="d-flex align-items-center justify-content-end">
                <a
                  href=""
                  onClick={() => history.push('/student')}
                  className="btn-link"
                >
                  Back
                </a>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div>
              <Row className="student-profile-row align-items-center">
                <Col md={7} className="d-flex align-items-start">
                  <div className="student-profile-title-name">
                    <h3 className="card-title mb-0">{studentName || ''}</h3>
                  </div>
                  <div className="school-year-name font-weight-bold">
                    <p>School Year {studentClassName || ''}</p>
                  </div>
                </Col>
                <Col md={5}>
                {
                  displayGraph && (
                  <Doughnut
                    id="dougnut"
                    data={doughnutData}
                    title={centerPercentage}
                    height="200"
                    width="200"
                    constrainToVisibleArea="true"
                    legendOrientation="vertical"
                    legendPosition="right"
                    legendData={legendData}
                    legends={{
                      colorScale: legendsColorCode,
                    }}
                    padding={{
                      bottom: 0,
                      left: 10,
                      right: 10,
                      top: 0,
                    }}
                    colorScale={dataColorCode}
                    wrapperSize={{ height: '150px', width: '100%' }}
                  />
                )}
                </Col>
              </Row>
              <Tabs
                defaultActiveKey="studentHomework"
                id="uncontrolled-tab-example"
                onSelect={handleTabSelect}
              >
                {classId && (<Tab eventKey="studentHomework" title="Homework Submission">
                  <StudentHomework classid={classId && classId}/>
                </Tab>)}

                {classId && (<Tab eventKey="StudentVirtualClass" title="Virtual Class">
                  <StudentVirtualClass classid={classId && classId} />
                </Tab>)}

                {classId && (<Tab eventKey="StudentStudyMaterial" title="Study Material">
                  <StudentStudyMaterial classid={classId && classId} />
                </Tab>)}
              </Tabs>
            </div>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default withRouter(StudentDetails);
