import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import CustomFunctions from '../../helper/customFunctions';
import Table from '../../components/Table/simpletable.component';
import 'react-datepicker/dist/react-datepicker.css';

const columns = [
  {
    id: 'tutor_name',
    label: 'Tutor Name',
    roleAccess: [2, 4],
  },
  {
    id: 'schoolYear',
    label: 'School Year',
    roleAccess: [2, 4],
  },
  {
    id: 'subject',
    label: 'Subject',
    roleAccess: [2, 4],
  },
];

function StudentTutorSubject(props) {
  const [rows, setRows] = useState([]);
  const [studentId, setStudentId] = useState('');
  const [userRole, setUserRole] = useState('');
  const [classId, setClassId] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  const { yearId } = props;

  useEffect(() => {
    const id = props.match.params.studentId;
    setClassId(yearId);
    if (id) {
      const uRole = CustomFunctions.getLocalStorage('role_id');
      setStudentId(id);
      if (!CustomFunctions.checkIfEmpty(uRole)) {
        setUserRole(Number(uRole));
      }
      getTableData(id, yearId, 1);
    }
  }, [yearId]);

  const getTableData = async (id, classid, pageNo = 0) => {
    const page = pageNo || pagination.current_page;
    const endPoint = `${Api.getStudentTutorSubjectUrl}`;
    const params = {
      student_id: id,
      class_id: classid,
      page,
      limit: pagination.itemsPerPage,
      sort_direction: 'ascending',
    };

    const url = CustomFunctions.generateUrl(endPoint, params);
    const { data } = await AuthApi.getDataFromServer(url);

    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      const row = [];
      data.data.map((dt) => {
        row.push({
          id: dt.id ? dt.id : '-',
          tutor_name: dt.tutor_full_name,
          schoolYear: dt.class_name ? dt.class_name : '-',
          subject: dt.subject_name ? dt.subject_name : '-',
          attachment: dt.attachements,
        });
        return dt;
      });
      setRows(row);
    }
  };

  return (
    <>
      <section className="content-area">
        <div className="mt-5">
          <Row>
            <Col className="mt-3">
              <Table
                role={Number(userRole)}
                columns={columns}
                rows={rows}
                showPagination
                paginationProps={{
                  ...pagination,
                  activePage: pagination.current_page,
                  onPageChange: (pageNo) => {
                    getTableData(studentId, classId, pageNo);
                  },
                }}
                tableClass="table-striped"
                dropdownContainerClass="mb-3"
                emptyRender={() => (
                  <p className="text-center mb-0">No record found</p>
                )}
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
export default withRouter(StudentTutorSubject);
