import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Row, Col, Card, Accordion, Form, Image } from 'react-bootstrap';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import CustomFunctions from '../../../helper/customFunctions';
import {
  DropdownInput,
  TextInput,
  Helmet,
  DateTimePicker,
} from '../../../components/FormInputs';
import Table from '../../../components/Table/simpletable.component';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import 'react-datepicker/dist/react-datepicker.css';

const statusData = [
  {
    id: 1,
    label: 'Pending',
  },
  {
    id: 2,
    label: 'Incomplete',
  },
  {
    id: 3,
    label: 'In Progress',
  },
  {
    id: 4,
    label: 'Completed',
  },
];

const statusColor = [
  {
    id: 1,
    label: 'Pending',
    classes: 'text-warning',
  },
  {
    id: 2,
    label: 'In Progress',
    classes: 'text-danger',
  },
  {
    id: 3,
    label: 'In Progress',
    classes: 'text-info',
  },
  {
    id: 4,
    label: 'Complete',
    classes: 'text-success',
  },
];

const items = [
  { to: '/', label: 'Home' },
  { to: '/managehomework', label: 'Homework' },
];

function StudentHomeworkStatus(props) {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [selectStatus, setSelectStatus] = useState(null);
  const [homeworkTitle, setHomeworkTitle] = useState(null);
  const [className, setClassName] = useState(null);
  const [subjectName, setSubjectName] = useState(null);
  const [cntClearFilter, setCntClearFilter] = useState(0);
  const [filterErr, setFilterErr] = useState('');
  const [homeworkId, setHomeworkId] = useState('');
  const [title, setTitle] = useState('');
  const [userRole, setUserRole] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 5,
    totalItems: 0,
  });
  let timmer = null;
  let disableButton = false;
  let searchFormSubmit = false;

  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [1, 2, 3],
      isDisplay: false,
    },
    {
      id: 'studentName',
      label: 'Student Name',
      roleAccess: [1, 2, 3],
      render: ({ studentName, isDelay }) => {
        return (
          <>
            <span className="p-r-2 text-primary">
            {isDelay &&
                <Image src="/images/red-flag.gif" />
            }
              {studentName}
            </span>
          </>
        );
      },
    },
    {
      id: 'date',
      label: 'Submission Date',
      roleAccess: [1, 2, 3],
    },
    {
      id: 'status',
      label: 'Status',
      roleAccess: [1, 2, 3],
      render: ({ status }) => {
        return (
          <>
            <span
              className={getStatusColor(status, 'classes')}
            >
              {getStatusColor(status, 'label')}
            </span>
          </>
        );
      },
    },
    {
      id: 'view',
      label: 'View Submission',
      roleAccess: [1, 2, 3],
      render: ({ id }) => {
        return (
          <>
            <a
              href="/"
              className="p-r-2 text-info"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`/studenthomeworkdetails/${id}`);
              }}
            >
              View
            </a>
          </>
        );
      },
    },
  ];

  const getStatusColor = (status, key) => {
    const obj = statusColor.filter((st) => {
      return Number(st.id) === Number(status);
    });
    if (!CustomFunctions.checkIfEmpty(obj, 'A')) {
      return obj[0][key];
    }
    return '';
  };

  useEffect(() => {
    const uRole = CustomFunctions.getLocalStorage('role_id');
    if (!CustomFunctions.checkIfEmpty(uRole)) {
      setUserRole(Number(uRole));
    }
    setTitle('Homework');
    const id = props.match.params.statusId;
    if (id) {
      CustomFunctions.setLocalStorage('homeworkId', id);
      setHomeworkId(id);
      getTableData(id, 1);
      getHomeworkData(id);
    }
  }, []);

  useEffect(() => {
    if (homeworkId) {
      getTableData(homeworkId, 1);
    }
  }, [cntClearFilter]);

  const getTableData = async (id, pageNo = 0) => {
    const page = pageNo || pagination.current_page;

    const endPoint = `${Api.getTutorHomeworkStudentByTutorIdUrl}`;

    const params = {
      homework_id: id,
      keyword,
      page,
      limit: pagination.itemsPerPage,
      status: selectStatus && searchFormSubmit ? selectStatus.id : '',
      start_date:
        fromDate && searchFormSubmit
          ? CustomFunctions.getYearDate(fromDate)
          : '',
      end_date:
        toDate && searchFormSubmit ? CustomFunctions.getYearDate(toDate) : '',
      sort_direction: 'descending',
      current_timezone: `${new Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    };

    const url = CustomFunctions.generateUrl(endPoint, params);

    const { data } = await AuthApi.getDataFromServer(url);

    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      const row = [];
      data.data.map((dt) => {
        row.push({
          id: dt.id,
          studentName: dt.student_name,
          date: CustomFunctions.getDMYDate(dt.end_date),
          status: dt.status,
          isDelay: dt.is_delay
        });
        return dt;
      });
      disableButton = false;
      setRows(row);
    } else {
      disableButton = false;
    }
  };

  const getHomeworkData = async (id) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.getManageHomeworkByUrl}${id}/`
    );
    if (!CustomFunctions.checkIfEmpty(data.data, 'O')) {
      const strDate = new Date(data.data.start_date.toLocaleString());
      const edDate = new Date(data.data.end_date.toLocaleString());
      setHomeworkTitle(data.data.title);
      setSubjectName(data.data.subject_name);
      setClassName(data.data.class_name);
      setStartDate(`${CustomFunctions.getDMYDate(strDate)}`);
      setEndDate(`${CustomFunctions.getDMYDate(edDate)}`);
    }
  };

  const getHomeworkStatus = () => {
    if (!statusData) return [];
    const homeworkOptions = [];
    statusData &&
      statusData.map((st) => {
        return homeworkOptions.push({
          label: st.label,
          id: st.id,
          value: st.id,
        });
      });
    return homeworkOptions;
  };

  const onClear = () => {
    searchFormSubmit = false;
    setFilterErr('');
    setKeyword('');
    setFromDate('');
    setToDate('');
    setSelectStatus(null);
    if (disableButton) return;

    disableButton = true;

    if (timmer) clearTimeout(timmer);
    timmer = setTimeout(() => {
      setCntClearFilter(cntClearFilter + 1);
      disableButton = false;
    }, 1000);
  };

  const filterSearch = (e) => {
    e.preventDefault();
    let filterSearchErr = '';
    let isValid = true;
    if (disableButton) return;

    disableButton = true;

    if (
      CustomFunctions.checkIfEmpty(keyword) &&
      CustomFunctions.checkIfEmpty(selectStatus) &&
      CustomFunctions.checkIfEmpty(fromDate) &&
      CustomFunctions.checkIfEmpty(toDate)
    ) {
      searchFormSubmit = false;
      filterSearchErr = 'Please select atleast one filter';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
    }

    if (!CustomFunctions.checkIfEmpty(fromDate)) {
      if (CustomFunctions.checkIfEmpty(toDate)) {
        filterSearchErr = 'Please select To date';
        setFilterErr(filterSearchErr);
        isValid = false;
        disableButton = false;
      }
    } else if (!CustomFunctions.checkIfEmpty(toDate)) {
      filterSearchErr = 'Please select From date';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
    }

    if (isValid) {
      searchFormSubmit = true;
      setFilterErr('');
      if (timmer) clearTimeout(timmer);

      timmer = setTimeout(() => {
        getTableData(homeworkId, 1);
      }, 1000);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Homework</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="text-primary m-0">Filters</h4>
                  <div className="d-flex align-items-center justify-content-end">
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Expand
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Card.Title>
                    <Form onSubmit={(e) => filterSearch(e)}>
                      {filterErr && (
                        <div className="p-3  bg-danger text-white bg-danger-main table-red-bg">
                          <div className="img-text">
                            <img src="/images/fail.svg" alt="Success" />{' '}
                            {filterErr}
                          </div>
                        </div>
                      )}
                      <Row className="mt-3">
                        <Col sm={4} className="mt-4">
                          <TextInput
                            value={keyword}
                            placeholder="Search keyword"
                            onInputChange={(e) => {
                              setFilterErr('');
                              setKeyword(e.target.value);
                            }}
                          />
                        </Col>
                        <Col sm={4} className="mt-4">
                          <DropdownInput
                            placeholder="Select Status"
                            options={getHomeworkStatus()}
                            defaultValue={selectStatus}
                            onSelectHandler={(select) =>
                              setSelectStatus(select)
                            }
                          />
                        </Col>
                        <Col sm={4}>
                          <DateTimePicker
                            label="From"
                            className="form-control"
                            selected={fromDate}
                            placeholder="DD-MM-YYYY"
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => {
                              setFilterErr('');
                              setFromDate(e);
                            }}
                          />
                        </Col>
                        <Col sm={4}>
                          <DateTimePicker
                            className="form-control"
                            label="To"
                            selected={toDate}
                            placeholder="DD-MM-YYYY"
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => {
                              setFilterErr('');
                              setToDate(e);
                            }}
                            minDate={fromDate}
                          />
                        </Col>
                        <Col sm={6} className="mt-4 col-sm-8">
                          <div className="filter-btn filter-clear-btn separate-submit-clear-btn">
                            <Button variant="primary" type="submit">
                              Submit
                            </Button>
                            <Button
                              variant="primary"
                              type="button"
                              className="ms-4"
                              onClick={() => onClear()}
                            >
                              Clear
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Title>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary mt-2 mb-2">Homework Submission <small className='text-primary h6'><i>(Note : <Image src="/images/red-flag.gif" /> - OVERDUE )</i></small></h4>
                <div className="d-flex align-items-center justify-content-end">
                  <a
                    href=""
                    onClick={() => history.push('/managehomework')}
                    className="btn-link"
                  >
                    Back
                  </a>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <div className="mt-2 mb-2 homework-tutorial-title">
                  <u className="text-primary">Homework title: {homeworkTitle || ''}</u>
                  <div className="homework-badge-gray">
                    <Row>
                      <Col sm={4}>
                        <p>
                          <span>School Year: </span>
                          {className || ''}
                        </p>
                      </Col>
                      <Col sm={4}>
                        <p>
                          <span>Subject:</span> {subjectName || ''}
                        </p>
                      </Col>
                      <Col sm={4}>
                        <p>
                          <span> Date:</span> {`${startDate}-${endDate}` || ''}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
                <Col className="mt-3">
                  <Table
                    role={Number(userRole)}
                    columns={columns}
                    rows={rows}
                    tableClass="table-striped"
                    showPagination
                    paginationProps={{
                      ...pagination,
                      activePage: pagination.current_page,
                      onPageChange: (pageNo) => {
                        getTableData(homeworkId, pageNo);
                      },
                    }}
                    dropdownContainerClass="mb-3"
                    emptyRender={() => (
                      <p className="text-center mb-0">No record found</p>
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
export default withRouter(StudentHomeworkStatus);
