import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { Form } from 'react-bootstrap';

export default function StarRatingsComponent(props) {
  const {
    label,
    onStarClick,
    value,
    name,
    starCount,
    errorMessage,
    containerClass,
    asterisk,
    isEdit = true,
    starColor,
  } = props;

  return (
    <>
      <Form.Group className={containerClass}>
        <Form.Label>
          {label}
          <span style={{ color: 'red' }}>{asterisk}</span>
        </Form.Label>
        <StarRatingComponent
          starCount={starCount}
          onStarClick={(newRating) => {
            if (onStarClick) onStarClick(newRating);
          }}
          value={value}
          name={name}
          starColor={starColor}
          editing={isEdit}
        />
        {Number(starCount) <= 0 && (
          <p style={{ color: 'red' }}> {errorMessage} </p>
        )}
      </Form.Group>
    </>
  );
}
