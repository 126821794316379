import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { TextInput, Helmet } from '../../../components/FormInputs';
import Table from '../../../components/Table/simpletable.component';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import CustomFunctions from '../../../helper/customFunctions';

const items = [
  { to: '/', label: 'Home' },
  { to: '/subjects', label: 'Subjects' },
];

const validator = new SimpleReactValidator();

const Subjects = () => {
  const [subject, setSubject] = useState('');
  const [count, forceUpdate] = useState(0);
  const [rows, setRows] = useState([]);
  const [subjectId, setSubjectId] = useState('');
  const [title, setTitle] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  const [disableButton, setDisableButton] = useState(false);
  let timmer = null;

  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [1, 2],
      isDisplay: false,
    },
    {
      id: 'name',
      label: 'Subject',
      roleAccess: [1, 2],
    },
    {
      id: 'action',
      label: 'Action',
      roleAccess: [1, 2],
      render: ({ id, name }) => {
        return (
          <>
            <a
              href="/"
              className="p-r-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSubjectId(id);
                setSubject(name);
              }}
            >
              <img src="/images/edit-button.svg" alt="Edit Subject" />
            </a>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setTitle('All Subjects');
    getTableData(1);
  }, []);

  const getTableData = async (pageNo = 0) => {
    const cPage = pageNo || pagination.current_page;

    const endPoint = `${Api.subjectUrl}`;

    const params = {
      page: cPage,
      limit: pagination.itemsPerPage,
      sort_direction: 'descending',
    };
    const url = CustomFunctions.generateUrl(endPoint, params);
    const { data } = await AuthApi.getDataFromServer(url);

    if (
      !CustomFunctions.checkIfEmpty(data, 'O') &&
      !CustomFunctions.checkIfEmpty(data.data, 'A')
    ) {
      const row = [];
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      data.data.map((sub) => {
        return row.push({
          id: sub.id,
          name: sub.name,
        });
      });
      setRows(row);
    }
  };

  const addSubject = async () => {
    const postData = {
      name: subject,
    };

    const payload = CustomFunctions.cleanObject(postData);

    const callback = subjectId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = subjectId
      ? `${Api.subjectUrl}${subjectId}/`
      : `${Api.subjectUrl}`;
    const { data, message } = await callback(endPoint, payload);

    if (data) {
      validator.hideMessages();
      swal(data && data.message[0], '', 'success').then((result) => {
        if (result) {
          setDisableButton(false);
          getTableData(1);
          setSubject('');
          setSubjectId('');
        }
      });
    } else {
      setDisableButton(false);
      swal(message, '', 'error');
      getTableData(1);
    }
  };

  const onCancel = () => {
    validator.hideMessages();
    setSubject('');
    setSubjectId('');
    if (disableButton) return;

    setDisableButton(true);

    if (timmer) clearTimeout(timmer);
    timmer = setTimeout(() => {
      getTableData(1);
      setDisableButton(false);
    }, 1000);
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (disableButton) return;

    setDisableButton(true);
    if (validator.allValid()) {
      addSubject();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Subjects</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary mt-2 mb-2">All Subjects</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3">
                <Col>
                  <Form autoComplete="off" onSubmit={(e) => submitForm(e)}>
                    <Form.Label className="required-field">Title </Form.Label>
                    <TextInput
                      onInputChange={(e) => setSubject(e.target.value)}
                      name="subject"
                      containerClass="form-group"
                      value={subject}
                      placeholder="Enter your Subject"
                      errorMessage={validator.message(
                        'Subject',
                        subject,
                        'required|regex:^(?=.*)(?=.*[A-Za-z ])[A-Za-z0-9 ]*$',
                        {
                          messages: {
                            required: 'The Subject field is required',
                            regex:
                              'Subject should be either string or alphanumeric.',
                          },
                        }
                      )}
                    />
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit" className="ms-4">
                      {subjectId ? 'Update' : 'Add'}
                    </Button>
                  </Form>
                </Col>
                <Col>
                  <Table
                    columns={columns}
                    rows={rows}
                    showPagination="true"
                    paginationProps={{
                      ...pagination,
                      activePage: pagination.current_page,
                      onPageChange: (pageNo) => {
                        getTableData(pageNo);
                      },
                    }}
                    tableClass="table-striped"
                    dropdownContainerClass="mb-3"
                    emptyRender={() => (
                      <p className="text-center mb-0">No record found</p>
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default withRouter(Subjects);
