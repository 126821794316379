import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Card, Form, Col, Row, Button } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import generatepassword from 'generate-password';
import swal from 'sweetalert';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import {
  TextInput,
  RadioInput,
  DropdownInput,
  PhoneInput,
  Helmet,
} from '../../../components/FormInputs';
import CustomFunctions from '../../../helper/customFunctions';

const additionalChoices = [
  { value: 1, name: 'Ongoing classes' },
  { value: 2, name: '11 Plus Mock Exams' },
  {
    value: 3,
    name: 'Summer camp ( An introduction to the 11 Plus programme for Year 4 and 5)',
  },
  { value: 4, name: 'Easter Break (Year 11 Exam Paper practice)"' },
];

const examFocusList = [
  { value: '11 Plus', name: '11 Plus' },
  { value: '13 Plus', name: '13 Plus' },
  { value: 'GCSE', name: 'GCSE' },
  { value: 'IGCSE', name: 'IGCSE' },
];

const allergiesList = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const learningDisabilitiesList = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const knowPPLTreeLearning = [
  { value: 'Google', name: 'Google' },
  { value: 'Facebook', name: 'Facebook' },
  { value: 'Leaflet', name: 'Leaflet' },
  { value: 'Library', name: 'Library' },
  { value: 'Bus Panels', name: 'Bus Panels' },
  { value: 'Friends/Family', name: 'Friends/Family' },
  { value: 'E-Leaflet', name: 'E-Leaflet' },
  { value: 'Primary Times', name: 'Primary Times' },
  { value: 'Other', name: 'Other' },
];

const validator = new SimpleReactValidator();

const PersonalDetails = (props) => {
  const history = useHistory();
  const [count, forceUpdate] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [address, setAddress] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [guardianName, setGuardianName] = useState('');
  const [guardianNo, setGuardianContactNo] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [examFocus, setExamFocus] = useState(null);
  const [allergies, setAllergies] = useState('');
  const [learningDisabilities, setLearningDisabilities] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [gcseIcse, setGcseIcse] = useState('');
  const [pplTreeLearning, setPPLTreeLearningValue] = useState('');
  const [yearList, setYearList] = useState([]);
  const [selectOptionYear, setSelectOptionYear] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [guardianEmail, setGuardianEmail] = useState('');
  const [subjectTopics, setSubjectTopics] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [showDisabilityText, setShowDisabilityText] = useState(false);
  const [showAllergiesText, setShowAllergiesText] = useState(false);
  const [disabilityText, setDisabilityText] = useState('');
  const [allergiesText, setAllergiesText] = useState('');
  const [showOtherPTLText, setShowOtherPTLText] = useState(false);
  const [otherPTLText, setOtherPTLText] = useState('');
  const [title, setTitle] = useState('');
  const [userName, setUserName] = useState('');
  const [termsConditions, setTermsConditions] = useState(false);

  useEffect(() => {
    async function getData() {
      const stuId = props.match.params.id;
      setTitle('Add Student');
      if (stuId) {
        setTitle('Edit Student');
        setStudentId(stuId);
        getStudentDetails(stuId);
      }
      getYearList();
      getExamFocusList();
    }
    getData();
  }, []);

  const getStudentDetails = async (id) => {
    const endPoint = `${Api.studentUrl}${id}/`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (!CustomFunctions.checkIfEmpty(data.data, 'O')) {
      const ptlLearnArray = [];
      const examFocusArray = [];

      setLastName(data.data.last_name);
      setFirstName(data.data.first_name);
      setEmailAddress(data.data.email);
      setContactNo(data.data.mobile);
      setGuardianName(data.data.guardian_name);
      setGuardianContactNo(data.data.guardian_contact);
      setAddress(data.data.address);
      setSchoolName(data.data.school_name);
      setSelectOptionYear({
        label: data.data.class_name,
        id: data.data.class,
        value: data.data.class,
      });
      setGcseIcse(data.data.sub_attention);
      if (data.data.exam_focus && data.data.exam_focus.length > 0) {
        data.data.exam_focus &&
          data.data.exam_focus.map((focus) => {
            return examFocusArray.push({
              label: focus,
              id: focus,
              value: focus,
            });
          });
        setExamFocus(examFocusArray);
      }

      if (data.data.additional_details) {
        const result = additionalChoices.find(
          (ad) => ad.value === data.data.additional_details
        );
        setAdditionalDetails({
          label: result ? result.name : '',
          id: data.data.additional_details,
          value: data.data.additional_details,
        });
      }
      if (
        data.data.peepal_tree_learn &&
        data.data.peepal_tree_learn.length > 0
      ) {
        data.data.peepal_tree_learn &&
          data.data.peepal_tree_learn.map((hear) => {
            return ptlLearnArray.push({
              label: hear,
              id: hear,
              value: hear,
            });
          });
        setPPLTreeLearningValue(ptlLearnArray);
      }
      setAllergies(`${data.data.allergies}`);
      setLearningDisabilities(`${data.data.learning_disable}`);
      if (data.data.learning_disable === true) {
        setShowDisabilityText('true');
        setDisabilityText(`${data.data.reg_known_disabilities}`);
      }
      if (data.data.allergies === true) {
        setShowAllergiesText('true');
        setAllergiesText(`${data.data.reg_known_allergies}`);
      }
      setGuardianEmail(data.data.guardian_email);
      setSubjectTopics(data.data.subject_topics);
      if (data.data.peepal_tree_learn_other) {
        setShowOtherPTLText(true);
        setOtherPTLText(`${data.data.peepal_tree_learn_other}`);
      }
      setUserName(data.data.username);
    }
  };

  const addStudent = async () => {
    const password = generatepassword.generate({
      length: 10,
      numbers: true,
    });

    const selectPplTreeLearn = [];
    pplTreeLearning &&
      pplTreeLearning.map((op) => {
        return selectPplTreeLearn.push(op.id);
      });

    const selectExamFocus = [];
    examFocus &&
      examFocus.map((op) => {
        return selectExamFocus.push(op.id);
      });

    const postData = {
      first_name: firstName,
      last_name: lastName,
      address,
      mobile: contactNo,
      email: emailAddress,
      g_name: guardianName,
      g_contact: guardianNo,
      learning_disable: learningDisabilities,
      allergies,
      peepal_tree_learn: selectPplTreeLearn,
      school_name: schoolName,
      classes: selectOptionYear.id,
      exam_focus: selectExamFocus,
      additional_details: additionalDetails ? additionalDetails.id : '',
      sub_attention: gcseIcse,
      g_email: guardianEmail,
      subject_topics: subjectTopics,
      username: userName,
      terms_conditions:termsConditions
    };

    if (learningDisabilities) postData.reg_known_disabilities = disabilityText;
    if (allergies) postData.reg_known_allergies = allergiesText;
    if (showOtherPTLText) postData.peepal_tree_learn_other = otherPTLText;

    if (!studentId) postData.password = password;

    const payload = CustomFunctions.cleanObject(postData);
    const callback = AuthApi.postDataToServer;
    const endPoint = `${Api.studentRegistrationUrl}`;

    const { data, message } = await callback(endPoint, payload);

    if (data) {
        swal('Thank you for register an account. Check your email and verify your account.', '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        setDisableButton(false);
        if (result) {
          history.push('/login');
        }
      });
    } else {
      setDisableButton(false);
      swal(message, '', 'error');
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    let termsFlag = false;
    if (!termsConditions) {
      termsFlag = true;
      swal('Please accept terms and conditions.', '', 'error');
    }else{
      termsFlag = false;
    }

    if (validator.allValid() && !termsFlag) {
      addStudent();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const getYearList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.schoolYearUrl}?status=1&type=all`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setYearList(data.data);
    }
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList.map((mat) => {
      return yearOptions.push({
        label: mat.name,
        id: mat.id,
        value: mat.id,
      });
    });
    return yearOptions;
  };

  const getExamFocusList = () => {
    if (!examFocusList) return [];
    const examFocusListOptions = [];
    examFocusList.map((alg) => {
      return examFocusListOptions.push({
        label: alg.name,
        id: alg.value,
        value: alg.value,
      });
    });
    return examFocusListOptions;
  };

  const getAdditionalChoices = () => {
    if (!additionalChoices) return [];
    const choicesOptions = [];
    additionalChoices.map((tech) => {
      return choicesOptions.push({
        label: tech.name,
        id: tech.value,
        value: tech.value,
      });
    });
    return choicesOptions;
  };

  const getKnowPPLTreeLearning = () => {
    if (!knowPPLTreeLearning) return [];
    const pplTreeLearningOptions = [];
    knowPPLTreeLearning.map((learn) => {
      return pplTreeLearningOptions.push({
        label: learn.name,
        id: learn.value,
        value: learn.value,
      });
    });
    return pplTreeLearningOptions;
  };

  const onCancel = async () => {
    validator.hideMessages();
    await history.push('/login');
  };

  const getAllergies = () => {
    if (!allergiesList) return [];
    const allergiesOptions = [];
    allergiesList.map((alg) => {
      return allergiesOptions.push({
        label: alg.label,
        value: alg.value,
      });
    });
    return allergiesOptions;
  };

  const getLearningDisabilities = () => {
    if (!learningDisabilitiesList) return [];
    const learningDisabilitiesOptions = [];
    learningDisabilitiesList.map((alg) => {
      return learningDisabilitiesOptions.push({
        label: alg.label,
        value: alg.value,
      });
    });
    return learningDisabilitiesOptions;
  };

  const addOtherPPLText = (selectOption) => {
    const otherOption = selectOption.find((item) => item.value === 'Other');

    if (!CustomFunctions.checkIfEmpty(otherOption)) {
      setShowOtherPTLText(true);
    } else {
      setShowOtherPTLText(false);
    }
  };

  const setLearningDisabilitiesFlag = (learnflag) => {
    if(learnflag === 'true'){
      setShowDisabilityText('true');
    }else{
      setShowDisabilityText('false');
    }
  };

  // const scrollTermsConditions = () => {
  //   const elmnt = document.getElementById('termsCondition');
  //   const a = elmnt.scrollTop;
  //   const b = elmnt.scrollHeight - elmnt.clientHeight;
  //   const c = a / b;

  //   if(c === 1){
  //     document.getElementById ('register').disabled  = false;
  //   }else{
  //     document.getElementById ('register').disabled  = true;
  //   }
  // };

  validator.purgeFields();

  return (
    <>
      <Helmet title={title} />
      <div className="login-page student-reg-form">
        <div className="page-header">
          <div className="title-breadcrumb-section">
          <div className="logo text-center">
            <a href="#">
              {' '}
              <img src="/images/PTL_Logo.png" alt="img" />
            </a>
          </div>
          </div>
        </div>
      <section className="content-area align-items-center">
        <div>
          <Card>
            <Card.Body>
              <Form autoComplete="off" onSubmit={(e) => submitForm(e)}>
              <h4 className="text-center text-danger font-weight-semibold">Student Registration</h4>
              <hr/>
                <Row>
                  <h5 className="mb-3">Personal Details</h5>
                  <hr />
                  <Col sm={3}>
                    <TextInput
                      label="First Name"
                      name="firstName"
                      containerClass="form-group"
                      placeholder="First Name"
                      value={firstName || ''}
                      onInputChange={(e) => setFirstName(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'firstName',
                        firstName,
                        'required|alpha_space'
                      )}
                    />
                  </Col>

                  <Col sm={3}>
                    <TextInput
                      label="Last Name"
                      name="lastName"
                      containerClass="form-group"
                      placeholder="Last Name"
                      value={lastName || ''}
                      onInputChange={(e) => setLastName(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'lastName',
                        lastName,
                        'required|alpha_space'
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <TextInput
                      label="Email Address"
                      name="emailAddress"
                      containerClass="form-group"
                      placeholder="Email Address"
                      value={emailAddress || ''}
                      onInputChange={(e) => setEmailAddress(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'emailAddress',
                        emailAddress,
                        'required|email'
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                 <Col sm={3}>
                    <TextInput
                      label="Username"
                      name="userName"
                      containerClass="form-group"
                      placeholder="Username"
                      value={userName || ''}
                      onInputChange={(e) => setUserName(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'userName',
                        userName,
                        'required|regex:^(?=.*)(?=.*[A-Za-z ])[A-Za-z0-9 ]*$',
                        {
                          messages: {
                            required: 'The Username field is required',
                            regex:
                            'Username should be either alphabet or alphanumeric.',
                          },
                        }
                      )}
                      disabled={studentId ? 'disabled' : ''}
                      />

                  </Col>
                  <Col sm={3}>
                    <div className="contact-number">
                      <PhoneInput
                        label="Contact No"
                        name="contactNo"
                        asterisk="*"
                        country="gb"
                        onlyCountries={['in', 'gb']}
                        placeholder="Contact No"
                        value={contactNo || ''}
                        onInputChange={(e) => setContactNo(e)}
                        errorMessage={validator.message(
                          'contactNo',
                          contactNo,
                          'required|phone|min:12'
                        )}
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <TextInput
                      label="Address"
                      name="address"
                      containerClass="form-group"
                      placeholder="Address"
                      value={address || ''}
                      Row={5}
                      as="textarea"
                      onInputChange={(e) => setAddress(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'address',
                        address,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      label="Parent/Guardian Name"
                      name="guardianName"
                      containerClass="form-group"
                      placeholder="Parent/Guardian Name"
                      value={guardianName || ''}
                      onInputChange={(e) => setGuardianName(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'guardianName',
                        guardianName,
                        'required|alpha_space'
                      )}
                    />
                  </Col>
                  <Col>
                    <div className="contact-number">
                      <PhoneInput
                        label="Guardian Contact No"
                        name="guardianNo"
                        asterisk="*"
                        country="gb"
                        onlyCountries={['in', 'gb']}
                        placeholder="Parent/Guardian Contact No"
                        value={guardianNo || ''}
                        onInputChange={(e) => setGuardianContactNo(e)}
                        errorMessage={validator.message(
                          'guardianNo',
                          guardianNo,
                          'required|phone|min:12'
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    <TextInput
                      label="Parent/Guardian Email"
                      name="guardianEmail"
                      containerClass="form-group"
                      placeholder="Parent/Guardian Email"
                      value={guardianEmail || ''}
                      asterisk="*"
                      onInputChange={(e) => setGuardianEmail(e.target.value)}
                      errorMessage={validator.message(
                        'guardianEmail',
                        guardianEmail,
                        'required|email'
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <h5>Academic Details</h5>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <TextInput
                      label="Name of School"
                      name="schoolName"
                      placeholder="Name of School"
                      asterisk="*"
                      value={schoolName || ''}
                      onInputChange={(e) => setSchoolName(e.target.value)}
                      errorMessage={validator.message(
                        'schoolName',
                        schoolName,
                        'required'
                      )}
                    />
                  </Col>

                  <Col sm={6}>
                    <DropdownInput
                      label="School Year"
                      options={getYearData() || []}
                      placeholder="Select School Year"
                      asterisk="*"
                      defaultValue={selectOptionYear || ''}
                      onSelectHandler={(select) => setSelectOptionYear(select)}
                      errorMessage={validator.message(
                        'School Year',
                        selectOptionYear,
                        'required'
                      )}
                      disabled={studentId ? 'disabled' : ''}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <TextInput
                      label="Subject topics that requires further attention"
                      name="subjectTopics"
                      containerClass="form-group"
                      placeholder=""
                      value={subjectTopics || ''}
                      Row={5}
                      as="textarea"
                      onInputChange={(e) => setSubjectTopics(e.target.value)}
                    />
                  </Col>
                  <Col sm={6}>
                    <DropdownInput
                      label="Additional Details Required For"
                      options={getAdditionalChoices() || []}
                      name="additionalDetails"
                      asterisk="*"
                      placeholder="Select..."
                      defaultValue={additionalDetails || ''}
                      onSelectHandler={(select) => setAdditionalDetails(select)}
                      errorMessage={validator.message(
                        'Additional Details',
                        additionalDetails,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <DropdownInput
                      label="Exam Focus"
                      options={getExamFocusList() || []}
                      placeholder="Select ..."
                      defaultValue={examFocus || ''}
                      asterisk="*"
                      isMulti
                      onSelectHandler={(select) => setExamFocus(select)}
                      errorMessage={validator.message(
                        'Exam Focus',
                        examFocus,
                        'required'
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>
                      GCSE/IGCSE Subject Board(if applicable)
                    </Form.Label>
                    <TextInput
                      name="gcseIcse"
                      containerClass="form-group"
                      placeholder=""
                      value={gcseIcse || ''}
                      onInputChange={(e) => setGcseIcse(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h5>Other Details</h5>
                    <hr />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Label>Any Known Learning Disabilities</Form.Label>
                    <span style={{ color: 'red' }}>*</span>
                    <div>
                      <RadioInput
                        onSelectRadioButton={(e) => {
                           setLearningDisabilities(e.target.value);
                           setLearningDisabilitiesFlag(e.target.value);
                        }}
                        name="learningDisabilities"
                        type="radio"
                        value={learningDisabilities || ''}
                        checked={learningDisabilities}
                        options={getLearningDisabilities() || []}
                        inputClass="form-check-inline"
                        asterisk="*"
                        errorMessage={validator.message(
                          'Learning disability',
                          learningDisabilities,
                          'required'
                        )}
                      />
                      {showDisabilityText === 'true' && (
                        <TextInput
                          name="disabilityText"
                          containerClass="form-group"
                          placeholder=""
                          value={disabilityText}
                          onInputChange={(e) =>
                            setDisabilityText(e.target.value)
                          }
                          errorMessage={
                            showDisabilityText === 'true'
                              ? validator.message(
                                  'disabilityText',
                                  disabilityText,
                                  'required',
                                  {
                                    messages: {
                                      required:
                                        'Known learn disability is required',
                                    },
                                  }
                                )
                              : ''
                          }
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Form.Label>
                      Any Known Allergies/ Current Medication{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <div>
                      <RadioInput
                        onSelectRadioButton={(e) => {
                          setAllergies(e.target.value);
                          setShowAllergiesText(e.target.value);
                        }}
                        name="Allergies"
                        type="radio"
                        value={allergies || ''}
                        checked={allergies}
                        options={getAllergies()}
                        inputClass="form-check-inline"
                        errorMessage={validator.message(
                          'allergies',
                          allergies,
                          'required'
                        )}
                      />
                      {showAllergiesText === 'true' && (
                        <TextInput
                          name="allergiesText"
                          containerClass="form-group"
                          placeholder=""
                          value={allergiesText}
                          onInputChange={(e) =>
                            setAllergiesText(e.target.value)
                          }
                          errorMessage={
                            showAllergiesText.toString() === 'true'
                              ? validator.message(
                                  'allergiesText',
                                  allergiesText,
                                  'required',
                                  {
                                    messages: {
                                      required:
                                        'Known allergy/current medication is required',
                                    },
                                  }
                                )
                              : ''
                          }
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <div>
                      <DropdownInput
                        label="Where did Student hear of Peepal Tree Learning?"
                        options={getKnowPPLTreeLearning() || []}
                        placeholder="Select ..."
                        defaultValue={pplTreeLearning || ''}
                        onSelectHandler={(select) => {
                          addOtherPPLText(select);
                          setPPLTreeLearningValue(select);
                        }}
                        asterisk="*"
                        isMulti
                        errorMessage={validator.message(
                          'Where did Student hear of Peepal Tree Learning',
                          pplTreeLearning,
                          'required'
                        )}
                      />
                      {showOtherPTLText === true && (
                        <TextInput
                          name="otherPTLText"
                          containerClass="form-group"
                          placeholder=""
                          value={otherPTLText}
                          onInputChange={(e) => setOtherPTLText(e.target.value)}
                          errorMessage={
                            showOtherPTLText
                              ? validator.message(
                                  'otherPTLText',
                                  otherPTLText,
                                  'required',
                                  {
                                    messages: {
                                      required: 'This field is required',
                                    },
                                  }
                                )
                              : ''
                          }
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                    <Form.Label>
                      Terms & Conditions{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                  <Col>
                    {/* onScroll={()=> scrollTermsConditions()} */}
                    <div id='termsCondition' style={{'border': '1px solid black','width':'100%','height': '200px','overflow': 'scroll'}}>
                    <ol>
                        <li>The safety of the students&nbsp;&nbsp;is the responsibility of the Company whilst on their premises however Parents must make their own provision as to drop off and pick up from the tuition centre.</li>
                        <li>It is the responsibility of the Parent(s) to pick up and drop off in a timely manner.</li>
                        <li>It is not permitted for the students&nbsp;to leave the building during the hours allocated to their class except in case of emergency.</li>
                        <li>Where Online learning is being used all parents and students must abide by the Remote Learning Responsible User agreement and Guidelines. When using the Peepal Tree Learning platform to attend classes online, students should conduct themselves as they would in a classroom. This includes:
                        <ul>
                        <li>Video conferencing from an environment that is quiet, safe and free from distractions (not a bedroom)</li>
                        <li>Be on time for the interactive session</li>
                        <li>Be dressed appropriately for learning (no pyjamas, no swimwear)</li>
                        <li>Remain attentive during sessions</li>
                        <li>Interact patiently and respectfully with your teachers and peers</li>
                        <li>Provide feedback to teachers about your experiences and any relevant suggestions</li>
                        <li>You MUST NOT record each other’s online interactions, including the teacher. Recording a lesson and placing it on social media will lead to a severe sanction.</li>
                        <li>Make sure you end the session as soon as the teacher indicates to do so.</li>
                        </ul>
                        </li>
                        <li>All online classes delivered are recorded and accessible by Peepal Tree learning Ltd. alone for the safety of it’s staff and students.</li>
                        <li>The Company is not responsible for the welfare of the students&nbsp;&nbsp;outside of class hours.</li>
                        <li>If the student wishes to withdraw from the classes a one month notice must be provided in writing to the company.</li>
                        <li>The Parent(s) is/are responsible for telling the Company if the child has any learning disability, medical condition or requires medication.</li>
                        <li>No warranties are made with regard to progress if any made by the students&nbsp;during the course of the classes.</li>
                        <li>The Company promotes good behaviour at their learning centres and reserves the right to exclude a child from classes should they cause disruption to the learning sessions. In the event a child causes serious misconduct or is a danger to the staff or any other child that student will be removed from the class with immediate effect.</li>
                        <li>The Company is not responsible for exam results or coursework marks.</li>
                        <li>By providing the Company with the Parent(s) personal details and information the Parent(s) agree that they are happy for the Company to store them on the Company database. The Company in return warrants that the information will not be shared with any third party without the Parent’s consent.</li>
                        <li>The Parent(s) agree that their correspondence details may be used to send information relating to the Company’s services and invitation to join the social media platform and communication networks. If the Parent(s) do not wish to receive such information then this must be done at the time of registration.</li>
                        <li>Appointments for discussing child progression with tutors must be arranged by emailing the Company at <a href="mailto:info@peepaltreelearning.com">info@peepaltreelearning.com</a>. The Company will be happy to book a 10&nbsp;minute appointment with the tutor either before or after class.</li>
                        <li>Parent(s) are encouraged not to share any personal details with the tutors who are all independent contractors and not employees of the Company. They are also under contractual obligation not to provide any private lessons with any of the Company’s students.</li>
                        <li>Payments must be made for the full term in advance of the student starting tuition. Payment is taken once the registration form is complete and can also be made via bank transfer to the following account: Peepal Tree Learning<br/>
                        Sort Code: 40-26-12<br/>
                        Account No: 12206692</li>
                        <li>Payment is also accepted by cheque or the Company’s payment system on their website.. A fee of £25.00 will be applied for any transfer/cheque that fails to negotiate to the Company’s bank.</li>
                        <li>Students will only be allowed to attend class once the full payment has been made.</li>
                        <li>Refunds will only be issued if the lesson does not take place as a result of the Company cancelling. The refund will be made within 7 working days. There will be no refund if the Parent(s) withdraws the student from the classes or if the student&nbsp;does not attend.</li>
                        <li>No guarantee of an available place will be given until the term payment has been made. If classes are fully booked students will be placed on a waiting list if desired by the Parent(s).</li>
                        </ol>
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                  <label>
                    <input
                      onChange={(e) => setTermsConditions(e.target.checked)}
                      name="termsConditions"
                      type="checkbox"
                      id="termsConditions"
                    />
                    <span className="ms-2">I’ve read and accepted the terms & conditions <span style={{color:'red'}}>*</span></span>
                  </label>
                  </Col>
                </Row>
                <div className="float-end">
                  <Button variant="secondary" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                  <Button
                    // id="register"
                    variant="primary"
                    type="submit"
                    className="ms-4"
                    disabled={disableButton}
                  >
                    Register
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </section>
      </div>
    </>
  );
};

export default withRouter(PersonalDetails);
