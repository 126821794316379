import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, Row, Col, Card, Image } from 'react-bootstrap';
import swal from 'sweetalert';
import CustomFunctions from '../../../helper/customFunctions';
import { TextInput, Helmet, PhoneInput } from '../../../components/FormInputs';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import UserRestrictions from '../../../components/UserRestrictions/userrestrictions.component';

const items = [
  { to: '/', label: 'Home' },
  { to: '/profile', label: 'Profile' },
];

const additionalChoices = [
  { value: 1, name: 'Ongoing classes' },
  { value: 2, name: '11 Plus Mock Exams' },
  {
    value: 3,
    name: 'Summer camp ( An introduction to the 11 Plus programme for Year 4 and 5)',
  },
  { value: 4, name: 'Easter Break (Year 11 Exam Paper practice)"' },
];

function Profile() {
  const history = useHistory();
  const initialState = {
    email: '',
    mobile: '',
    username: '',
    subjectName: '',
    className: '',
  };
  const [userData, setUserData] = useState(initialState);
  const [userRole, setUserRole] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [address, setAddress] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [guardianName, setGuardianName] = useState('');
  const [guardianNo, setGuardianContactNo] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [year, setYear] = useState(null);
  const [examFocus, setExamFocus] = useState(null);
  const [allergies, setAllergies] = useState();
  const [learningDisabilities, setLearningDisabilities] = useState();
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [gcseIcse, setGcseIcse] = useState('');
  const [pplTreeLearning, setPPLTreeLearningValue] = useState();
  const [activationDate, setActivateDate] = useState('');
  const [guardianEmail, setGuardianEmail] = useState('');
  const [title, setTitle] = useState('');
  const [subjectTopics, setSubjectTopics] = useState('');
  const [showDisabilityText, setShowDisabilityText] = useState(false);
  const [showAllergiesText, setShowAllergiesText] = useState(false);
  const [disabilityText, setDisabilityText] = useState('');
  const [allergiesText, setAllergiesText] = useState('');
  const [showOtherPTLText, setShowOtherPTLText] = useState(false);
  const [otherPTLText, setOtherPTLText] = useState('');
  const [userName, setUserName] = useState('');
  const [tutorSubjectList, setTutorSubjectList] = useState(''); //eslint-disable-line
  const [studentId, setStudentId] = useState(null);

  useEffect(() => {
    const uRole = CustomFunctions.getLocalStorage('role_id');
    if (!CustomFunctions.checkIfEmpty(uRole)) {
      setUserRole(Number(uRole));
    }
    const userid = CustomFunctions.getLocalStorage('id');
    if (!CustomFunctions.checkIfEmpty(userid)) {
      setStudentId(Number(userid));
    }
    setTitle('My Profile');
    getProfile(uRole);
  }, []);

  const getProfile = async (userrole) => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.getProfileByIdUrl}`
    );

    if (!CustomFunctions.checkIfEmpty(data.data, 'O')) {
      if (Number(userrole) === 4) {
        await getStudentSubjectTutor(data.data.class);
        setFirstName(data.data.first_name);
        setLastName(data.data.last_name);
        setEmailAddress(data.data.email);
        setContactNo(data.data.mobile);
        setGuardianName(data.data.g_name);
        setGuardianContactNo(data.data.g_contact);
        setAddress(data.data.address);
        setSchoolName(data.data.school_name);
        setYear(data.data.class_name);
        setGcseIcse(data.data.sub_attention);
        if (data.data.exam_focus) {
          setExamFocus(data.data.exam_focus);
        }
        if (data.data.additional_details) {
          const result = additionalChoices.find((ad) => {
            return ad.value === data.data.additional_details;
          });
          setAdditionalDetails(result.name);
        }
        if (data.data.peepal_tree_learn) {
          setPPLTreeLearningValue(data.data.peepal_tree_learn);
        }
        setAllergies(data.data.allergies);
        setLearningDisabilities(data.data.learning_disable);
        setActivateDate(
          data.data.activation_date
            ? CustomFunctions.getDate(data.data.activation_date)
            : ''
        );
        setGuardianEmail(data.data.g_email);
        setSubjectTopics(data.data.subject_topics);

        if (data.data.peepal_tree_learn) {
          setPPLTreeLearningValue(data.data.peepal_tree_learn);
        }
        if (data.data.learning_disable === true) {
          setShowDisabilityText('true');
          setDisabilityText(`${data.data.reg_known_disabilities}`);
        }
        if (data.data.allergies === true) {
          setShowAllergiesText('true');
          setAllergiesText(`${data.data.reg_known_allergies}`);
        }
        if (data.data.peepal_tree_learn_other) {
          setShowOtherPTLText(true);
          setOtherPTLText(`${data.data.peepal_tree_learn_other}`);
        }
        setUserName(data.data.username);
      } else {
        setUserData({
          email: data.data.email,
          mobile: data.data.mobile,
          subjectName: data.data.class_subjects
            ? Object.values(data.data.class_subjects)
            : '',
          className: data.data.class_subjects
            ? Object.keys(data.data.class_subjects)
            : '',
          fullname : `${data.data.first_name}`,
          username : Number(userrole) === 5 ? `${data.data.username}` : `${data.data.first_name} ${data.data.last_name}`,
        });
      }
    } else {
      swal(message, '', 'error').then((result) => {
        if (result) {
          history.push('/');
        }
      });
    }
  };

  const getStudentSubjectTutor = async (classid) => {
    const tutorSubjectListOptions = [];
    return new Promise(async (resolve) => {
      const { data } = await AuthApi.getDataFromServer(
        `${Api.studentTutorSubjectUrl}?id=${studentId}&class_id=${classid}`
      );

      if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
          data.data.map((stud) => {
            return tutorSubjectListOptions.push({
              subject_name: stud.subject_name,
              tutor_name: stud.tutor_full_name,
            });
          });
          setTutorSubjectList(tutorSubjectListOptions);
          resolve();
      } else {
          setTutorSubjectList('');
          resolve([]);
      }
    });
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">My Profile</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Card>
        <Card.Header className="card-header d-flex align-items-center justify-content-between">
          <h4 className="text-primary mt-2 mb-2">My Profile</h4>
          <div className="d-flex align-items-center justify-content-end">
          {userRole === 4 && (
            <>
               <a
                href=""
                onClick={() => history.push(`/student/studentAcademicDetails/${studentId}`)}
                className="btn-link"
               >
                View Academic History
              </a>
            </>
          )}
            &nbsp;&nbsp;&nbsp;
            <a
              href=""
              onClick={() => history.push('/profile/edit')}
              className="btn-link"
            >
              Edit Profile
            </a>
          </div>
        </Card.Header>
        <UserRestrictions permittedUsers={[1, 2, 3]} roleId={userRole}>
          <Card.Body>
            <Form>
              <Row>
                <h2 className="profile-title">{userData.username || ''}</h2>
                <Form.Label className="mt-4">
                  <h5>Personal Information</h5>
                </Form.Label>
                <Col md={6}>
                  <Form.Label>
                    <Image src="images/mail.svg" className="svg me-3" />
                    {userData.email || ''}
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Label>
                    <Image src="images/Mobile-num.svg" className="svg me-3" />+
                    {userData.mobile || ''}
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Form.Label className="mt-4">
                  <h5>Work Information</h5>
                </Form.Label>
                <Col sm={6}>
                  <Form.Label>
                    {userRole === 3 ? 'School Year' : 'Role'}
                  </Form.Label>
                  <TextInput
                    inputClass="form-control"
                    value={userRole === 3 ? userData.className : 'Management'}
                    disabled
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>
                    {userRole === 3 ? 'Subject' : 'Responsibilities'}
                  </Form.Label>
                  <TextInput
                    inputClass="form-control"
                    value={
                      userRole === 3 ? userData.subjectName : 'Manage Portal'
                    }
                    disabled
                  />
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </UserRestrictions>
        <UserRestrictions permittedUsers={[5]} roleId={userRole}>
          <Card.Body>
            <Form>
              <Row>
                <h2 className="profile-title">{userData.fullname || ''}</h2>
                <Form.Label className="mt-4">
                  <h5>Personal Information</h5>
                </Form.Label>
                <Col md={6}>
                  <Form.Label>
                    <Image src="images/mail.svg" className="svg me-3" />
                    {userData.email || ''}
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Label>
                    <Image src="images/Mobile-num.svg" className="svg me-3" />+
                    {userData.mobile || ''}
                  </Form.Label>
                </Col>
              </Row>

            </Form>
          </Card.Body>
        </UserRestrictions>
        <UserRestrictions permittedUsers={[4]} roleId={userRole}>
          <Card.Body>
            <Form>
              <Row>
                <Col>
                  <h2 className="profile-title">
                    {firstName || ''} {lastName || ''}
                  </h2>
                  <div className="d-flex align-items-center justify-content-between mt-4">
                    <h5 className="mb-0">Personal Details</h5>
                    {activationDate !== '' && (
                      <>
                        <div className="p-r-2 font-weight-semibold float-end">
                          Activation Date : {activationDate || ''}
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Form.Label className="required-field">First Name</Form.Label>
                  <TextInput
                    name="firstName"
                    containerClass="form-group"
                    placeholder="First Name"
                    value={firstName || ''}
                    disabled
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label className="required-field">Last Name</Form.Label>
                  <TextInput
                    name="lastName"
                    containerClass="form-group"
                    value={lastName || ''}
                    disabled
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label className="required-field">
                    Email Address
                  </Form.Label>
                  <TextInput
                    name="emailAddress"
                    containerClass="form-group"
                    value={emailAddress || ''}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
              <Col sm={3}>
                <TextInput
                    label="Username"
                    name="userName"
                    containerClass="form-group"
                    placeholder="Username"
                    value={userName || ''}
                    disabled="true"
                />
                </Col>
                <Col sm={3}>
                  <div className="contact-number">
                    <PhoneInput
                      label="Contact No"
                      name="contactNo"
                      country="gb"
                      onlyCountries={['in', 'gb']}
                      placeholder="Contact No"
                      value={contactNo || ''}
                      disabled="true"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <Form.Label className="required-field">Address</Form.Label>
                  <TextInput
                    name="address"
                    containerClass="form-group"
                    value={address || ''}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="required-field">
                    Parent/Guardian Name
                  </Form.Label>
                  <TextInput
                    name="guardianName"
                    containerClass="form-group"
                    value={guardianName || ''}
                    disabled
                  />
                </Col>
                <Col>
                  <div className="contact-number">
                    <PhoneInput
                      label="Guardian Contact No"
                      name="guardianNo"
                      country="gb"
                      onlyCountries={['in', 'gb']}
                      placeholder="Parent/Guardian Contact No"
                      value={guardianNo || ''}
                      disabled="true"
                    />
                  </div>
                </Col>
                <Col>
                  <Form.Label className="required-field">
                    Parent/Guardian Email.
                  </Form.Label>
                  <TextInput
                    name="guardianEmail"
                    containerClass="form-group"
                    placeholder="Parent/Guardian Email"
                    value={guardianEmail || ''}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h5>Academic Details</h5>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Label className="required-field">
                    Name of School
                  </Form.Label>
                  <TextInput
                    name="schoolName"
                    value={schoolName || ''}
                    disabled
                  />
                </Col>

                <Col sm={6}>
                  <Form.Label className="required-field">
                    School Year
                  </Form.Label>
                  <TextInput
                    containerClass="form-group"
                    value={year || ''}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <TextInput
                    label="Subject topics that requires further attention"
                    name="subjectTopics"
                    containerClass="form-group"
                    value={subjectTopics || ''}
                    Row={5}
                    as="textarea"
                    disabled
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label className="required-field">
                    Additional Details
                  </Form.Label>
                  <TextInput
                    containerClass="form-group"
                    value={additionalDetails || ''}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Label className="required-field">Exam Focus</Form.Label>
                  <TextInput
                    containerClass="form-group"
                    value={examFocus || ''}
                    disabled
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>
                    GCSE/IGCSE Subject Board(if applicable)
                  </Form.Label>
                  <TextInput
                    name="gcseIcse"
                    containerClass="form-group"
                    placeholder=""
                    value={gcseIcse || ''}
                    onInputChange={(e) => setGcseIcse(e.target.value)}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h5>Other Details</h5>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Label>Any Known Learning Disabilities</Form.Label>
                  <div>
                    <TextInput
                      containerClass="form-group"
                      value={learningDisabilities === true ? 'Yes' : 'No'}
                      disabled
                    />
                    {showDisabilityText === 'true' && (
                      <TextInput
                        name="disabilityText"
                        containerClass="form-group"
                        placeholder=""
                        value={disabilityText}
                        disabled
                      />
                    )}
                  </div>
                </Col>
                <Col sm={6}>
                  <Form.Label>
                    Any Known Allergies/ Current Medication
                  </Form.Label>
                  <div>
                    <TextInput
                      containerClass="form-group"
                      value={allergies === true ? 'Yes' : 'No'}
                      disabled
                    />
                    {showAllergiesText === 'true' && (
                      <TextInput
                        name="allergiesText"
                        containerClass="form-group"
                        placeholder=""
                        value={allergiesText}
                        disabled
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Label>
                    Where did Student hear of Peepal Tree Learning?
                  </Form.Label>
                  <div>
                    <TextInput
                      containerClass="form-group"
                      value={pplTreeLearning || ''}
                      disabled
                    />
                    {showOtherPTLText === true && (
                      <TextInput
                        name="otherPTLText"
                        containerClass="form-group"
                        placeholder=""
                        value={otherPTLText}
                        disabled
                      />
                    )}
                  </div>
                </Col>
              </Row>
              {tutorSubjectList.length > 0 && (
              <>
              <Row className="mt-3">
                  <Col sm={12}>
                  <h5>Class Details</h5>
                  <hr />
                  </Col>
              </Row>
              <Row className="mt-3 align-items-center font-weight-semibold">
                <Col sm={3}>
                    Subject
                </Col>
                <Col sm={3}>
                    Tutor
                </Col>
              </Row>
              { tutorSubjectList && tutorSubjectList.map((tutor)=>(
                <>
                  <Row className="mt-3 align-items-center">
                    <Col sm={3}>
                      {tutor.subject_name}
                    </Col>
                    <Col sm={3}>
                      {tutor.tutor_name}
                    </Col>
                  </Row>
                </>
              ))}
              </>
              )}
            </Form>
          </Card.Body>
        </UserRestrictions>
      </Card>
      <section className="content-area" />
    </>
  );
}
export default withRouter(Profile);
