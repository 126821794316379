import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Image, Button, Form, Row, Col, Modal, Card } from 'react-bootstrap';
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import {
  TextInput,
  Helmet,
  StarRatings,
  FileInput,
} from '../../../components/FormInputs';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import s3config from '../../../constants';
import CustomFunctions from '../../../helper/customFunctions';
import UserRestrictions from '../../../components/UserRestrictions/userrestrictions.component';

const items = [
  { to: '/', label: 'Home' },
  { to: '/managehomework', label: 'Homework' },
];

const validator = new SimpleReactValidator();
const modalValidator = new SimpleReactValidator();

function StudentHomeworkDetails(props) {
  const [studFeedback, setStudFeedback] = useState('');
  const [titleName, setTitleName] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [className, setClassName] = useState('');
  const [discription, setDiscription] = useState('');
  const [tutorAttachements, setTutorAttachments] = useState([]);
  const [feedbackStudent, setFeedbackStudent] = useState('');
  const [feedback, setFeedback] = useState('');
  const [viewFeedback, setViewFeedback] = useState(false);
  const [ratings, setRatings] = useState(0);
  const [studentRatings, setStudentRatings] = useState(0);
  const [show, setShow] = useState(false);
  const [homeworkThreadData, setHomeworkThreadData] = useState([]);
  const [studentName, setStudentName] = useState('');
  const [count, forceUpdate] = useState(0);
  const [referenceId, setReferenceId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [title, setTitle] = useState('');
  const [userRole, setUserRole] = useState('');
  const [homeWorkId, setHomeWorkId] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [fileError, setFileError] = useState('');
  const [numberOfFile, setNumberOfFile] = useState([{id:0,files:[]}]);
  const [documentFiles, setDocumentFiles] = useState(true);

  useEffect(() => {
    function getData() {
      setTitle('Homework');
      const uRole = CustomFunctions.getLocalStorage('role_id');
      if (!CustomFunctions.checkIfEmpty(uRole)) {
        setUserRole(Number(uRole));
      }
      const id = props.match.params.refId;
      if (id) {
        setReferenceId(id);
      }
    }
    getData();
  }, []);

  useEffect(async () => {
    if (referenceId) {
      getHomeworkStudentData(referenceId);
      getHomeworkThreadData(referenceId);
    }
  }, [referenceId]);

  useEffect(async () => {
    getHomeworkData();
  }, [homeWorkId]);

  const getHomeworkThreadData = async (id) => {
    return new Promise(async (resolve) => {
      // eslint-disable-line
      const { data } = await AuthApi.getDataFromServer(
        `${Api.getHomeworkThreadByRefId}?ref_id=${id}&type=all`
      );
      if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
        setHomeworkThreadData(data.data);
        resolve();
      }
    });
  };

  const getHomeworkData = async () => {
    const homeworkId = CustomFunctions.getLocalStorage('homeworkId');

    if (!homeworkId && !homeWorkId) return;
    let endPoint = homeWorkId ? `${Api.getManageHomeworkByUrl}${homeWorkId}/`: `${Api.getManageHomeworkByUrl}${homeworkId}/`;

    if (Number(userRole) === 4) {
      endPoint = `${Api.getManageHomeworkByUrl}${homeWorkId}/` ;
    }

    return new Promise(async (resolve) => { // eslint-disable-line
      const filesData = [];
      const { data } = await AuthApi.getDataFromServer(endPoint);
      if (!CustomFunctions.checkIfEmpty(data, 'O')) {
        data.data &&
          data.data.attachements &&
          data.data.attachements.map((attach) => {
            filesData.push(attach.file_url);
            return attach;
          });

        setTutorAttachments(data.data.attachements);
        setTitleName(data.data.title);
        setClassName(data.data.class_name);
        setSubjectName(data.data.subject_name);
        setDiscription(data.data.description);
        resolve();
      }
    });
  };

  const getHomeworkStudentData = async (id) => {
    if (!id) return;
    const { data } = await AuthApi.getDataFromServer(
      `${Api.getTutorHomeworkStudentByTutorIdUrl}${id}/`
    );

    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setStudentName(data.data.student_name);
      setHomeWorkId(data.data.homework_id);
      setStudentRatings(Number(data.data.rating));
      setFeedbackStudent(data.data.feedback);
    }
  };

  const handleImageFileUpload = (e) => {
    const fileUrls = imageUrl;
    e &&
      e.files &&
      e.files.map((file) => {
        if (file.response && !fileUrls.includes(file.response.Location)) {
          fileUrls.push(file.response.Location);
        }
        return file;
      });
    setImageUrl([...fileUrls]);
  };

  const addFiles = () =>{
    const maxLength = Math.max(...numberOfFile.map((o)=> { return o.id; }));
    setNumberOfFile([...numberOfFile,{id:maxLength+1,files:[]}]);
  };

  const removeFiles = (i) =>{
    numberOfFile.splice(i, 1);
    setNumberOfFile(JSON.parse(JSON.stringify(numberOfFile)));
  };

  const postHomeworkDetails = async () => {
    const postData = {
      ref: referenceId,
      feedback: studFeedback,
    };

    if (imageUrl.length > 0) {
      postData.attachements = [...imageUrl];
    }

    const callback = AuthApi.postDataToServer;
    const endPoint = `${Api.getHomeworkThreadByRefId}`;
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      validator.hideMessages();
      setDocumentFiles(false);
      swal(data && data.message[0], '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result) {
          setDisableButton(false);
          document.getElementById('myForm').reset();
          window.location.reload();
          setStudFeedback('');
          setImageUrl('');
          setNumberOfFile([{id:0,files:[]}]);
          getHomeworkThreadData(referenceId);
        }
      });
    } else {
      setDisableButton(true);
      validator.hideMessages();
      swal(message, '', 'error', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  };

  const handleClose = () => {
    setViewFeedback(false);
    modalValidator.hideMessages();
    setShow(false);
    setRatings(0);
  };

  const changeRatingFeedback = (newRating) => {
    setRatings(newRating);
  };

  const postStudentFeedback = async () => {
    const postData = {
      rating: ratings,
      feedback,
    };

    const callback = AuthApi.putDataToServer;
    const endPoint = `${Api.getTutorHomeworkStudentByTutorIdUrl}${referenceId}/`;
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      modalValidator.hideMessages();
      swal(data && data.message[0], '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result) {
          setStudentRatings(ratings);
          setFeedbackStudent(feedback);
          setRatings(0);
          setShow(false);
        }
      });
    } else {
      validator.hideMessages();
      swal(message, '', 'error', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  };

  const onSubmitHomeworkDetails = (e) => {
    e.preventDefault();
    if (disableButton) return;

    setDisableButton(true);
    if (validator.allValid()) {
      postHomeworkDetails();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const onSubmitFeedback = (e) => {
    e.preventDefault();
    if (modalValidator.allValid()) {
      postStudentFeedback();
    } else {
      modalValidator.showMessages();
      forceUpdate(count + 1);
    }
  };

  const editFeedback = (type = 'add') => {
    setFeedback('');
    if (type === 'edit') {
      setFeedback(feedbackStudent);
      setRatings(studentRatings);
    }
    setShow(true);
  };

  const displayFeedback = () => {
    setViewFeedback(true);
    setFeedback(feedbackStudent);
    setRatings(studentRatings);
    setShow(true);
  };

  // const createIFrame = (url, triggerDelay, removeDelay) => {
  //   setTimeout(() => {
  //     const frame = document.createElement('iframe');
  //     frame.setAttribute('src', url);
  //     frame.setAttribute('className', 'multi-download');
  //     frame.setAttribute('style', 'display:none');
  //     document.body.appendChild(frame);
  //     setTimeout(() => {
  //       document.body.removeChild(frame);
  //     }, removeDelay);
  //   }, triggerDelay);
  // };

  // const downloadAll = (urls) => {
  //   const link = document.createElement('a');
  //   const triggerDel = 100;
  //   const removeDel = 1000;

  //   link.setAttribute('download', null);
  //   link.style.display = 'none';

  //   document.body.appendChild(link);
  //   for (let i = 0; i < urls.length; i++) { // eslint-disable-line
  //     createIFrame(urls[i], triggerDel * (i + 1), removeDel * (i + 1));
  //   }
  // };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Homework</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-3">
        {userRole === 4 && (
        <>
        <Card className="access-homework-main">
            <Card.Body>
              <div className="access-homework">
                <h5>To Submit Homework:</h5>
                <ul>
                    <li>Click Homework Title</li>
                    <li>Click Add Attachment</li>
                    <li>Upload Completed Homework</li>
                    <li>Add a message for your Tutor (optional)</li>
                    <li>Submit</li>
                </ul>
              </div>
            </Card.Body>
        </Card>
        </>
        )}
          <div className="table-projects comment-section-main mb-4">
            <div className="card student-comment-section">
              <div className="ms-4 homework-thread-header-title">
                <div className="d-flex align-items-center justify-content-end">
                    <a href='/managehomework' >
                       <u>Back</u>
                    </a>
                </div>
              </div>
              <div className="student-title-text d-flex align-items-center justify-content-between">
                <div className="title">
                  <h4>
                    <strong className="text-primary">
                      {studentName || ''}
                    </strong>
                  </h4>
                </div>
                <ul className="m-0 p-0 list-unstyled d-flex align-items-center">
                  <li>School Year {className || ''}</li>
                  <li>{subjectName || ''}</li>
                </ul>
              </div>
              <div className="row filedownload-content filedownload-content-top">
                <div className="col-md-4">
                  <div className="file-download">
                    <Image src="/images/files.svg" alt="image" />
                    {tutorAttachements && tutorAttachements.length > 0 &&
                     tutorAttachements.map((img) => (
                      <span className="span-container">
                         <a href={img.file_url} target="blank">
                                 <Image src="/images/attachment-img.svg"/>
                                    {img.file_url.length > 0
                                      ? decodeURI(
                                        img.file_url.split(
                                            decodeURI(Api.s3url)
                                          )
                                        ).substring(1)
                                      : ''}
                        </a>
                     </span>
                    ))}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="homework-text">
                    <div className="text-rating d-flex align-items-start justify-content-between">
                      <h6>{titleName || ''}.</h6>
                      <div
                        className={`displayFeedbackrating-list d-flex align-items-start justify-content-end ${
                          displayFeedback ? 'view-feedback-show' : ''
                        }`}
                        // className="displayFeedbackrating-list d-flex align-items-start justify-content-end "
                        title={
                          Number(studentRatings) > 0 || feedbackStudent
                            ? 'view feedback'
                            : ''
                        }
                      >
                        {!CustomFunctions.checkIfEmpty(studentRatings) && (
                          <StarRatings
                            starCount={5}
                            value={studentRatings || ''}
                            onStarClick={
                              studentRatings > 0 &&
                              feedbackStudent &&
                              displayFeedback
                            }
                          />
                        )}
                        {(Number(studentRatings) > 0 || feedbackStudent) && (
                          <a
                            href="javascript:void(0)"
                            onClick={displayFeedback}
                          >
                            View feedback
                          </a>
                        )}
                      </div>
                    </div>
                      <div className="homework-edit-description d-flex align-items-start justify-content-between">
                        <div className="homework-description">
                          <p>{discription || ''}</p>
                        </div>
                        <UserRestrictions permittedUsers={[3]} roleId={userRole}>
                        <div className="edit-complete d-flex align-items-center justify-content-end">
                          {!CustomFunctions.checkIfEmpty(studentRatings) && (
                            <div className="edit-thread-icon">
                              <Image
                                src="/images/edit-btn-gray.svg"
                                alt="image"
                                onClick={() => editFeedback('edit')}
                              />
                            </div>
                          )}
                          <Button
                            variant="success"
                            className={feedbackStudent || studentRatings ? 'pointer-disable':''}
                            onClick={() => { feedbackStudent || studentRatings ? '' : editFeedback('add'); }} // eslint-disable-line
                          >
                            {feedbackStudent || studentRatings
                              ? 'Completed'
                              : 'Mark as complete'}
                          </Button>
                        </div>
                        </UserRestrictions>
                      </div>
                  </div>
                </div>
              </div>
              <div className="homeowrk-comment-section">
                <div className="homework-date">
                  <h5>Homework</h5>
                </div>
                <div className="media">
                  <div className="media-body w-100">
                    {homeworkThreadData &&
                      homeworkThreadData.map((homework) => (
                        <Row className="title-comment-box">
                          <Col md={8}>
                            <div className="mb-3">
                              <h6 className="mt-0">
                                {homework.commentar || ''}
                              </h6>
                              <p>{homework.feedback || ''}</p>
                              {homework.attachements &&
                                homework.attachements.map((attach) => (
                                 <a href={attach.file_url} target="blank">
                                 <Image src="/images/attachment-img.svg"/>
                                    {attach.file_url.length > 0
                                      ? decodeURI(
                                          attach.file_url.split(
                                            decodeURI(Api.s3url)
                                          )
                                        ).substring(1)
                                      : ''}
                                  </a>
                                ))}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="homework-thread-submission-date text-end">
                              <span>
                                {`${homework.created_date} ${homework.created_time}` ||
                                  ''}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    <UserRestrictions permittedUsers={[3, 4]} roleId={userRole}>
                      <Form
                        id="myForm"
                        onSubmit={(e) => onSubmitHomeworkDetails(e)}
                      >
                        <div className="textarea-attachment mt-3">
                          <TextInput
                            inputClass="form-control"
                            Row={3}
                            as="textarea"
                            placeholder=" Write your message here"
                            name="studFeedback"
                            containerClass="form-group"
                            style={{height:'100px'}}
                            value={studFeedback || ''}
                            onInputChange={(e) =>
                              setStudFeedback(e.target.value)
                            }
                            errorMessage={validator.message(
                              'feedback',
                              studFeedback,
                              'required'
                            )}
                          />
                        </div>
                        <div className="comment-box d-flex flex-column">
                          <div>
                    { numberOfFile && numberOfFile.map((file, i)=>(
                      <div key={file.id}>
                        <Row sm={12}>
                          <Col className= 'col-md-5'>
                            <FileInput
                              onSelectFile={(e) => {
                                numberOfFile[i].files = e;
                                handleImageFileUpload(e);
                                setNumberOfFile([...numberOfFile]);
                              }}
                              isS3
                              showUploadProgress={!documentFiles.toString() ? 'true' : 'false'}
                              accept=".xls,.txt,.pdf,.doc,image/jpeg,image/png,"
                              s3config={s3config}
                              allowedFileSize={25 * 1024 * 1024}
                              uploadUrl={Api.fileUploadUrl}
                              payloadKey="file_name"
                              onError={(error) => setFileError(error)}
                              errorMessage={fileError}
                            />
                            </Col>
                            <Col sm={2}>
                              {(numberOfFile.length > 1) ?
                                <Image
                                  src="/images/fail.svg"
                                  className="ms-2 close-file-icon"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    removeFiles(i);
                                  }}
                                />
                              :''}
                            </Col>
                          </Row>
                        </div>
                        ))
                      }
                       <Button
                          variant="primary"
                          type="button"
                          className="mt-2 mb-2"
                          onClick={()=>addFiles()}
                        >
                        Add More
                      </Button>
                          </div>
                        </div>
                        <div className="send-btn">
                          <Button
                            type="submit"
                            variant="primary"
                            disabled={disableButton}
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </UserRestrictions>
                    {show && (
                      <Modal
                        centered
                        className="rating-popup"
                        show={show}
                        onHide={() => handleClose()}
                      >
                        <Card body>
                          <Form
                            id="myModalForm"
                            onSubmit={(e) => onSubmitFeedback(e)}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title className="text-primary">
                                <h6>
                                  {viewFeedback
                                    ? 'Homework Feedback'
                                    : 'Homework Status'}
                                </h6>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <StarRatings
                                label="Ratings"
                                name="rating"
                                starCount={5}
                                value={ratings || ''}
                                onStarClick={(nextValue) =>
                                  changeRatingFeedback(nextValue)
                                }
                                isEdit={!viewFeedback}
                              />
                              <TextInput
                                label="Feedback"
                                inputClass="form-control"
                                Row={3}
                                as="textarea"
                                placeholder="Write your message here"
                                name="feedback"
                                containerClass="form-group"
                                value={feedback || ''}
                                onInputChange={(e) =>
                                  setFeedback(e.target.value)
                                }
                                disabled={viewFeedback}
                                errorMessage={modalValidator.message(
                                  'feedback',
                                  feedback,
                                  'required'
                                )}
                              />
                            </Modal.Body>
                            {!viewFeedback && (
                              <Modal.Footer>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                  <Button
                                    variant="secondary"
                                    onClick={() => handleClose()}
                                  >
                                    Cancel
                                  </Button>
                                  <Button variant="primary" type="submit">
                                    Save
                                  </Button>
                                </div>
                              </Modal.Footer>
                            )}
                          </Form>
                        </Card>
                      </Modal>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default withRouter(StudentHomeworkDetails);
