import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import FullCalendar from 'stark-fullcalendar';
import { Row, Col, Card } from 'react-bootstrap';
import swal from 'sweetalert';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import RightContainer from '../../../components/RightContainer/rightContainer.component';
import { Helmet } from '../../../components/FormInputs';
import UserRestrictions from '../../../components/UserRestrictions/userrestrictions.component';
import CustomFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';

const items = [{ to: '/', label: 'Dashboard' }];

function Dashboard() {
  const [title, setTitle] = useState('');
  const [userRole, setUserRole] = useState('');
  const [studentActive, setStudentActive] = useState('');
  const [totalStudent, setTotalStudent] = useState('');
  const [totalTutor, setTotalTutor] = useState('');
  const [tutorActive, setTutorActive] = useState('');
  const [upcomingVirtualClass, setUpcomingVirtualClass] = useState('');
  const [completedVirtualClass, setCompletedVirtualClass] = useState('');
  const [totalNoOfLiveClassHours, setTotalNoOfLiveClassHours] = useState('');
  const [totalNoOfAttendance, setTotalNoOfAttendance] = useState('');
  const [guardianStudent, setGuardianStudent] = useState(''); //eslint-disable-line
  const [classId, setClassId] = useState('');

  useEffect(() => {
    async function getData() {
      setTitle('Dashboard');
      const uRole = CustomFunctions.getLocalStorage('role_id');
      const userid = CustomFunctions.getLocalStorage('id');
      if (!CustomFunctions.checkIfEmpty(uRole)) {
        setUserRole(Number(uRole));
      }
      if(Number(uRole) === 1 || Number(uRole) === 2){
        await getDashboardData();
      }
      if(Number(uRole) === 5){
        await getDashboardDataForGuardian();
      }
      if (Number(uRole) === 4){
        await getStudentClassIdByStudentId(userid);
      }
   }
   getData();
  }, []);

  const ref = useRef();

  const handleEventClick = async (clickInfo) => {
    clickInfo.jsEvent.preventDefault();
    const { attributes } = clickInfo.jsEvent.target;
    const parentAction = Object.keys(clickInfo.jsEvent.target.attributes).filter(item => {
      return attributes[item].name.includes('parentcallback');
    });
    if (parentAction.length)
      return false;
    const arr = [];
    Object.entries(clickInfo.event.extendedProps).map(([key, value]) => {
      const obj = {};
      obj.variableName = key;
      obj.value = value;
      arr.push(obj);
      return obj;
    });
    return true;
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        {
          eventInfo.view.type === 'timeGridDay' ? (
            <>
              <Row className='day-time-wrapper align-item-center h-100'>
                <Col>
                  <i>{eventInfo.event.title}</i>
                  <i>{eventInfo.event.description}</i>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className="fc-event-title">{eventInfo.event.title}</div>
            </>
          )
        }
     </>
    );
   };

   const getEventList = async (fetchInfo, successCallback) => {
    let type = '';

    if (ref && ref.current)
      type = ref.current.getApi().view.type; //eslint-disable-line

    let endPoint = `${Api.calenderUrl}?mode=custom&date_from=${CustomFunctions.formatDate(fetchInfo.start, '-', 'ymd')}&date_to=${CustomFunctions.formatDate(fetchInfo.end, '-', 'ymd')}&response_mode=day`;

    if(userRole === 4 && !classId){
      return false;
    }

    if (Number(userRole) === 4) {
      endPoint = `${Api.calenderUrl}?mode=custom&date_from=${CustomFunctions.formatDate(fetchInfo.start, '-', 'ymd')}&date_to=${CustomFunctions.formatDate(fetchInfo.end, '-', 'ymd')}&response_mode=day&class_id=${classId}`;
    }

    const { data } = await AuthApi.getDataFromServer(endPoint);
    // you can call api here
    const eventData = [];
    if (data && data.data) {
        data.data.map(item=>{
          if(item.title !== 'HW: 0' && item.title !=='VC: 0'){
            eventData.push(item);
          }
          return item;
        });
    };
    return successCallback(eventData);
  };

  const getDashboardData = () => {
    return new Promise(async (resolve) => {
      const { data } = await AuthApi.getDataFromServer(
        `${Api.dashboardUrl}`
      );
      if (!CustomFunctions.checkIfEmpty(data.data, 'O')) {
          setStudentActive(data.data.student_active);
          setTotalStudent(data.data.total_student);
          setTotalTutor(data.data.total_tutor);
          setTutorActive(data.data.tutor_active);
          setUpcomingVirtualClass(data.data.upcoming_virtual_class);
          setCompletedVirtualClass(data.data.completed_virtual_class);
          setTotalNoOfAttendance(data.data.this_week_attendance);
          setTotalNoOfLiveClassHours(data.data.total_hours);
          resolve();
      }
    });
  };

  const getDashboardDataForGuardian = () => {
    return new Promise(async (resolve) => {
      const { data } = await AuthApi.getDataFromServer(
        `${Api.studentUrl}`
      );

      if (!CustomFunctions.checkIfEmpty(data, 'O')) {
          setGuardianStudent(data.data);
          resolve();
      }
    });
  };

  const switchAccount = (uid, status) => {
    if(status === 2){
        swal('Student is inactive, please contact to Admin.', '', 'error', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }else{
      CustomFunctions.switchUser(
        Api.userImpersonateUrl,
        uid,
        window.location.pathname,
        (e) => {
          swal(e, '', 'error');
        }
      );
    }
  };

  const getStudentClassIdByStudentId = async (userid) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.studentUrl}${userid}/`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'O')) {
      setClassId(data.data.class);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="container-fluid">
        <div className="page-header">
          <div className="title-breadcrumb-section">
            <div className="row">
              <div className="col-xl-8" id="main-content">
                <h2 className="main-content-title tx-24 mg-b-5">
                  Welcome To Dashboard
                </h2>
              </div>
            </div>
            <Breadcrumb items={items} />
          </div>
        </div>
        <section className="content-area">
          <div className="row">
            <div className="col-xl-8" id="main-content" >
            <UserRestrictions permittedUsers={[3, 4]} roleId={userRole}>
                <FullCalendar
                // required if need to handle action
                ref={ref}

                contentHeight = {500}
                // required if need to handle action
                // calenderRef  = {ref}
                // optional : to wrapper class for calendar
                classWrapper = "calender-wrap"

                // optional : by default 0 for Sunday
                firstDay = '1'

                // required : at least one. if action required then must add interactionPlugin
                pluginslist={['dayGridPlugin', 'timeGridPlugin', 'interactionPlugin']}

                // optional : you can hide or show header toolbar
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek'
                }}

                // required : based on plugin specified up.
                initialView="dayGridMonth"

                // optional: to allow drag and edit event
                editable={false}

                // optional
                selectable={false}

                // optional
                selectMirror={false}

                // optional
                dayMaxEvents

                // optional : to show weekends in calendar
                weekendsVisible

                // required : here u can directly specify data or use callback function to handle navigation
                events={getEventList}

                // optional : to change layout of event
                eventContent={(e) => renderEventContent(e)}

                // optional : to perform action on event click
                eventClick={(e) => handleEventClick(e)}

                // optional : to parform action when click on date
                dateClick={(e) => {
                  const calendarApi = ref.current.getApi();
                  calendarApi.changeView('timeGridDay', e.date);
                }}

          />
          </UserRestrictions>

          <UserRestrictions permittedUsers={[1, 2]} roleId={userRole}>
            <Row>
              <Col>
                <Card className='bg-danger'>
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="text-white font-14">Total Students</p>
                        <h5 className="text-white">{totalStudent}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className='bg-danger'>
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="text-white font-14">Total Tutors</p>
                        <h5 className="text-white">{totalTutor}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className='bg-primary'>
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="text-white font-14">Total Active Students</p>
                        <h5 className="text-white">{studentActive}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                 <Card className='bg-primary'>
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="text-white font-14">Total Active Tutors</p>
                        <h5 className="text-white">{tutorActive}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
             <Row>
              <Col>
                <Card className='bg-danger'>
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="text-white font-14">Completed Virtual Classes</p>
                        <h5 className="text-white">{completedVirtualClass}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                 <Card className='bg-primary'>
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="text-white font-14">Upcoming Virtual Classes</p>
                        <h5 className="text-white">{upcomingVirtualClass}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
             <Row>
              <Col>
                <Card className='bg-danger'>
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="text-white font-14">Total Live Class Hours</p>
                        <h5 className="text-white">{totalNoOfLiveClassHours}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                 <Card className='bg-primary'>
                  <Card.Body>
                    <Row>
                      <Col>
                        <p className="text-white font-14">Total Attendance in the week</p>
                        <h5 className="text-white">{totalNoOfAttendance}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </UserRestrictions>

          <UserRestrictions permittedUsers={[5]} roleId={userRole}>
            <Row>
            {guardianStudent && guardianStudent.map((studentDetails)=>
                  {
                  return (
                    <>
                    <Col sm={6} >
                      <Card className='bg-danger'>
                        <Card.Body>
                              <p className="text-white font-14"> {studentDetails.full_name}
                              <a
                                href="/"
                                className="p-r-2"
                                title="Switch User"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  switchAccount(studentDetails.id, studentDetails.status);
                                }}
                              >
                              <img
                                src="/images/login-button_gray.svg"
                                width="18px"
                                height="21px"
                                alt="Switch Account"
                                className="float-right"
                              />
                              </a>
                              </p>
                              <h5 className="text-white">{studentDetails.class_name}</h5>
                              <p  className="text-white font-14">{studentDetails.subjects_name}</p>
                        </Card.Body>
                      </Card>
                    </Col>
                </>
               );
               })}
            </Row>
          </UserRestrictions>

          </div>
          {/* { userRole === 5 && (
            <> */}
              <div className="col-xl-4" id="right-sidebar">
                <RightContainer />
              </div>
            {/* </>
          )} */}
          </div>
        </section>
      </div>
    </>
  );
}

export default withRouter(Dashboard);
