import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Card, Form, Col, Button, Row, Image } from 'react-bootstrap';
import { setHours, setMinutes } from 'date-fns';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import s3config from '../../../constants';
import {
  DropdownInput,
  DateTimePicker,
  TextInput,
  Helmet,
  FileInput,
} from '../../../components/FormInputs';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import 'react-datepicker/dist/react-datepicker.css';
import CustomFunctions from '../../../helper/customFunctions';
import UserRestrictions from '../../../components/UserRestrictions/userrestrictions.component';

const items = [
  { to: '/', label: 'Home' },
  { to: '/home/', label: ' Set Up Live Class' },
];

const durationList = [
  { value: 30, label: '30 Minutes' },
  { value: 60, label: '60 Minutes' },
  { value: 90, label: '90 Minutes' },
  { value: 120, label: '120 Minutes' },
  { value: 150, label: '150 Minutes' },
  { value: 180, label: '180 Minutes' },
  { value: 210, label: '210 Minutes' },
  { value: 240, label: '240 Minutes' },
  { value: 270, label: '270 Minutes' },
  { value: 300, label: '300 Minutes' },
];

const validator = new SimpleReactValidator();

const AddManageSession = (props) => {
  const history = useHistory();
  const [classTitle, setClassTitle] = useState(null);
  const [classDescription, setClassDescription] = useState(null);
  const [selectOptionYear, setSelectOptionYear] = useState(null);
  const [selectOptionSubject, setSelectOptionSubject] = useState(null);
  const [selectOptionStudent, setSelectOptionStudent] = useState(null);
  const [selectDate, setSelectDate] = useState('');
  const [time, setTime] = useState('');
  const [duration, setDuration] = useState('');
  const [timezone, setTimeZone] = useState('');
  const [count, forceUpdate] = useState(0);
  const [imageBucket, setImageBucket] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userId, setTutorId] = useState('');
  const [subjectList, setSubjectList] = useState([]);
  const [virtualClassId, setVirtualClassId] = useState('');
  const [selsubjectOptions, setSubjectOptions] = useState(null);
  const [selectOptionTutor, setSelectOptionTutor] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [selStudentOptions, setStudentOptions] = useState(null);
  const [selyearOptions, setYearOptions] = useState(null);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [title, setTitle] = useState('');
  const [fileError, setFileError] = useState('');
  const [numberOfFile, setNumberOfFile] = useState([{id:0,files:[]}]);
  const [minTime, setMinTime] = useState('');
  const [maxTime, setMaxTime] = useState('');

  useEffect(() => {
    async function getData() {
      const uRole = CustomFunctions.getLocalStorage('role_id');
      if (!CustomFunctions.checkIfEmpty(uRole)) {
        setUserRole(Number(uRole));
      }
      const uid = CustomFunctions.getLocalStorage('id');
      if (!CustomFunctions.checkIfEmpty(uid)) {
        setTutorId(Number(uid));
      }
      setTitle('Add Virtual Class');
      const id = props.match.params.virtualClassId;
      if (id) {
        setTitle('Edit Virtual Class');
        setVirtualClassId(id);
      }
      await getSubjectList();
      await getTimeZone();
    }
    getDuration();
    getData();
    setDateTime(new Date());
  }, []);

  useEffect(() => {
    if (virtualClassId) getVirtualClasses(virtualClassId);
  }, [virtualClassId]);

  const getDuration = () => {
    if (!durationList) return [];
    const durationListOptions = [];
    durationList.map((durationTime) => {
      return durationListOptions.push({
        label: durationTime.label,
        id: durationTime.value,
        value: durationTime.value,
      });
    });
    return durationListOptions;
  };

  const handleImageFileUpload = (e) => {
    const fileUrls = imageUrl;
    e &&
      e.files &&
      e.files.map((file) => {
        if (file.response && !fileUrls.includes(file.response.Location)) {
          fileUrls.push(file.response.Location);
        }
        return file;
      });
    setImageUrl([...fileUrls]);
  };

  const addFiles = () =>{
    const maxLength = Math.max(...numberOfFile.map((o)=> { return o.id; }));
    setNumberOfFile([...numberOfFile,{id:maxLength+1,files:[]}]);
  };

  const removeFiles = (i) =>{
    numberOfFile.splice(i, 1);
    setNumberOfFile(JSON.parse(JSON.stringify(numberOfFile)));
  };

  const getSubjectList = () => {
    return new Promise(async (resolve) => {
      const { data } = await AuthApi.getDataFromServer(
        `${Api.getTutorClassSubjectByUrl}?type=all`
      );

      if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
        setSubjectList([...data.data]);
        resolve();
      }
    });
  };

  const getSubjectData = (classid, tutorid) => {
    if (!subjectList) return [];
    const subjectOptions = [];
    subjectList &&
      subjectList.map((subject) => {
        if (Number(subject.id) === Number(tutorid)) {
          subject &&
            subject.tutor_subjects &&
            subject.tutor_subjects.map((dt) => {
              if (Number(dt.class_id) === Number(classid)) {
                return subjectOptions.push({
                  label: dt.subject_name,
                  id: dt.subject_id,
                  value: dt.subject_id,
                });
              }
              return subjectOptions;
            });
        }
        return subject;
      });
    const key = 'id';
    const uniqueSubjects = [
      ...new Map(subjectOptions.map((item) => [item[key], item])).values(),
    ];
    setSubjectOptions(uniqueSubjects);
    setSelectOptionSubject('');
    setSelectOptionStudent('');
    return uniqueSubjects;
  };

  const postVirtualClass = async () => {
    const selectStudentId = [];

    selectOptionStudent &&
      selectOptionStudent.map((op) => {
        return selectStudentId.push(op.value);
      });

    const postData = {
      title: classTitle,
      classes: selectOptionYear.id,
      description: classDescription,
      subject: selectOptionSubject.id,
      start_time: `${CustomFunctions.getYearDate(
        selectDate
      )} ${CustomFunctions.getTime(time)}`,
      time_zone: timezone.label,
      status: 1,
      duration: duration.id,
      tutor: selectOptionTutor ? selectOptionTutor.id : userId,
      students: selectStudentId,
      attachements: [],
      id: virtualClassId,
    };
    if (imageUrl.length > 0 || imageBucket.length > 0) {
      postData.attachements = [...imageUrl,...imageBucket];
    }
    const payload = CustomFunctions.cleanObject(postData);
    const callback = virtualClassId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = `${Api.virtualClassUrl}`;

    const { data, message } = await callback(endPoint, payload);

    if (data) {
      validator.hideMessages();
      swal(data && data.message[0], '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result) {
          setDisableButton(false);
          setSelectOptionSubject(null);
          setSelectOptionStudent(null);
          setSelectOptionYear(null);
          setSelectOptionTutor(null);
          setClassTitle('');
          setClassDescription('');
          setSelectDate('');
          setTimeZone('');
          setDuration('');
          setTime('');
          history.push('/virtualclasses');
        }
      });
    } else {
      setDisableButton(false);
      swal(message, '', 'error');
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (disableButton) return;
    setDisableButton(true);
    if (validator.allValid()) {
      postVirtualClass();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const onCancel = () => {
    validator.hideMessages();
    history.push('/virtualclasses');
  };

  const getVirtualClasses = async (id = 0) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.virtualClassUrl}${id}/`
    );

    if (!CustomFunctions.checkIfEmpty(data && data.data, 'O')) {
      const images = [];
      const studentData = [];

      const studentListArray = await getTutorSubjectClassData(
        data.data.tutor_id,
        data.data.class_id,
        data.data.subject_id
      );

      if (data.data.subject_id) {
        setSelectOptionSubject({
          label: data.data.subject_name,
          id: data.data.subject_id,
          value: data.data.subject_id,
        });
      }
      if (data.data.class_id) {
        setSelectOptionYear({
          label: data.data.class_name,
          id: data.data.class_id,
          value: data.data.class_id,
        });
      }
      if (data.data.tutor_id) {
        setSelectOptionTutor({
          label: data.data.tutor_name,
          id: data.data.tutor_id,
          value: data.data.tutor_id,
        });
      }

      if (data.data.duration) {
        const resultDuration = durationList.find((a) => {
          return Number(a.value) === Number(data.data.duration);
        });

        if(!CustomFunctions.checkIfEmpty(resultDuration,'O')){
        data.data.duration &&
          setDuration({
            label: resultDuration.label,
            id: data.data.duration,
            value: data.data.duration,
          });
        }
      }

      if (data.data.time_zone) {
        setTimeZone({
          label: data.data.time_zone,
          value: data.data.time_zone,
        });
      }

      studentListArray &&
        studentListArray.map((stud) => {
          data.data.students &&
            data.data.students.map((i) => {
              if (Number(stud.id) === Number(i)) {
                return studentData.push({
                  label: stud.full_name,
                  id: stud.id,
                  value: stud.id,
                });
              }
              return i;
            });
          return stud;
        });

      setSelectOptionStudent(studentData);
      setClassTitle(data.data.title);
      setClassDescription(data.data.description);
      setTime(new Date(data.data.start_time));
      setSelectDate(new Date(data.data.start_time));

      data.data.attachements &&
        data.data.attachements.map((attach) => {
          images.push(attach.file_url);
          return attach;
        });
      setImageBucket(images);
    }
  };

  const getTutorSubjectClassData = async (tutorId, classId, subjectId) => {
    if (!studentList) return [];
    const studentOptions = [];

    return new Promise(async (resolve) => {
      const endPoint = `${Api.studentUrl}`;

      const params = {
        class_id: classId,
        subject_id: subjectId,
        tutor_id: tutorId,
        status : 1,
        type: 'all'
      };

      const url = CustomFunctions.generateUrl(endPoint, params);
      const { data } = await AuthApi.getDataFromServer(url);
      if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
        data.data.map((stud) => {
          return studentOptions.push({
            label: stud.full_name,
            id: stud.id,
            value: stud.id,
          });
        });
        setStudentList(data.data);
        setStudentOptions([...studentOptions]);
        resolve(data.data);
      } else {
        setSelectOptionStudent('');
        setStudentOptions('');
        resolve();
      }
    });
  };

  const getYearData = (tutorid) => {
    if (!subjectList) return [];
    const yearOptions = [];
    subjectList &&
      subjectList.map((subject) => {
        if (Number(subject.id) === Number(tutorid)) {
          subject &&
            subject.tutor_subjects &&
            subject.tutor_subjects.map((dt) => {
              return yearOptions.push({
                label: dt.class_name,
                id: dt.class_id,
                value: dt.class_id,
              });
            });
        }
        return subject;
      });
    const key = 'id';
    const uniqueYears = [
      ...new Map(yearOptions.map((item) => [item[key], item])).values(),
    ];
    setYearOptions(uniqueYears);
    setSelectOptionYear('');
    setSelectOptionSubject('');
    setSelectOptionStudent('');
    return uniqueYears;
  };

  useEffect(() => {
    if (Number(userRole) === 3 && Number(userId)) {
      getYearData(userId);
    }
  }, [subjectList]);

  const getTimezoneList = () => {
    if (!timeZoneList) return [];
    const timeZoneListOptions = [];
    timeZoneList.map((tmzone) => {
      return timeZoneListOptions.push({
        label: tmzone.name,
        id: tmzone.id,
        value: tmzone.name,
      });
    });
    return timeZoneListOptions;
  };

  const getTimeZone = async () => {
    const { data } = await AuthApi.getDataFromServer(`${Api.timeZoneUrl}`);
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setTimeZoneList([...data.data]);
    }
  };

  const getTutorData = () => {
    if (!subjectList) return [];
    const tutorOptions = [];
    subjectList &&
      subjectList.map((subject) => {
        return tutorOptions.push({
          label: `${subject.first_name} ${subject.last_name}`,
          id: subject.id,
          value: subject.id,
        });
      });
    const key = 'id';
    const uniqueTutorArray = [
      ...new Map(tutorOptions.map((item) => [item[key], item])).values(),
    ];
    return uniqueTutorArray;
  };

  const onDeleteHandler = (index) => {
    const buck = [];
    const ind = imageBucket.splice(index, 1);
    imageBucket &&
      imageBucket.map((bucket) => {
        buck.push(bucket);
        return bucket;
      });
    if (ind) {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure that you want to delete this record?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result) {
          setImageBucket(buck);
        }
      });
    }
  };

  const setDateTime = (date) => {
    const curDate = new Date();
    if(CustomFunctions.getDMYDate(date) === CustomFunctions.getDMYDate(curDate)){
      setMinTime(setHours(setMinutes(new Date, new Date().getMinutes()), new Date().getHours()));
      setMaxTime(setHours(setMinutes(new Date, 59), 23));
    } else {
      setMinTime(setHours(setMinutes(new Date, new Date(date).getMinutes()), new Date(date).getHours()));
      setMaxTime(setHours(setMinutes(new Date, 59), 23));
    }
  };

  validator.purgeFields();

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Add Virtual Class</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Card>
            <Card.Header>
              <h5 className="mt-2 mb-2">{title}</h5>
            </Card.Header>
            <Card.Body>
              <Form autoComplete="off" onSubmit={(e) => submitForm(e)}>
                <Row>
                  <h5 className="font-weight-semibold text-muted">
                    Class Details
                  </h5>
                  <Col className="col-md-6 mt-2">
                    <TextInput
                      onInputChange={(e) => setClassTitle(e.target.value)}
                      name="classTitle"
                      containerClass="form-group"
                      inputClass="form-control"
                      label="Class Title"
                      asterisk="*"
                      value={classTitle || ''}
                      placeholder="Enter Class Title"
                      errorMessage={validator.message(
                        'Class Title',
                        classTitle,
                        'required|regex:^(?=.*)(?=.*[A-Za-z ])[A-Za-z0-9 ]*$',
                        {
                          messages: {
                            required: 'Class Title field is required',
                            regex:
                              'Class title should be either string or alphanumeric.',
                          },
                        }
                      )}
                    />
                    <Col className="col-md-12">
                    { numberOfFile && numberOfFile.map((file, i)=>(
                      <div key={file.id}>
                        <Row>
                          <Col className= {(numberOfFile.length > 1) ? 'col-md-10' : 'col-md-12'}>
                            <FileInput
                              title= {i > 0 ? '' : 'Attach Document'}
                              optionalLabel={i > 0 ? '' :'(Allowed file size - 25 MB, file types - PDF, JPG, PNG, DOC, TXT, XLS)'}
                              onSelectFile={(e) => {
                                numberOfFile[i].files = e;
                                handleImageFileUpload(e);
                                setNumberOfFile([...numberOfFile]);
                              }}
                              isS3
                              showUploadProgress
                              accept=".xls,.txt,.pdf,.doc,image/jpeg,image/png,"
                              s3config={s3config}
                              allowedFileSize={25 * 1024 * 1024}
                              uploadUrl={Api.fileUploadUrl}
                              payloadKey="file_name"
                              onError={(error) => setFileError(error)}
                              errorMessage={fileError}
                            />
                            </Col>
                            <Col sm={2}>
                              {(numberOfFile.length > 1) ?
                                <Image
                                  src="/images/fail.svg"
                                  className="ms-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    removeFiles(i);
                                  }}
                                />
                              :''}
                            </Col>
                          </Row>
                        </div>
                        ))
                      }
                       <Button
                          variant="primary"
                          type="button"
                          className="mt-2 mb-2"
                          onClick={()=>addFiles()}
                        >
                        Add More
                      </Button>
                    </Col>
                    <Row>
                      <Col>
                        {userId
                          ? imageBucket &&
                            imageBucket.map((img, index) => (
                              <span className="span-container attchment-edit-image">
                                {img && (
                                  <a className="ms-2" href={img} target="blank">
                                    {img.length > 0
                                      ? decodeURI(
                                          img.split(decodeURI(Api.s3url))
                                        ).substring(1)
                                      : ''}
                                  </a>
                                )}
                                <Image
                                  src="/images/delete.png"
                                  className="ms-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDeleteHandler(index);
                                  }}
                                />
                              </span>
                            ))
                          : ''}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={6} className="mt-2">
                    <TextInput
                      onInputChange={(e) => setClassDescription(e.target.value)}
                      name="description"
                      asterisk="*"
                      label="Description"
                      value={classDescription || ''}
                      Row={5}
                      as="textarea"
                      placeholder="Write here"
                      errorMessage={validator.message(
                        'Description',
                        classDescription,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <DateTimePicker
                      label="Date"
                      asterisk="*"
                      className="form-control"
                      selected={selectDate || ''}
                      minDate={new Date()}
                      placeholder="Select Date"
                      onChange={(e) => {
                        setSelectDate(e);
                        setDateTime(e);
                      }}
                      dateFormat="dd/MM/yyyy"
                      errorMessage={validator.message(
                        'date',
                        selectDate,
                        'required'
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <DateTimePicker
                      label="Time"
                      asterisk="*"
                      className="form-control"
                      selected={time || ''}
                      placeholder="Select Time"
                      onChange={(e) => {
                        setTime(e);
                      }}
                      showTimeSelect="showTimeSelect"
                      showTimeSelectOnly="showTimeSelectOnly"
                      timeIntervals="1"
                      dateFormat="hh:mm aa"
                      errorMessage={validator.message('time', time, 'required')}
                      minTime={minTime}
                      maxTime={maxTime}
                    />
                  </Col>
                  <Col sm={3}>
                    <DropdownInput
                      label="Duration"
                      options={getDuration()}
                      placeholder="Select"
                      defaultValue={duration || ''}
                      asterisk="*"
                      onSelectHandler={(select) => {
                        setDuration(select);
                      }}
                      errorMessage={validator.message(
                        'duration',
                        duration,
                        'required'
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <DropdownInput
                      label="Timezone"
                      asterisk="*"
                      options={getTimezoneList()}
                      defaultValue={timezone || ''}
                      placeholder="Select"
                      onSelectHandler={(select) => {
                        setTimeZone(select);
                      }}
                      errorMessage={validator.message(
                        'timezone',
                        timezone,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="font-weight-semibold text-muted mb-0">
                        Attendees Details
                      </h5>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <UserRestrictions permittedUsers={[1, 2]} roleId={userRole}>
                    <Col className="col-md-6 mt-2">
                      <DropdownInput
                        label="Tutor Name"
                        asterisk="*"
                        placeholder="Select Tutor Name"
                        options={getTutorData()}
                        defaultValue={selectOptionTutor || ''}
                        onSelectHandler={(select) => {
                          getYearData(select.id);
                          setSelectOptionTutor(select);
                          setSubjectOptions(null);
                          setStudentOptions(null);
                        }}
                        errorMessage={
                          CustomFunctions.hasUserRestrictions([1, 2])
                            ? validator.message(
                                'Tutor',
                                selectOptionTutor,
                                'required'
                              )
                            : ''
                        }
                        disabled={virtualClassId ? true : ''}
                      />
                    </Col>
                  </UserRestrictions>
                  <Col sm={6} className="mt-2">
                    <DropdownInput
                      label="School Year"
                      placeholder="Select School Year"
                      asterisk="*"
                      defaultValue={selectOptionYear || ''}
                      options={selyearOptions}
                      onSelectHandler={(select) => {
                        const tid = selectOptionTutor
                          ? selectOptionTutor.id
                          : userId;
                        getSubjectData(select.id, tid);
                        setSelectOptionYear(select);
                        setStudentOptions(null);
                      }}
                      errorMessage={validator.message(
                        'School Year',
                        selectOptionYear,
                        'required'
                      )}
                      disabled={virtualClassId ? true : ''}
                    />
                  </Col>

                  <Col sm={6} className="mt-2">
                    <DropdownInput
                      label="Subject"
                      placeholder="Select Subject"
                      asterisk="*"
                      options={selsubjectOptions}
                      defaultValue={selectOptionSubject || ''}
                      onSelectHandler={(select) => {
                        const tid = selectOptionTutor
                          ? selectOptionTutor.id
                          : userId;
                        setSelectOptionSubject(select);
                        getTutorSubjectClassData(
                          tid,
                          selectOptionYear.id,
                          select.id
                        );
                      }}
                      errorMessage={validator.message(
                        'Subject',
                        selectOptionSubject,
                        'required'
                      )}
                      disabled={virtualClassId ? true : ''}
                    />
                  </Col>
                  <Col
                    className={`mt-2 ${
                      userRole === 2 ||  userRole === 1
                    }? 'col-md-12 mt-2': 'col-md-6 mt-2'}`}
                  >
                    <DropdownInput
                      label="Student Name"
                      placeholder="Select Student Name"
                      options={selStudentOptions || [] }
                      defaultValue={selectOptionStudent || ''}
                      onSelectHandler={(select) =>
                        setSelectOptionStudent(select)
                      }
                      asterisk="*"
                      isMulti
                      errorMessage={validator.message(
                        'Student',
                        selectOptionStudent,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <div className="float-end">
                  <Button variant="secondary" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="ms-4"
                    disabled={disableButton}
                  >
                    {virtualClassId ? ' Update' : 'Add'}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default withRouter(AddManageSession);
