import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Card,
  Image,
  Accordion,
  Form,
} from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import CustomFunctions from '../../../helper/customFunctions';
import {
  TextInput,
  DropdownInput,
  DateTimePicker,
  Helmet,
} from '../../../components/FormInputs';
import Table from '../../../components/Table/simpletable.component';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';
import 'react-datepicker/dist/react-datepicker.css';
import UserRestrictions from '../../../components/UserRestrictions/userrestrictions.component';

const items = [
  { to: '/', label: 'Home' },
  { to: '/managehomework', label: 'Homework' },
];

const statusData = [
  {
    id: 1,
    label: 'Pending',
    classes: 'text-warning',
    color: '',
  },
  {
    id: 2,
    label: 'In Progress',
    classes: 'text-danger',
    color: 'table-red-bg',
  },
  {
    id: 3,
    label: 'In Progress',
    classes: 'text-danger',
    color: 'table-red-bg',
  },
  {
    id: 4,
    label: 'Complete',
    classes: 'text-success',
    color: '',
  },
];

function ManageHomework() {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [selectOptionSubject, setSelectOptionSubject] = useState(null);
  const [selectOptionYear, setSelectOptionYear] = useState(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [subjectList, setSubjectList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [cntClearFilter, setCntClearFilter] = useState(0);
  const [tutorList, setTutorList] = useState([]);
  const [selectOptionTutor, setSelectOptionTutor] = useState(null);
  const [title, setTitle] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userId, setUserId] = useState('');
  const [classId, setClassId] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  const [filterErr, setFilterErr] = useState('');
  let timmer = null;
  let disableButton = false;
  let searchFormSubmit = false;

  const handleActionCallback = ({ id }) => {
    return (
      <>
        <a
          href="/"
          className="p-r-2 text-info"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push(`/studenthomeworkstatus/${id}`);
          }}
        >
          view
        </a>
        <a
          href="/"
          className="p-r-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push(`/managehomework/edit/${id}`);
          }}
        >
          <Image src="/images/edit-button.svg" alt="Edit Tutor" />
        </a>
        <a
          href="/"
          className="p-r-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            deleteHomework(id);
          }}
        >
          <Image src="/images/delete.png" />
        </a>
      </>
    );
  };

  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [1, 2, 3],
      isDisplay: false,
    },
    {
      id: 'homeworktitle',
      label: 'Homework Title',
      roleAccess: [1, 2, 3, 4],
      render: ({ homeworktitle, id, role, isDelay }) => {
        return (
          <>
            {role === 4 &&
              <>
                {isDelay &&
                  <Image src="/images/red-flag.gif" />
                }

                <a
                  href={CustomFunctions.userRestrictions(
                    `/studenthomeworkdetails/${id}`,
                    [4],
                    true
                  )}
                  className="p-r-2 font-weight-semibold"
                >
                  {homeworktitle}
                </a>
              </>
            }
            {role !== 4 &&
              <>
              {isDelay &&
                <Image src="/images/red-flag.gif" />
              }
                {homeworktitle}

              </>
            }
          </>
        );
      },
    },
    {
      id: 'year',
      label: 'School Year',
      roleAccess: [1, 2, 3],
    },
    {
      id: 'tutor',
      label: 'Tutor Name',
      roleAccess: [1, 2, 4],
    },
    {
      id: 'subject',
      label: 'Subject',
      roleAccess: [1, 2, 3, 4],
    },
    {
      id: 'attachment',
      label: 'Attachment',
      roleAccess: [1, 2, 3, 4],
      render: ({ attachment }) => {
        return (
          <>
            {' '}
            {attachment &&
              attachment.map((img) => (
                <span className="span-container">
                  <a
                    className="attachment-link-color"
                    href={img.file_url}
                    target="blank"
                    dangerouslySetInnerHTML={{
                      __html: img.file_url
                        ? decodeURI(
                            img.file_url.split(decodeURI(Api.s3url))
                          ).substring(1)
                        : '-',
                    }}
                  />
                </span>
              ))}
          </>
        );
      },
    },
    {
      id: 'date',
      label: 'Date',
      roleAccess: [3, 4],
    },
    {
      id: 'formDate',
      label: 'From Date',
      roleAccess: [1, 2],
    },
    {
      id: 'toDate',
      label: 'To Date',
      roleAccess: [1, 2],
    },
    {
      id: 'count',
      label: 'Count',
      roleAccess: [3],
    },
    {
      id: 'status',
      label: 'Status',
      roleAccess: [4],
      render: ({ status, id }) => {
        return (
          <>
            <span
              className={getStatusColor(status, 'classes')}
            >
              {getStatusColor(status, 'label', id)}
            </span>
          </>
        );
      },
    },
    {
      id: 'action',
      label: 'Action',
      roleAccess: [1, 2, 3],
      render: handleActionCallback,
    },
  ];

  const getStatusColor = (status, key) => {
    const obj = statusData.filter((st) => {
      return Number(st.id) === Number(status);
    });
    if (!CustomFunctions.checkIfEmpty(obj, 'A')) {
      return obj[0][key];
    }
    return '';
  };

  const onInit = async() => {
    const uRole = CustomFunctions.getLocalStorage('role_id');
    const userid = CustomFunctions.getLocalStorage('id');
    if (Number(uRole) === 4){
      await getStudentClassIdByStudentId(userid);
    }
    if (!CustomFunctions.checkIfEmpty(uRole)) {
      setUserRole(Number(uRole));
    }

    if (!CustomFunctions.checkIfEmpty(userid)) {
      setUserId(Number(userid));
    }
    setTitle('Homework');
    getSubjectList();
    getYearList();
    getTutorList();
  };

  useEffect(() => {
    onInit();
  }, []);

  useEffect(() => {
    if (userRole) {
      getTableData(pagination.current_page);
    }
  }, [cntClearFilter, userRole, userId, classId]);

  const getStudentClassIdByStudentId = async (userid) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.studentUrl}${userid}/`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'O')) {
      setClassId(data.data.class);
    }
  };

  const getTableData = async (pageNo = 0) => {
    const cPage = pageNo || pagination.current_page;
    let endPoint = `${Api.getManageHomeworkByUrl}`;
    if (Number(userRole) === 4 && (!userId || !classId )) {
        return;
    }
    if (Number(userRole) === 4) {
      endPoint = `${Api.getTutorHomeworkStudentByTutorIdUrl}`;
    }
    const params = {
      page: cPage,
      limit: pagination.itemsPerPage,
      keyword: searchFormSubmit ? keyword : '',
      class_id: selectOptionYear && searchFormSubmit ? selectOptionYear.id : `${classId}`,
      subject_id:
        selectOptionSubject && searchFormSubmit ? selectOptionSubject.id : '',
      start_date:
        fromDate ? CustomFunctions.getYearDate(fromDate)
          : '',
      end_date:
        toDate  ? CustomFunctions.getYearDate(toDate) : '',
        tutor_id:
        selectOptionTutor && searchFormSubmit ? selectOptionTutor.id : '',
      sort_direction: 'descending',
      current_timezone: `${new Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      student_id: Number(userRole) === 4 ? userId : '',
    };

    const payload = CustomFunctions.cleanObject(params);

    const url = CustomFunctions.generateUrl(endPoint, payload);

    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      const row = [];
      await data.data.map((dt) => {
        const setRowData = {
          id: dt.id,
          homeworktitle: dt.title,
          year: dt.class_name,
          tutor: dt.tutor_name,
          subject: dt.subject_name,
          count: dt.count,
          attachment: dt.attachements,
          formDate: `${CustomFunctions.getDMYDate(dt.start_date)}`,
          toDate: `${CustomFunctions.getDMYDate(dt.end_date)}`,
          date:`${CustomFunctions.getDMYDate(dt.start_date)}-${CustomFunctions.getDMYDate(dt.end_date)}`,
          status: dt.status,
          role: userRole,
          isDelay: dt.is_delay
        };
        row.push(setRowData);
        return dt;
      });
      disableButton = false;
      setRows(row);
    } else {
      disableButton = false;
    }
  };

  const getSubjectList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.subjectUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setSubjectList(data.data);
    }
  };

  const getYearList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.schoolYearUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setYearList(data.data);
    }
  };

  const getTutorList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.tutorUrl}?type=all`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setTutorList(data.data);
    }
  };

  const getTutorData = () => {
    if (!tutorList) return [];
    const tutorOptions = [];
    tutorList.map((dt) => {
      return tutorOptions.push({
        label: dt.full_name,
        id: dt.id,
        value: dt.id,
      });
    });
    return tutorOptions;
  };

  const getSubjectData = () => {
    if (!subjectList) return [];
    const subjectOptions = [];
    subjectList &&
      subjectList.map((dt) => {
        return subjectOptions.push({
          label: dt.name,
          id: dt.id,
          value: dt.id,
        });
      });
    return subjectOptions;
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList &&
      yearList.map((mat) => {
        return yearOptions.push({
          label: mat.name,
          id: mat.id,
          value: mat.id,
        });
      });
    return yearOptions;
  };

  const deleteHomework = (id) => {
    if (id) {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure that you want to delete this record?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const { data, message } = await AuthApi.deleteDataFromServer(
            `${Api.getManageHomeworkByUrl}${id}/`
          );
          if (data) {
            swal(data && data.message[0], '', 'success', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
              buttons: false,
              timer: 2000,
            }).then(() => {
              swal.close();
              getTableData(pagination.current_page);
            });
          } else {
            swal(message, '', 'error', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
          }
        }
      });
    }
  };

  const onClear = () => {
    searchFormSubmit = false;
    setFilterErr('');
    setKeyword('');
    setToDate('');
    setFromDate('');
    setSelectOptionSubject(null);
    setSelectOptionYear(null);
    setSelectOptionTutor(null);
    if (disableButton) return;

    disableButton = true;

    if (timmer) clearTimeout(timmer);
    timmer = setTimeout(() => {
      setCntClearFilter(cntClearFilter + 1);
      disableButton = false;
    }, 1000);
  };

  const filterSearch = (e) => {
    e.preventDefault();

    let filterSearchErr = '';
    let isValid = true;
    if (disableButton) return;

    disableButton = true;

    if (
      CustomFunctions.checkIfEmpty(keyword) &&
      CustomFunctions.checkIfEmpty(selectOptionSubject) &&
      CustomFunctions.checkIfEmpty(selectOptionYear) &&
      CustomFunctions.checkIfEmpty(selectOptionTutor) &&
      CustomFunctions.checkIfEmpty(fromDate) &&
      CustomFunctions.checkIfEmpty(toDate)
    ) {
      searchFormSubmit = false;
      filterSearchErr = 'Please select atleast one filter';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
    }

    if (!CustomFunctions.checkIfEmpty(fromDate)) {
      if (CustomFunctions.checkIfEmpty(toDate)) {
        filterSearchErr = 'Please select To date';
        setFilterErr(filterSearchErr);
        isValid = false;
        disableButton = false;
      }
    } else if (!CustomFunctions.checkIfEmpty(toDate)) {
      filterSearchErr = 'Please select From date';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
    }

    if (isValid) {
      searchFormSubmit = true;
      setFilterErr('');
      if (timmer) clearTimeout(timmer);
      timmer = setTimeout(() => {
        getTableData(pagination.current_page);
      }, 1000);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Homework </h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-3">
          <Accordion defaultActiveKey="0">
          {userRole === 4 && (
            <>
            <Row>
            <Col md={6}>
            <Card className="access-homework-main">
                <Card.Body>
                  <div className="access-homework">
                    <h5>To Access Homework:</h5>
                    <ul>
                    <li>Click Homework Title</li>
                    <li>Read Tutor Instructions</li>
                    <li>Download and Print Attachment if required</li>
                    <li>Complete Homework on Paper</li>
                    <li>Scan (In Word : Preferable / PDF) and save on your Computer to Submit</li>
                    </ul>
                  </div>
                </Card.Body>
            </Card>
            </Col>
            <Col md={6}>
            <Card className="access-homework-main" style={{ height: '210px' }}>
                <Card.Body>
                  <div className="access-homework">
                    <h5>To Submit Homework:</h5>
                    <ul>
                        <li>Click Homework Title</li>
                        <li>Click Add Attachment</li>
                        <li>Upload Completed Homework</li>
                        <li>Add a message for your Tutor (optional)</li>
                        <li>Submit</li>
                    </ul>
                  </div>
                </Card.Body>
            </Card>
            </Col>
            </Row>
          </>
          )}
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="text-primary m-0">Filters</h4>

                  <div className="d-flex align-items-center justify-content-end">
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Expand
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Card.Title>
                    <Form onSubmit={(e) => filterSearch(e)}>
                      {filterErr && (
                        <div className="p-3  bg-danger text-white bg-danger-main table-red-bg">
                          <div className="img-text">
                            <img src="images/fail.svg" alt="Success" />{' '}
                            {filterErr}
                          </div>
                        </div>
                      )}
                      <Row className="mt-3">
                        <Col sm={userRole === 4 ? 6 : 4}>
                          <div className="search-bar-input">
                            <TextInput
                              value={keyword}
                              placeholder="Search keyword"
                              onInputChange={(e) => {
                                setFilterErr('');
                                setKeyword(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={userRole === 4 ? 6 : 4}>
                          <DropdownInput
                            placeholder="Select Subject"
                            options={getSubjectData()}
                            inputClass="mx-0"
                            defaultValue={selectOptionSubject}
                            onSelectHandler={(select) => {
                              setFilterErr('');
                              setSelectOptionSubject(select);
                            }}
                          />
                        </Col>
                        <Col sm={4}>
                          <UserRestrictions
                            permittedUsers={[1, 2, 3]}
                            roleId={userRole}
                          >
                            <DropdownInput
                              placeholder="Select School Year"
                              options={getYearData()}
                              defaultValue={selectOptionYear}
                              onSelectHandler={(select) => {
                                setFilterErr('');
                                setSelectOptionYear(select);
                              }}
                            />
                          </UserRestrictions>
                        </Col>
                      </Row>
                      <Row>
                        <UserRestrictions
                          permittedUsers={[1, 2, 4]}
                          roleId={userRole}
                        >
                          <Col sm={4}>
                            <DateTimePicker
                              label="From"
                              className="form-control"
                              selected={fromDate}
                              placeholder="DD-MM-YYYY"
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => {
                                setFilterErr('');
                                setFromDate(e);
                              }}
                            />
                          </Col>
                          <Col sm={4}>
                            <DateTimePicker
                              className="form-control"
                              label="To"
                              selected={toDate}
                              placeholder="DD-MM-YYYY"
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => {
                                setFilterErr('');
                                setToDate(e);
                              }}
                              minDate={fromDate}
                            />
                          </Col>
                          <Col sm={4}>
                            <DropdownInput
                              placeholder="Select Tutor Name"
                              options={getTutorData()}
                              inputClass="mt-4"
                              defaultValue={selectOptionTutor}
                              onSelectHandler={(select) => {
                                setFilterErr('');
                                setSelectOptionTutor(select);
                              }}
                            />
                          </Col>
                        </UserRestrictions>
                        <div className="filter-btn filter-clear-btn separate-submit-clear-btn">
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={disableButton}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="primary"
                            type="button"
                            className="ms-4"
                            onClick={() => onClear()}
                          >
                            Clear
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </Card.Title>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary mt-2 mb-2">Homework <small className='text-primary h6'><i>(Note : <Image src="/images/red-flag.gif" /> - OVERDUE )</i></small></h4>

                <div className="d-flex align-items-center justify-content-end">
                  <UserRestrictions
                    permittedUsers={[1, 2, 3]}
                    roleId={userRole}
                  >
                    <a
                      href=""
                      onClick={() => history.push('/managehomework/add')}
                      className="btn-link"
                    >
                      Add Homework
                    </a>
                  </UserRestrictions>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="mt-3">
                  <Table
                    role={Number(userRole)}
                    columns={columns}
                    rows={rows}
                    tableClass="table-striped"
                    showPagination
                    paginationProps={{
                      ...pagination,
                      activePage: pagination.current_page,
                      onPageChange: (pageNo) => {
                        searchFormSubmit = true; 
                        getTableData(pageNo);
                      },
                    }}
                    dropdownContainerClass="mb-3"
                    emptyRender={() => (
                      <p className="text-center mb-0">No record found</p>
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
export default withRouter(ManageHomework);
