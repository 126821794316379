import React, { Component } from 'react'; // eslint-disable-line
import { AES } from 'crypto-js';
import AuthApi from './authApi';

class CustomFunctions extends Component {
  getDate = (dateTime) => {
    const newdate = new Date(dateTime);
    const d = newdate.getDate();
    const m = newdate.getMonth() + 1;
    const y = newdate.getFullYear();
    const formattedate =
      (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y; //eslint-disable-line
    return formattedate;
  };

  getDMYDate = (dateTime) => {
    const newdate = new Date(dateTime);
    const d = newdate.getDate();
    const m = newdate.getMonth() + 1;
    const y = newdate.getFullYear();
    const formattedate =
      (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y; //eslint-disable-line
    return formattedate;
  };

  getMonthDate = (dateTime) => {
    const newdate = new Date(dateTime);
    const d = newdate.getDate();
    const m = newdate.getMonth() + 1;
    const y = newdate.getFullYear();
    const formattedate =
      (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + '-' + y; //eslint-disable-line
    return formattedate;
  };

  getYearDate = (dateTime) => {
    const newdate = new Date(dateTime);
    const d = newdate.getDate();
    const m = newdate.getMonth() + 1;
    const y = newdate.getFullYear();
    const formattedate =
      y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d); //eslint-disable-line
    return formattedate;
  };

  getTime = (dateTime) => {
    const newdate = new Date(dateTime);
    const h = newdate.getHours();
    const min = newdate.getMinutes();
    const formattedate = h + ':' + min; //eslint-disable-line
    return formattedate;
  };

  validateEmail = (email) => {
    var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return emailReg.test(email);
  };

  validatePhone = (phone) => {
    var phoneformat = /^\d{10}$/; // eslint-disable-line
    return phone.match(phoneformat);
  };

  validatePrice = (price) => {
    var priceformat = /^\d{1,8}(\.\d{0,2})?$/g; // eslint-disable-line
    return price.match(priceformat);
  };

  loadScript = async (src) => {
    const ele = document.getElementById(src);
    if (ele) {
      ele.remove();
    }
    const script = document.createElement('script');
    script.id = src;
    script.src = src;
    script.type = 'text/javascript';
    script.async = false;
    await document.body.appendChild(script);
  };

  getUserData = async () => {
    try {
      const userdata = this.getLocalStorage('userdata');
      const decodedData = this.jsonParse(userdata);
      return decodedData;
    } catch (err) {
      return null;
    }
  };

  validateAmount = (amount) => {
    const amountFormat = /^[1-9]\d{0,8}(((,\d{3}){1})?(\.\d{0,2})?)$/;
    return amountFormat.test(amount);
  };

  localStorageClear = () => {
    this.removeLocalStorage('access_token');
    this.removeLocalStorage('checkbox');
    this.removeLocalStorage('email');
    this.removeLocalStorage('password');
    this.removeLocalStorage('role_id');
    this.removeLocalStorage('id');
    this.removeLocalStorage('ulinfo');
    this.removeLocalStorage('masquerade');
    this.removeLocalStorage('homeworkId');
  };

  clearLocalStorage = () => {
    this.removeLocalStorage('access_token');
    this.removeLocalStorage('id');
    this.removeLocalStorage('role_id');
    this.removeLocalStorage('ulinfo');
    this.removeLocalStorage('masquerade');
    this.removeLocalStorage('homeworkId');
  };

  checkIfEmpty = (value, type = 'default') => {
    switch (type) {
      case 'A':
        return !value || (value && !value.length);
      case 'O':
        return !value || !Object.keys(value).length;
      default:
        return !value;
    }
  };

  cleanObject = (obj) => {
    const cleanedObject = Object.entries(obj).reduce(
      (a, [k, v]) => (v || v === false || v === 0 ? ((a[k] = v), a) : a), // eslint-disable-line
      {}
    );
    return cleanedObject;
  };

  userRestrictions = (element, permittedUsers = [], isLink = false) => {
    const roleId = this.getLocalStorage('role_id');
    const hasPermissions =
      permittedUsers &&
      permittedUsers.length &&
      permittedUsers.includes(Number(roleId));
    if (!hasPermissions) return isLink ? 'javascript:void(0)' : '';
    return element;
  };

  hasUserRestrictions = (permittedUsers = []) => {
    const roleId = this.getLocalStorage('role_id');
    const hasPermissions =
      permittedUsers &&
      permittedUsers.length &&
      permittedUsers.includes(Number(roleId));
    return hasPermissions;
  };

  generateUrl = (url, urlParams = {}) => {
    const searchParams = new URLSearchParams(
      this.cleanObject(urlParams)
    ).toString();
    let apiEndpoint = url;
    if (!this.checkIfEmpty(urlParams, 'O'))
      apiEndpoint = `${apiEndpoint}?${searchParams}`;
    return apiEndpoint;
  };

  /**
   * to bulk delete record which will call prepare url and call get api
   * @param {*} url
   * @param {*} urlParams
   * @param {*} successCallback
   * @param {*} errorCallback
   * @returns callback success and error
   */
  deleteBulkRecord = async (
    url,
    urlParams = {},
    type = 'get',
    successCallback = null,
    errorCallback = null
  ) => {
    let buildUrl = url;
    let params = {};
    let callApi = null;
    try {
      switch (type.toLowerCase()) {
        case 'put': {
          const filterParam = this.cleanObject(urlParams);
          params = { buildUrl, filterParam };
          callApi = AuthApi.putDataToServer;
          break;
        }
        case 'post': {
          const filterPostParam = this.cleanObject(urlParams);
          params = { buildUrl, filterPostParam };
          callApi = AuthApi.postDataToServer;
          break;
        }
        case 'get':
        default: {
          callApi = AuthApi.deleteDataFromServer;
          if (!this.checkIfEmpty(urlParams, 'O'))
            buildUrl = this.generateUrl(buildUrl, urlParams);

          params = { buildUrl };
        }
      }
      const { data, message } = await callApi(...Object.values(params));
      if (data && !data.success) {
        if (errorCallback) errorCallback(message);
        return;
      }
      if (successCallback) successCallback(data);
    } catch (e) {
      if (errorCallback) errorCallback(e);
    }
  };
  /**
   * to change status by using put api call
   * @param {*} url
   * @param {*} urlParams
   * @param {*} successCallback
   * @param {*} errorCallback
   * @returns callback success or error
   */

  changeStatus = async (
    url,
    urlParams = {},
    type = 'put',
    successCallback = null,
    errorCallback = null
  ) => {
    try {
      if (this.checkIfEmpty(urlParams, 'O')) return false;

      const apiCall =
        type.toLowerCase() === 'put'
          ? AuthApi.putDataToServer
          : AuthApi.postDataToServer;
      const params = this.cleanObject(urlParams);
      const { data, message } = await apiCall(url, params);
      if (data && !data.success) {
        if (errorCallback) errorCallback(message);
        return data;
      }
      if (successCallback) successCallback(data);

      return data;
    } catch (e) {
      if (errorCallback) errorCallback(e);

      return e;
    }
  };

  stringify = (data) => {
    try {
      return JSON.stringify(data);
    } catch (error) {
      return '';
    }
  };

  setLocalStorage = (key, value) => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (error) {
      return false;
    }
  };

  getLocalStorage = (key) => {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return false;
    }
  };

  removeLocalStorage = (key) => {
    try {
      if (typeof key === 'string') return localStorage.removeItem(key);

      if (typeof key === 'object') {
        key.map((item) => {
          return this.removeLocalStorage(item);
        });
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  formatDate = (dt, seperator = '-', format = 'ymd') => {
    const dateObj = new Date(dt);
    let month = dateObj.getUTCMonth() + 1; // months from 1-12
    month = month < 10 ? `0${month}` : month;
    let day = dateObj.getDate();
    day = day < 10 ? `0${day}` : day;
    const year = dateObj.getUTCFullYear();
    let newdate;
    switch (format) {
      case 'ymd':
        newdate = year + seperator + month + seperator + day;
        break;
      case 'dmy':
        newdate = day + seperator + month + seperator + year;
        break;
      case 'mdy':
        newdate = month + seperator + day + seperator + year;
        break;
      case 'dM':
        newdate =
          day +
          seperator +
          dateObj.toLocaleString('default', { month: 'short' });
        break;
      default:
        newdate = year + seperator + month + seperator + day;
        break;
    }
    return newdate;
  };

  formatSimpleDate = (dt) => {
    let dtFormated = dt ? dt.replaceAll('/', '-') : '';
    if (dtFormated) {
      dtFormated = dtFormated.split('-');
      if (dtFormated.length > 0)
        dtFormated = new Date(
          `${dtFormated[2]}-${dtFormated[1]}-${dtFormated[0]}`
        );
    }
    return dtFormated;
  };

  encryptKey = (value, key) => {
    try {
      return AES.encrypt(value, key);
    } catch (error) {
      return false;
    }
  };

  getAESKey = () => {
    return 'peepalTreePortal';
  };

  decryptKey = (value, key) => {
    try {
      return AES.decrypt(value, key);
    } catch (error) {
      return false;
    }
  };

  switchUser = async (url, id, redirectUrl = '/', errorCallback = null) => {
    try {
      const masquerade = this.getLocalStorage('masquerade');
      let callApi = null;
      if (!id) {
        if (errorCallback) {
          errorCallback({ mesaage: ['user id required'] });
        }
        return;
      }

      callApi = AuthApi.getDataFromServer;
      const { data, message } = await callApi(`${url}${id}/`);
      if (data && !data.success) {
        if (errorCallback) errorCallback(message);
        return;
      }

      if (data && data.data) {
        const previousUserInfo = this.getLocalStorage('ulinfo');
        this.setLocalStorage('access_token', data.data.token.access_token);
        this.setLocalStorage('id', data.data.id);
        this.setLocalStorage('role_id', data.data.role_id);
        this.setLocalStorage('ulinfo', JSON.stringify(data.data));
        if (masquerade) {
          const adminRedirectUrl = this.getLocalStorage('masqueradeRedirect');
          this.removeLocalStorage('masquerade');
          this.removeLocalStorage('masqueradeRedirect');
          window.location.href = adminRedirectUrl;
        } else {
          this.setLocalStorage('masqueradeRedirect', redirectUrl);
          this.setLocalStorage('masquerade', previousUserInfo);
          window.location.href = '/';
        }
      }
    } catch (e) {
      if (errorCallback) errorCallback(e);
    }
  };

  checkUserSwitch = () => {
    const masquerade = this.getLocalStorage('masquerade');
    if (masquerade) return JSON.parse(masquerade);

    return false;
  };

  loginUser = async (
    url,
    username,
    password,
    redirectUrl = '/',
    successCallback = null,
    errorCallback = null
  ) => {
    const postData = {
      username,
      password,
    };

    const { data, message } = await AuthApi.postDataToServer(url, postData);

    if (data && data.data && data.data.token && data.data.token.access_token) {
      this.setLocalStorage('access_token', data.data.token.access_token);

      const envryptedPassword = this.encryptKey(password, this.getAESKey());
      const envryptedUsername = this.encryptKey(
        data.data.email,
        this.getAESKey()
      );

      this.setLocalStorage('access_token', data.data.token.access_token);
      this.setLocalStorage('email', envryptedUsername.toString());
      this.setLocalStorage('id', data.data.id);
      this.setLocalStorage('role_id', data.data.role_id);
      this.setLocalStorage('password', envryptedPassword.toString());
      this.setLocalStorage('ulinfo', JSON.stringify(data.data));
      this.removeLocalStorage('masquerade');
      this.removeLocalStorage('masqueradeRedirect');
      const timer = setInterval(async () => {
        const token = this.getLocalStorage('access_token');
        if (token) {
          clearInterval(timer);
          if (successCallback) {
            successCallback(data.data);
            return false;
          }

          window.location.href = redirectUrl;
        }
        return true;
      }, 1000);
    } else {
      if (errorCallback) errorCallback(message); // eslint-disable-line
    }
    return true;
  };

  ucFirst = (str = '') => {
    if (this.checkIfEmpty(str)) return str;
    return str.length > 1
      ? str.charAt(0).toUpperCase() + str.slice(1)
      : str.charAt(0).toUpperCase();
  };

  getBackYearDate = () =>{
    const d = new Date();
    const pastYear = d.getFullYear() - 1;
    d.setFullYear(pastYear);
    return this.formatDate(d);
  }

  formatSimpleDateTime = (dateTime) => {
    const newdate = new Date(dateTime);
    const d = newdate.getDate();
    const m = newdate.getMonth() + 1;
    const y = newdate.getFullYear();
    const formattedate = `${y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) +' '+ '00:00:00'}`; //eslint-disable-line
    return formattedate;
  };
}

export default new CustomFunctions();
