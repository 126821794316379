import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  Accordion,
  Form,
  Button,
  Row,
  Col,
  Card,
  Image,
} from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import CustomFunctions from '../../../helper/customFunctions';
import Table from '../../../components/Table/simpletable.component';
import {
  TextInput,
  DropdownInput,
  DateTimePicker,
  Helmet,
} from '../../../components/FormInputs';
import UserRestrictions from '../../../components/UserRestrictions/userrestrictions.component';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb.component';

const items = [
  { to: '/', label: 'Home' },
  { to: '/virtualclasses', label: 'Virtual Sessions' },
];

const VirtualClasses = () => {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [subjectList, setSubjectList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [selectOptionSubject, setSelectOptionSubject] = useState(null);
  const [selectOptionYear, setSelectOptionYear] = useState(null);
  const [cntClearFilter, setCntClearFilter] = useState(0);
  const [userRole, setUserRole] = useState('');
  const [filterErr, setFilterErr] = useState('');
  const [title, setTitle] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [classId, setClassId] = useState('');
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });

  let timmer = null;
  let disableButton = false;
  let searchFormSubmit = false;

  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [1, 2, 3],
      isDisplay: false,
    },
    {
      id: 'sessiontitle',
      label: 'Session Title',
      roleAccess: [1, 2, 3, 4],
      render: ({ sessiontitle, joinNowLink, id, sessionId }) => {
        return (
          <>
            <span className="span-container">
              {sessiontitle}
              {joinNowLink && (
                  <Button
                    variant="primary"
                    type="button"
                    className="ms-4 btn-info"
                    onClick={() => getJoinNowLink((Number(userRole) === 4) ? sessionId : id)}
                  >
                    Join Now
                  </Button>
              )}
            </span>
          </>
        );
      },
    },
    {
      id: 'year',
      label: 'School Year',
      roleAccess: [1, 2, 3],
    },
    {
      id: 'subject',
      label: 'Subject',
      roleAccess: [1, 2, 3, 4],
    },
    {
      id: 'tutorName',
      label: 'Tutor Name',
      roleAccess: [1,2,4],
    },
    {
      id: 'date',
      label: 'Date',
      roleAccess: [1, 2, 3, 4],
    },
    {
      id: 'time',
      label: 'Time',
      roleAccess: [1, 2, 3, 4],
    },
    {
      id: 'attachment',
      label: 'Attachment',
      roleAccess: [1, 2, 3, 4],
      render: ({ attachment }) => {
        return (
          <>
            {' '}
            {attachment &&
              attachment.map((img) => (
                <span className="span-container">
                  <a
                    href={img.file_url}
                    target="blank"
                    className="text-info attachment-link-color"
                    dangerouslySetInnerHTML={{
                      __html: img.file_url
                        ? decodeURI(
                            img.file_url.split(decodeURI(Api.s3url))
                          ).substring(1)
                        : '-',
                    }}
                  />
                </span>
              ))}
          </>
        );
      },
    },
    {
      id: 'attendance',
      label: 'Attendance',
      roleAccess: [1, 2, 3, 4],
      render: ({
        actualAttendee,
        totalAttendee,
        userrole,
        attendanceReport,
      }) => {
        return (
          <>
            {Number(userrole) === 4 && attendanceReport}
            {Number(userrole) !== 4 &&
              (actualAttendee && totalAttendee
                ? `${actualAttendee} / ${totalAttendee}`
                : '---')}
          </>
        );
      },
    },
    {
      id: 'count',
      label: 'Student Count',
      roleAccess: [1, 2, 3],
      render: ({ count }) => {
        return <>{count}</>;
      },
    },
    {
      id: 'action',
      label: 'Action',
      roleAccess: [1, 2, 3],
      render: ({ id }) => {
        return (
          <>
            <a
              href="/"
              className="p-r-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`/virtualclasses/edit/${id}`);
              }}
            >
              <Image src="/images/edit-button.svg" alt="Edit Tutor" />
            </a>
            <a
              href="/"
              className="p-r-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                deleteVirtualClass(id);
              }}
            >
              <Image src="images/delete.png" />
            </a>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    async function getData() {
        setTitle('Virtual Classes');
        const uRole = CustomFunctions.getLocalStorage('role_id');
        const userId = CustomFunctions.getLocalStorage('id');
        if (Number(uRole) === 4){
          await getStudentClassIdByStudentId(userId);
        }
        if (!CustomFunctions.checkIfEmpty(uRole)) {
          setUserRole(Number(uRole));
        }
        getSubjectList();
        getYearList();
    }
    getData();
  }, []);

  useEffect(() => {
    if (userRole) getTableData(1);
  }, [cntClearFilter, userRole, classId]);

  const getStudentClassIdByStudentId = async (userid) => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.studentUrl}${userid}/`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'O')) {
      setClassId(data.data.class);
    }
  };

  const getSubjectList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.subjectUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setSubjectList(data.data);
    }
  };

  const getYearList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.schoolYearUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setYearList(data.data);
    }
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList &&
      yearList.map((mat) => {
        return yearOptions.push({
          label: mat.name,
          id: mat.id,
          value: mat.id,
        });
      });
    return yearOptions;
  };

  const getSubjectData = () => {
    if (!subjectList) return [];
    const subjectOptions = [];
    subjectList &&
      subjectList.map((dt) => {
        return subjectOptions.push({
          label: dt.name,
          id: dt.id,
          value: dt.id,
        });
      });
    return subjectOptions;
  };

  const getTableData = async (pageNo = 0) => {
    const cPage = pageNo || pagination.current_page;
    let endPoint = `${Api.getVirtualClassByUrl}`;
    if (Number(userRole) === 4 &&  !classId) {
      endPoint = `${Api.virtualClassStudentUrl}`;
    }
    if (Number(userRole) === 4) {
      endPoint = `${Api.virtualClassStudentUrl}`;
    }
    const params = {
      page: cPage,
      limit: pagination.itemsPerPage,
      sort_direction: 'descending',
      sort_by: Number(userRole) === 4 ? 'session__start_time' : 'start_time',
      keyword: searchFormSubmit ? keyword : '',
      class_id: selectOptionYear && searchFormSubmit ? selectOptionYear.id : `${classId}`,
      subject_id:
        selectOptionSubject && searchFormSubmit ? selectOptionSubject.id : '',
      start_date:
        fromDate ? CustomFunctions.getYearDate(fromDate)
          : '',
      end_date:
        toDate ? CustomFunctions.getYearDate(toDate) : '',
    };
    const url = CustomFunctions.generateUrl(endPoint, params);
    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      const row = [];
      data.data.map((session) => {
        const setRowData = {
          id: session.id,
          sessionId: session.session_id,
          sessiontitle: session.title ? session.title : '-',
          year: session.class_name ? session.class_name : '-',
          subject: session.subject_name ? session.subject_name : '-',
          tutorName: session.tutor_name ? session.tutor_name : '-',
          date: session.start_time
            ? `${CustomFunctions.getDMYDate(
                session.start_time.split(' ')[0]
              )}`
            : '-',
          time: session.start_time.split(' ')[1],
          duration: session.duration ? session.duration : '-',
          attendance: session.attendance ? session.attendance : '-',
          attachment: session.attachements,
          joinNowLink: session.join_now,
          actualAttendee: session.actual_attendee,
          totalAttendee: session.total_attendee,
          count: session.count ? session.count : 0,
          userrole: Number(userRole),
          attendanceReport: session.attendance_report
            ? session.attendance_report
            : '',
        };
        row.push(setRowData);
        return session;
      });
      setRows(row);
    }
  };

  const filterSearch = (e) => {
    e.preventDefault();
    let filterSearchErr = '';
    let isValid = true;

    if (disableButton) return;

    disableButton = true;

    if (
      CustomFunctions.checkIfEmpty(keyword) &&
      CustomFunctions.checkIfEmpty(selectOptionSubject) &&
      CustomFunctions.checkIfEmpty(selectOptionYear) &&
      CustomFunctions.checkIfEmpty(fromDate) &&
      CustomFunctions.checkIfEmpty(toDate)
    ) {
      searchFormSubmit = false;
      filterSearchErr = 'Please select atleast one filter';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
    }

    if (!CustomFunctions.checkIfEmpty(fromDate)) {
      if (CustomFunctions.checkIfEmpty(toDate)) {
        searchFormSubmit = false;
        filterSearchErr = 'Please select To date';
        setFilterErr(filterSearchErr);
        isValid = false;
        disableButton = false;
      }
    } else if (!CustomFunctions.checkIfEmpty(toDate)) {
      filterSearchErr = 'Please select From date';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
      searchFormSubmit = false;
    }

    if (isValid) {
      searchFormSubmit = true;
      setFilterErr('');
      if (timmer) clearTimeout(timmer);

      timmer = setTimeout(() => {
        getTableData(1);
      }, 1000);
    }
  };

  const onClear = () => {
    searchFormSubmit = false;
    setFilterErr('');
    setKeyword('');
    setSelectOptionSubject(null);
    setSelectOptionYear(null);
    setToDate('');
    setFromDate('');
    if (disableButton) return;

    disableButton = true;

    if (timmer) clearTimeout(timmer);
    timmer = setTimeout(() => {
      setCntClearFilter(cntClearFilter + 1);
      disableButton = false;
    }, 1000);
  };

  const deleteVirtualClass = (id) => {
    if (id) {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure that you want to delete this record?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const { data, message } = await AuthApi.deleteDataFromServer(
            `${Api.deleteVirtualClassUrl}${id}/`
          );
          if (data) {
            swal(data && data.message[0], '', 'success', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
              buttons: false,
              timer: 2000,
            }).then(() => {
              swal.close();
              getTableData(1);
            });
          } else {
            swal(message, '', 'error', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
          }
        }
      });
    }
  };

  const getJoinNowLink = async (id) => {

    const { data } = await AuthApi.getDataFromServer(
      `${Api.joinNowLinkUrl}${id}/`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'O')) {
      // const isFirefox = typeof InstallTrigger !== 'undefined';
      const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
      //  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window.safari || (typeof window.safari !== 'undefined' && window.safari.pushNotification));

      if(data.success){
        if(isChrome === true) {
          const win =   window.open(data.data.join_url, '_blank');
          win.focus();
        } else {
          window.location.assign(data.data.join_url);
        }
      } else {
        swal(data.message[0], '', 'error', {
          closeOnClickOutside: false,
          allowOutsideClick: false,
        });
      }
    }
    return null;
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-0b-5">Virtual Sessions</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="text-primary m-0">Filters</h4>
                  <div className="d-flex align-items-center justify-content-end">
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Expand
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Card.Title>
                    <Form onSubmit={(e) => filterSearch(e)}>
                      {filterErr && (
                        <div className="p-3  bg-danger text-white bg-danger-main table-red-bg">
                          <div className="img-text">
                            <img src="/images/fail.svg" alt="Success" />{' '}
                            {filterErr}
                          </div>
                        </div>
                      )}
                      <Row className="mt-3">
                        <Col sm={userRole === 4 ? 6 : 4}>
                          <div className="search-bar-input">
                            <TextInput
                              value={keyword}
                              placeholder="Search keyword"
                              onInputChange={(e) => {
                                setFilterErr('');
                                setKeyword(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={userRole === 4 ? 6 : 4}>
                          <DropdownInput
                            placeholder="Select Subject"
                            options={getSubjectData()}
                            defaultValue={selectOptionSubject}
                            onSelectHandler={(select) => {
                              setFilterErr('');
                              setSelectOptionSubject(select);
                            }}
                          />
                        </Col>
                        <UserRestrictions
                          permittedUsers={[1, 2, 3]}
                          roleId={userRole}
                        >
                          <Col sm={4}>
                            <DropdownInput
                              placeholder="Select School Year"
                              options={getYearData()}
                              defaultValue={selectOptionYear}
                              onSelectHandler={(select) => {
                                setFilterErr('');
                                setSelectOptionYear(select);
                              }}
                            />
                          </Col>
                        </UserRestrictions>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <DateTimePicker
                            label="From"
                            className="form-control"
                            selected={fromDate}
                            placeholder="DD-MM-YYYY"
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => {
                              setFilterErr('');
                              setFromDate(e);
                            }}
                          />
                        </Col>
                        <Col sm={4}>
                          <DateTimePicker
                            className="form-control"
                            label="To"
                            selected={toDate}
                            placeholder="DD-MM-YYYY"
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => {
                              setFilterErr('');
                              setToDate(e);
                            }}
                            minDate={fromDate}
                          />
                        </Col>
                        <Col sm={4}>
                          <div className="filter-btn filter-clear-btn separate-submit-clear-btn mt-4">
                            <Button
                              variant="primary"
                              type="submit"
                              className="ms-4"
                              disabled={disableButton}
                            >
                              Submit
                            </Button>
                            <Button
                              variant="primary"
                              type="button"
                              className="ms-4"
                              onClick={() => onClear()}
                            >
                              Clear
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Title>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary mt-2 mb-2">Virtual Classes</h4>
                <div className="d-flex align-items-center justify-content-end">
                  <UserRestrictions
                    permittedUsers={[1, 2, 3]}
                    roleId={userRole}
                  >
                    <a
                      href=""
                      onClick={() => history.push('/virtualclasses/add')}
                      className="btn-link"
                    >
                      Add Classes
                    </a>
                  </UserRestrictions>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="mt-3">
                  <Table
                    role={Number(userRole)}
                    columns={columns}
                    rows={rows}
                    showPagination
                    paginationProps={{
                      ...pagination,
                      activePage: pagination.current_page,
                      onPageChange: (pageNo) => {
                        getTableData(pageNo);
                      },
                    }}
                    tableClass="table-striped"
                    dropdownContainerClass="mb-3"
                    emptyRender={() => (
                      <p className="text-center mb-0">No record found</p>
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default withRouter(VirtualClasses);
