import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col, Card, Accordion, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import Table from '../../components/Table/simpletable.component';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb.component';
import CustomFunctions from '../../helper/customFunctions';
import UserRestrictions from '../../components/UserRestrictions/userrestrictions.component';

import {
  TextInput,
  DropdownInput,
  DateTimePicker,
  Helmet,
} from '../../components/FormInputs';

const items = [
  { to: '/', label: 'Home' },
  { to: '/student', label: 'Students' },
];

const Student = (props) => {
  const [rows, setRows] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selOptionYear, setSelOptionYear] = useState(null);
  const [selOptionSubject, setSelOptionSubject] = useState(null);
  const [selOptionTutor, setSelOptionTutor] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [tutorList, setTutorList] = useState([]);
  const [activationDate, setActivationDate] = useState(null);
  const [cntClearFilter, setCntClearFilter] = useState(0);
  const [pagination, setPagination] = useState({
    current_page: 1,
    itemsPerPage: 10,
    totalItems: 0,
  });
  const [filterErr, setFilterErr] = useState('');
  const [title, setTitle] = useState('');
  const [userRole, setUserRole] = useState('');
  let timmer = null;
  let disableButton = false;
  let searchFormSubmit = false;

  useEffect(() => {
    setTitle('All Students');
    getSubjectList();
    getYearList();
    getTutorList();
    const uRole = CustomFunctions.getLocalStorage('role_id');
    if (!CustomFunctions.checkIfEmpty(uRole)) {
      setUserRole(uRole ? Number(uRole) : '');
    }
  }, []);

  useEffect(() => {
    getTableData(pagination.current_page);
  }, [cntClearFilter]);

  const handleActionCallback = ({ status, id, archiveStatus }) => {
    return (
      <>
        <UserRestrictions permittedUsers={[1, 2]} roleId={userRole}>
          { (status === 1) && (
            <a
              href="/"
              className="p-r-2"
              title="Switch User"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                switchAccount(id);
              }}
            >
              <img
                src="/images/login-button_gray.svg"
                width="18px"
                height="21px"
                alt="Switch Account"
              />
            </a>
          )}
        </UserRestrictions>
        <a
          href="/"
          className="p-r-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.history.push(`student/addStudent/${id}`);
          }}
        >
          <img src="/images/edit-button.svg" alt="Edit Student" />
        </a>
        <a
          href="/"
          className="p-r-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            deleteStudent(id);
          }}
        >
          <img src="/images/delete.png" alt="Delete Student" />
        </a>
        {archiveStatus === true &&(
         <>
         <a
          href="/"
          className="p-r-2"
          title="View Academic History"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.history.push(`student/studentAcademicDetails/${id}`);
          }}
        >
          <img src="/images/archive.svg" alt="Academic History" />
        </a>
        </>
        )}
      </>
    );
  };

  const columns = [
    {
      id: 'id',
      label: 'Id',
      roleAccess: [1, 2, 3],
      isDisplay: false,
    },
    {
      id: 'student_name',
      label: 'Name',
      roleAccess: [1, 2, 3],
      render: ({ id, student_name: studentName }) => {
        return (
          <>
            <a
              href="/"
              className="p-r-2 font-weight-semibold"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (userRole === 2 || userRole === 1) {
                  props.history.push(`student/studentDetails/${id}`);
                } else {
                  props.history.push(`student/studentHomeworkDetails/${id}`);
                }
              }}
            >
              {studentName}
            </a>
          </>
        );
      },
    },
    {
      id: 'class_name',
      label: 'Year',
      roleAccess: [1, 2, 3],
    },
    {
      id: 'subject',
      label: 'Subject',
      roleAccess: [1, 2, 3],
    },
    {
      id: 'tutors_name',
      label: 'Tutors',
      roleAccess: [1, 2, 4],
    },
    {
      id: 'activation_date',
      label: 'Activation Date',
      roleAccess: [1, 2, 4],
    },
    {
      id: 'status',
      label: 'Status',
      roleAccess: [1, 2, 4],
      render: ({ status }) => {
        return (
          <>
            <img
              src={
                status === 1 ? '/images/yes.png' : './images/no-stopping.png'
              }
              alt="Download"
            />
          </>
        );
      },
    },
    {
      id: 'action',
      label: 'Action',
      roleAccess: [1, 2, 4],
      render: handleActionCallback,
    },
  ];

  const getSubjectList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.subjectUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setSubjectList(data.data);
    }
  };

  const getYearList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.schoolYearUrl}?status=1&type=all&sort_direction=descending`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setYearList(data.data);
    }
  };

  const getTutorList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.tutorUrl}?type=all`
    );
    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setTutorList(data.data);
    }
  };

  const getTutorData = () => {
    if (!tutorList) return [];
    const tutorOptions = [];
    tutorList.map((dt) => {
      return tutorOptions.push({
        label: dt.full_name,
        id: dt.id,
        value: dt.id,
      });
    });
    return tutorOptions;
  };

  const getSubjectData = () => {
    if (!subjectList) return [];
    const subjectOptions = [];
    subjectList.map((dt) => {
      return subjectOptions.push({
        label: dt.name,
        id: dt.id,
        value: dt.id,
      });
    });
    return subjectOptions;
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList.map((mat) => {
      return yearOptions.push({
        label: mat.name,
        id: mat.id,
        value: mat.id,
      });
    });
    return yearOptions;
  };

  const onClear = () => {
    searchFormSubmit = false;
    setFilterErr('');
    setSearchKeyword('');
    setSelOptionYear(null);
    setSelOptionSubject(null);
    setSelOptionTutor(null);
    setActivationDate('');
    if (disableButton) return;

    disableButton = true;

    if (timmer) clearTimeout(timmer);
    timmer = setTimeout(() => {
      setCntClearFilter(cntClearFilter + 1);
      disableButton = false;
    }, 1000);
  };

  const getTableData = async (pageNo = 0) => {
    const cPage = pageNo || pagination.current_page;

    const endPoint = `${Api.studentUrl}`;

    const params = {
      page: cPage,
      limit: pagination.itemsPerPage,
      sort_direction: 'descending',
      sort_by: 'student__activation_date',
      keyword: searchFormSubmit ? searchKeyword : '',
      class_id: selOptionYear && searchFormSubmit ? selOptionYear.id : '',
      subject_id:
        selOptionSubject && searchFormSubmit ? selOptionSubject.id : '',
      tutor_id: selOptionTutor && searchFormSubmit ? selOptionTutor.id : '',
      activation_date:
        activationDate && searchFormSubmit
          ? CustomFunctions.getYearDate(activationDate)
          : '',
    };
    const url = CustomFunctions.generateUrl(endPoint, params);
    const { data } = await AuthApi.getDataFromServer(url);
    if (!CustomFunctions.checkIfEmpty(data, 'O')) {
      setPagination({
        current_page: Number(data.paginator.current_page),
        activePage: Number(data.paginator.current_page),
        itemsPerPage: data.paginator.limit,
        totalItems: data.paginator.total_count,
      });
      const row = [];
      data.data.map((student) => {
        const setRowData = {
          id: student.id,
          student_name: student.full_name ? student.full_name : '-',
          class_name: student.class_name ? student.class_name : '-',
          activation_date: student.activation_date
            ? CustomFunctions.getDate(student.activation_date)
            : '-',
          subject: student.subjects_name ? student.subjects_name : '-',
          tutors_name: student.tutors_name ? student.tutors_name : '-',
          status: student.status,
          archiveStatus: student.is_archive
        };
        row.push(setRowData);
        return student;
      });
      disableButton = false;
      setRows(row);
    } else {
      disableButton = false;
    }
  };

  const deleteStudent = (id) => {
    if (id) {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure that you want to delete this record?',
        icon: 'warning',
        dangerMode: false,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const { data, message } = await AuthApi.deleteDataFromServer(
            `${Api.studentUrl}${id}/`
          );

          if (data) {
            swal(data && data.message[0], '', 'success', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
              buttons: false,
              timer: 2000,
            }).then(() => {
              swal.close();
              getTableData(pagination.current_page);
            });
          } else {
            swal(message, '', 'error', {
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
          }
        }
      });
    }
  };

  const bulkActions = [
    {
      id: 'delete',
      actionTitle: 'Delete',
      actionCallback: (selectedIndexes) => {
        const endPoint = `${Api.studentBulkStatus}`;
        if (!CustomFunctions.checkIfEmpty(selectedIndexes, 'A')) {
          const params = { id: selectedIndexes, status: 3 };
          swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete this record?',
            icon: 'warning',
            dangerMode: true,
            buttons: true,
            closeOnClickOutside: false,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result) {
              const successHandle = (e) => {
                swal(e && e.message[0], '', 'success', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                  buttons: false,
                  timer: 2000,
                }).then(() => {
                  swal.close();
                  getTableData(pagination.current_page);
                });
              };
              const errorHandle = (e) => {
                swal(e, '', 'error', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                });
              };
              await CustomFunctions.changeStatus(
                endPoint,
                params,
                'PUT',
                successHandle,
                errorHandle
              );
            }
          });
        }
      },
    },
    {
      id: 'active',
      actionTitle: 'Active',
      actionCallback: (selectedIndexes) => {
        const endPoint = `${Api.studentBulkStatus}`;
        if (!CustomFunctions.checkIfEmpty(selectedIndexes, 'A')) {
          const params = { id: selectedIndexes, status: 1 };
          swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to update this record?',
            icon: 'warning',
            dangerMode: true,
            buttons: true,
            closeOnClickOutside: false,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result) {
              const successHandle = (e) => {
                swal(e && e.message[0], '', 'success', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                  buttons: false,
                  timer: 2000,
                }).then(() => {
                  swal.close();
                  getTableData(pagination.current_page);
                });
              };
              const errorHandle = (e) => {
                swal(e, '', 'error', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                });
              };
              await CustomFunctions.changeStatus(
                endPoint,
                params,
                'PUT',
                successHandle,
                errorHandle
              );
            }
          });
        }
      },
    },
    {
      id: 'inactive',
      actionTitle: 'Inactive',
      actionCallback: (selectedIndexes) => {
        const endPoint = `${Api.studentBulkStatus}`;
        if (!CustomFunctions.checkIfEmpty(selectedIndexes, 'A')) {
          const params = { id: selectedIndexes, status: 2 };
          swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to update this record?',
            icon: 'warning',
            dangerMode: true,
            buttons: true,
            closeOnClickOutside: false,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result) {
              const successHandle = (e) => {
                swal(e && e.message[0], '', 'success', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                  buttons: false,
                  timer: 2000,
                }).then(() => {
                  swal.close();
                  getTableData(pagination.current_page);
                });
              };
              const errorHandle = (e) => {
                swal(e, '', 'error', {
                  closeOnClickOutside: false,
                  allowOutsideClick: false,
                });
              };
              await CustomFunctions.changeStatus(
                endPoint,
                params,
                'PUT',
                successHandle,
                errorHandle
              );
            }
          });
        }
      },
    },
  ];

  const filterSearch = (e) => {
    e.preventDefault();
    let filterSearchErr = '';
    let isValid = true;
    if (disableButton) return;
    disableButton = true;
    if (
      CustomFunctions.checkIfEmpty(searchKeyword) &&
      CustomFunctions.checkIfEmpty(selOptionYear) &&
      CustomFunctions.checkIfEmpty(selOptionSubject) &&
      CustomFunctions.checkIfEmpty(activationDate) &&
      CustomFunctions.checkIfEmpty(selOptionTutor)
    ) {
      searchFormSubmit = false;
      filterSearchErr = 'Please select atleast one filter';
      setFilterErr(filterSearchErr);
      isValid = false;
      disableButton = false;
    }

    if (!CustomFunctions.checkIfEmpty(isValid)) {
      searchFormSubmit = true;
      setFilterErr('');
      if (timmer) clearTimeout(timmer);

      timmer = setTimeout(() => {
        getTableData(pagination.current_page);
      }, 1000);
    }
  };

  const switchAccount = (uid) => {
    CustomFunctions.switchUser(
      Api.userImpersonateUrl,
      uid,
      window.location.pathname,
      (e) => {
        swal(e, '', 'error');
      }
    );
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Students</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="text-primary m-0">Filters</h4>
                  <div className="d-flex align-items-center justify-content-end">
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Expand
                    </Accordion.Toggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Card.Title>
                    <Form onSubmit={(e) => filterSearch(e)}>
                      {filterErr && (
                        <div className="p-3 bg-danger text-white bg-danger-main table-red-bg">
                          <div className="img-text">
                            <img src="images/fail.svg" alt="Success" />{' '}
                            {filterErr}
                          </div>
                        </div>
                      )}
                      <Row className="mt-3">
                        <Col sm={4}>
                          <div className="search-bar-input">
                            <TextInput
                              value={searchKeyword}
                              placeholder="Search Keyword"
                              onInputChange={(e) => {
                                setSearchKeyword(e.target.value);
                                setFilterErr('');
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={4}>
                          <DropdownInput
                            placeholder="Select School Year"
                            options={getYearData()}
                            defaultValue={selOptionYear}
                            onSelectHandler={(select) => {
                              setSelOptionYear(select);
                              setFilterErr('');
                            }}
                          />
                        </Col>
                        <Col sm={4}>
                          <DropdownInput
                            placeholder="Select Subject"
                            options={getSubjectData()}
                            inputClass="mx-0"
                            defaultValue={selOptionSubject}
                            onSelectHandler={(select) => {
                              setSelOptionSubject(select);
                              setFilterErr('');
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <UserRestrictions
                            permittedUsers={[1, 2]}
                            roleId={userRole}
                          >
                            <DateTimePicker
                              className="form-control"
                              selected={activationDate}
                              placeholder="Select Activation Date"
                              onChange={(e) => {
                                setActivationDate(e);
                                setFilterErr('');
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </UserRestrictions>
                        </Col>
                        <Col sm={4}>
                          <UserRestrictions
                            permittedUsers={[1, 2]}
                            roleId={userRole}
                          >
                            <DropdownInput
                              placeholder="Select Tutor"
                              options={getTutorData()}
                              inputClass="mx-0"
                              defaultValue={selOptionTutor}
                              onSelectHandler={(select) => {
                                setSelOptionTutor(select);
                                setFilterErr('');
                              }}
                            />
                          </UserRestrictions>
                        </Col>
                        <Col sm={4}>
                          <div className="filter-btn">
                            <Button
                              variant="primary"
                              type="submit"
                              className="ms-4"
                              disabled={disableButton}
                            >
                              Submit
                            </Button>
                            <Button
                              variant="primary"
                              className="ms-4"
                              onClick={() => onClear()}
                            >
                              Clear
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Title>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary mt-2 mb-2">All Student</h4>
                <div className="d-flex align-items-center justify-content-end">
                  <a
                    href=""
                    onClick={() => props.history.push('/student/addStudent')}
                    className="btn-link"
                  >
                    {Number(userRole) === 2 || Number(userRole) === 1 ? 'Add Student' : ''}
                  </a>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="mt-3">
                  <Table
                    role={Number(userRole)}
                    columns={columns}
                    rows={rows}
                    tableClass="table-striped"
                    showPagination
                    showCheckbox={Number(userRole) === 2 || Number(userRole) === 1 ? 'showCheckbox' : ''}
                    paginationProps={{
                      ...pagination,
                      activePage: pagination.current_page,
                      onPageChange: (pageNo) => {
                        getTableData(pageNo);
                      },
                    }}
                    bulkActionsLabel="Apply"
                    bulkActions={Number(userRole) === 2 || Number(userRole) === 1 ? bulkActions : ''}
                    dropdownContainerClass="mb-3"
                    emptyRender={() => (
                      <p className="text-center mb-0">No record found</p>
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default withRouter(Student);
