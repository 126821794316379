import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Card, Form, Col, Row, Button, Image } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
// import generatepassword from 'generate-password';
import swal from 'sweetalert';
import AuthApi from '../../helper/authApi';
import Api from '../../helper/api';
import {
  TextInput,
  RadioInput,
  DropdownInput,
  PhoneInput,
  Helmet,
  PasswordInput
} from '../../components/FormInputs';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb.component';
import CustomFunctions from '../../helper/customFunctions';

const additionalChoices = [
  { value: 1, name: 'Ongoing classes' },
  { value: 2, name: '11 Plus Mock Exams' },
  {
    value: 3,
    name: 'Summer camp ( An introduction to the 11 Plus programme for Year 4 and 5)',
  },
  { value: 4, name: 'Easter Break (Year 11 Exam Paper practice)"' },
];

const examFocusList = [
  { value: '11 Plus', name: '11 Plus' },
  { value: '13 Plus', name: '13 Plus' },
  { value: 'GCSE', name: 'GCSE' },
  { value: 'IGCSE', name: 'IGCSE' },
];

const allergiesList = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const learningDisabilitiesList = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const knowPPLTreeLearning = [
  { value: 'Google', name: 'Google' },
  { value: 'Facebook', name: 'Facebook' },
  { value: 'Leaflet', name: 'Leaflet' },
  { value: 'Library', name: 'Library' },
  { value: 'Bus Panels', name: 'Bus Panels' },
  { value: 'Friends/Family', name: 'Friends/Family' },
  { value: 'E-Leaflet', name: 'E-Leaflet' },
  { value: 'Primary Times', name: 'Primary Times' },
  { value: 'Other', name: 'Other' },
];

const validator = new SimpleReactValidator();

const PersonalDetails = (props) => {
  const history = useHistory();
  const [count, forceUpdate] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [address, setAddress] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [guardianName, setGuardianName] = useState('');
  const [guardianNo, setGuardianContactNo] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [examFocus, setExamFocus] = useState(null);
  const [allergies, setAllergies] = useState('');
  const [learningDisabilities, setLearningDisabilities] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [gcseIcse, setGcseIcse] = useState('');
  const [pplTreeLearning, setPPLTreeLearningValue] = useState('');
  const [yearList, setYearList] = useState([]);
  const [selectOptionYear, setSelectOptionYear] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [guardianEmail, setGuardianEmail] = useState('');
  const [items, setItems] = useState('');
  const [activationDate, setActivateDate] = useState('');
  const [subjectTutor, setSubjectTutor] = useState([
    {
      selectOptionSubject: '',
      selectOptionTutor: '',
      subjectErr: '',
      tutorErr: '',
    },
  ]);
  const [subjectList, setSubjectList] = useState([]);
  const [classId, setClassId] = useState('');
  const [seltutorOptions, setTutorOptions] = useState(null);
  const [subjectTopics, setSubjectTopics] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [showDisabilityText, setShowDisabilityText] = useState(false);
  const [showAllergiesText, setShowAllergiesText] = useState(false);
  const [disabilityText, setDisabilityText] = useState('');
  const [allergiesText, setAllergiesText] = useState('');
  const [showOtherPTLText, setShowOtherPTLText] = useState(false);
  const [otherPTLText, setOtherPTLText] = useState('');
  const [title, setTitle] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordErr, setConfirmPasswordErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');

  useEffect(() => {
    async function getData() {
      const stuId = props.match.params.id;
      setTitle('Add Student');
      if (stuId) {
        setTitle('Edit Student');
        setStudentId(stuId);
        getStudentDetails(stuId);
        getSubjectList();
      }
      getYearList();
      getExamFocusList();
      const breadCrumb = [
        { to: '/', label: 'Home' },
        { to: '/student', label: 'Students' },
        { label: stuId ? 'Edit' : 'Add' },
      ];
      setItems(breadCrumb);
    }
    getData();
  }, []);

  const getStudentDetails = async (id) => {
    const endPoint = `${Api.studentUrl}${id}/`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (!CustomFunctions.checkIfEmpty(data.data, 'O')) {
      const ptlLearnArray = [];
      const examFocusArray = [];

      setLastName(data.data.last_name);
      setFirstName(data.data.first_name);
      setEmailAddress(data.data.email);
      setContactNo(data.data.mobile);
      setGuardianName(data.data.guardian_name);
      setGuardianContactNo(data.data.guardian_contact);
      setAddress(data.data.address);
      setSchoolName(data.data.school_name);
      setSelectOptionYear({
        label: data.data.class_name,
        id: data.data.class,
        value: data.data.class,
      });
      setGcseIcse(data.data.sub_attention);
      if (data.data.exam_focus && data.data.exam_focus.length > 0) {
        data.data.exam_focus &&
          data.data.exam_focus.map((focus) => {
            return examFocusArray.push({
              label: focus,
              id: focus,
              value: focus,
            });
          });
        setExamFocus(examFocusArray);
      }

      if (data.data.additional_details) {
        const result = additionalChoices.find(
          (ad) => ad.value === data.data.additional_details
        );
        setAdditionalDetails({
          label: result ? result.name : '',
          id: data.data.additional_details,
          value: data.data.additional_details,
        });
      }
      if (
        data.data.peepal_tree_learn &&
        data.data.peepal_tree_learn.length > 0
      ) {
        data.data.peepal_tree_learn &&
          data.data.peepal_tree_learn.map((hear) => {
            return ptlLearnArray.push({
              label: hear,
              id: hear,
              value: hear,
            });
          });
        setPPLTreeLearningValue(ptlLearnArray);
      }
      setAllergies(`${data.data.allergies}`);
      setLearningDisabilities(`${data.data.learning_disable}`);
      if (data.data.learning_disable === true) {
        setShowDisabilityText('true');
        setDisabilityText(`${data.data.reg_known_disabilities}`);
      }
      if (data.data.allergies === true) {
        setShowAllergiesText('true');
        setAllergiesText(`${data.data.reg_known_allergies}`);
      }
      setGuardianEmail(data.data.guardian_email);
      setClassId(data.data.class);
      setActivateDate(
        data.data.activation_date
          ? CustomFunctions.getDate(data.data.activation_date)
          : ''
      );
      setSubjectTopics(data.data.subject_topics);
      if (data.data.peepal_tree_learn_other) {
        setShowOtherPTLText(true);
        setOtherPTLText(`${data.data.peepal_tree_learn_other}`);
      }
      const subjectTutorList = [];
      data.data.tutor_subjects &&
        data.data.tutor_subjects.map((subjTutor) => {
          subjectTutorList.push({
            selectOptionSubject: {
              label: `${subjTutor.subject_name}`,
              id: `${subjTutor.subject_id}`,
              value: `${subjTutor.subject_id}`,
            },
            selectOptionTutor: {
              label: `${subjTutor.tutor_name}`,
              id: `${subjTutor.tutor_id}`,
              value: `${subjTutor.tutor_id}`,
            },
            subjectErr: '',
            tutorErr: '',
          });
          setSubjectTutor(subjectTutorList);
          return subjTutor;
        });
        setUserName(data.data.username);
    }
  };

  const addStudent = async () => {
    // const apassword = generatepassword.generate({
    //   length: 10,
    //   numbers: true,
    // });

    const selectPplTreeLearn = [];
    pplTreeLearning &&
      pplTreeLearning.map((op) => {
        return selectPplTreeLearn.push(op.id);
      });

    const selectExamFocus = [];
    examFocus &&
      examFocus.map((op) => {
        return selectExamFocus.push(op.id);
      });

    const postData = {
      first_name: firstName,
      last_name: lastName,
      address,
      mobile: contactNo,
      email: emailAddress,
      g_name: guardianName,
      g_contact: guardianNo,
      learning_disable: learningDisabilities,
      allergies,
      peepal_tree_learn: selectPplTreeLearn,
      school_name: schoolName,
      classes: selectOptionYear.id,
      exam_focus: selectExamFocus,
      additional_details: additionalDetails ? additionalDetails.id : '',
      sub_attention: gcseIcse,
      g_email: guardianEmail,
      subject_topics: subjectTopics,
      username: userName
    };

    if (learningDisabilities) postData.reg_known_disabilities = disabilityText;
    if (allergies) postData.reg_known_allergies = allergiesText;
    if (showOtherPTLText) postData.peepal_tree_learn_other = otherPTLText;

    if(password){
      postData.new_password = password;
    }
    if(confirmPassword){
      postData.confirm_password = confirmPassword;
    }

    const payload = CustomFunctions.cleanObject(postData);

    const callback = studentId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = studentId
      ? `${Api.studentUrl}${studentId}/`
      : `${Api.studentRegistrationUrl}`;

    const { data, message } = await callback(endPoint, payload);

      if (data) {
        if (!CustomFunctions.checkIfEmpty(studentId)) {
          updateStudentSubjectTutor();
        }
        swal(data && data.message[0], '', 'success', {
          closeOnClickOutside: false,
          allowOutsideClick: false,
        }).then((result) => {
          setDisableButton(false);
          if (result) {
            history.push('/student');
          }
        });
    } else {
      setDisableButton(false);
      swal(message, '', 'error');
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (disableButton) return;

    setDisableButton(true);
    const result = submitClassDetails();

    let isValid = true;
    let confirmPassErr = '';
    let passErr = '';

   if(password && !confirmPassword){
      confirmPassErr = 'Please enter confirm password';
      setConfirmPasswordErr(confirmPassErr);
      isValid = false;
    }else{
      setConfirmPasswordErr('');
      isValid = true;
    }

    if(!password && confirmPassword){
      passErr = 'Please enter new password';
      setPasswordErr(passErr);
      isValid = false;
    }else {
      setPasswordErr('');
      isValid = true;
    }

    if(password && confirmPassword && password !== confirmPassword ){
      confirmPassErr = 'New Password and Confirm Password did not match!';
      setConfirmPasswordErr(confirmPassErr);
      isValid = false;
    }

    if (validator.allValid() && !studentId && isValid) {
      addStudent();
    } else if (validator.allValid() && result && studentId && isValid) {
      addStudent();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const getYearList = async () => {
      const { data } = await AuthApi.getDataFromServer(
        `${Api.getTutorClassSubjectByUrl}?status=1&type=all`
      );
      if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
          setYearList(data.data);
      };
  };

  const getYearData = () => {
    if (!yearList) return [];
    const yearOptions = [];
    yearList.map((year) => {
       year.tutor_subjects.map((mat) => {
         return yearOptions.push({
          label: mat.class_name,
          id: mat.class_id,
          value: mat.class_id,
        });
      });
      return yearOptions;
    });
    const key = 'id';
    const uniqueYears = [
      ...new Map(yearOptions.map((item) => [item[key], item])).values(),
    ];
    return uniqueYears;
  };

  const getExamFocusList = () => {
    if (!examFocusList) return [];
    const examFocusListOptions = [];
    examFocusList.map((alg) => {
      return examFocusListOptions.push({
        label: alg.name,
        id: alg.value,
        value: alg.value,
      });
    });
    return examFocusListOptions;
  };

  const getAdditionalChoices = () => {
    if (!additionalChoices) return [];
    const choicesOptions = [];
    additionalChoices.map((tech) => {
      return choicesOptions.push({
        label: tech.name,
        id: tech.value,
        value: tech.value,
      });
    });
    return choicesOptions;
  };

  const getKnowPPLTreeLearning = () => {
    if (!knowPPLTreeLearning) return [];
    const pplTreeLearningOptions = [];
    knowPPLTreeLearning.map((learn) => {
      return pplTreeLearningOptions.push({
        label: learn.name,
        id: learn.value,
        value: learn.value,
      });
    });
    return pplTreeLearningOptions;
  };

  const onCancel = async () => {
    validator.hideMessages();
    await history.push('/student');
  };

  const getAllergies = () => {
    if (!allergiesList) return [];
    const allergiesOptions = [];
    allergiesList.map((alg) => {
      return allergiesOptions.push({
        label: alg.label,
        value: alg.value,
      });
    });
    return allergiesOptions;
  };

  const getLearningDisabilities = () => {
    if (!learningDisabilitiesList) return [];
    const learningDisabilitiesOptions = [];
    learningDisabilitiesList.map((alg) => {
      return learningDisabilitiesOptions.push({
        label: alg.label,
        value: alg.value,
      });
    });
    return learningDisabilitiesOptions;
  };

  const getSubjectData = () => {
    if (!subjectList) return [];
    const subjectOptions = [];
    subjectList &&
      subjectList.map((subject) => {
        subject &&
          subject.tutor_subjects &&
          subject.tutor_subjects.map((dt) => {
            if (Number(dt.class_id) === Number(classId)) {
              return subjectOptions.push({
                label: dt.subject_name,
                id: dt.subject_id,
                value: dt.subject_id,
              });
            }
            return subjectOptions;
          });
        return subject;
      });
    const key = 'id';
    const arrayUniqueByKey = [
      ...new Map(subjectOptions.map((item) => [item[key], item])).values(),
    ];
    return arrayUniqueByKey;
  };

  const submitClassDetails = () => {
    let isValid = true;
    if (subjectTutor.length > 0) {
      subjectTutor.map((st, index) => {
        subjectTutor[index].subjectErr = '';
        subjectTutor[index].tutorErr = '';
        if (!st.selectOptionSubject) {
          subjectTutor[index].subjectErr = 'Please select subject';
          isValid = false;
        }
        if (!st.selectOptionTutor) {
          subjectTutor[index].tutorErr = 'Please select tutor';
          isValid = false;
        }
        if (subjectTutor.length > 1) {
          const validSubjectTutor = subjectTutor.filter(
            (j) =>
              Number(st.selectOptionSubject.id) ===
                Number(j.selectOptionSubject.id) &&
              Number(st.selectOptionTutor.id) === Number(j.selectOptionTutor.id)
          );
          let lastIndex = 0;
          subjectTutor.map((j, i) => {
            if (
              Number(st.selectOptionSubject.id) ===
                Number(j.selectOptionSubject.id) &&
              Number(st.selectOptionTutor.id) === Number(j.selectOptionTutor.id)
            ) {
              lastIndex = i;
            }
            return lastIndex;
          });

          if (validSubjectTutor.length > 1 && lastIndex === index) {
            subjectTutor[index].tutorErr =
              'Duplicate subject & tutor not allowed';
            isValid = false;
          }
        }
        return subjectTutor;
      });
    }
    setSubjectTutor(subjectTutor);
    return isValid;
  };

  const getTutorData = (subjectId, classid) => {
    if (!subjectList) return [];
    const tutorOptions = [];
    subjectList &&
      subjectList.map((subject) => {
        subject &&
          subject.tutor_subjects &&
          subject.tutor_subjects.map((dt) => {
            if (
              Number(dt.class_id) === Number(classid) &&
              Number(dt.subject_id) === Number(subjectId)
            ) {
              return tutorOptions.push({
                label: `${subject.first_name} ${subject.last_name}`,
                id: subject.id,
                value: subject.id,
              });
            }
            setTutorOptions(tutorOptions);
            return tutorOptions;
          });
        return subject;
      });
    return tutorOptions;
  };

  const getSubjectList = async () => {
    const { data } = await AuthApi.getDataFromServer(
      `${Api.getTutorClassSubjectByUrl}?type=all`
    );

    if (!CustomFunctions.checkIfEmpty(data && data.data, 'A')) {
      setSubjectList(data.data);
    }
  };

  const updateStudentSubjectTutor = async () => {
    const tutorStudentSubjectArr = [];
    if (subjectTutor.length > 0) {
      subjectTutor.map((st) => {
        return tutorStudentSubjectArr.push({
          tutor: st.selectOptionTutor.id,
          subject: st.selectOptionSubject.id,
        });
      });
    }

    const postData = {
      student: studentId,
      tutor_subjects: tutorStudentSubjectArr,
    };

    const callback = AuthApi.postDataToServer;
    const endPoint = `${Api.studentTutorSubjectUrl}`;
    const { data, message } = await callback(endPoint, postData);

    if (!data) {
      swal(message, '', 'error');
    }
  };

  const addSubjectTutor = () => {
    const allSubjectTutors = [...subjectTutor];
    allSubjectTutors.push({
      selectOptionSubject: '',
      selectOptionTutor: '',
      subjectErr: '',
      tutorErr: '',
    });
    setSubjectTutor([...allSubjectTutors]);
  };

  const removeSubjectTutor = (index) => {
    const allSubjectTutors = [...subjectTutor];
    const deleteIndex = allSubjectTutors.splice(index, 1);
    if (deleteIndex) {
      swal({
        title: 'Are you sure?',
        text: 'Are you sure that you want to delete this record?',
        icon: 'warning',
        dangerMode: true,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result) {
          setSubjectTutor([...allSubjectTutors]);
        }
      });
    }
  };

  const addOtherPPLText = (selectOption) => {
    const otherOption = selectOption.find((item) => item.value === 'Other');

    if (!CustomFunctions.checkIfEmpty(otherOption)) {
      setShowOtherPTLText(true);
    } else {
      setShowOtherPTLText(false);
    }
  };

  const setLearningDisabilitiesFlag = (learnflag) => {
    if(learnflag === 'true'){
      setShowDisabilityText('true');
    }else{
      setShowDisabilityText('false');
    }
  };

  const getSubjectTutorList = async (classid, studentid) => {
    if (classid){
      setClassId(classid);
    }
    const { data } = await AuthApi.getDataFromServer(
      `${Api.studentTutorSubjectUrl}?student_id=${studentid}&class_id=${classid}`
    );

    const subjectTutorList = [];
    if (!CustomFunctions.checkIfEmpty(data.data, 'A')) {
        data && data.data && data.data.map((subjTutor) => {
          subjectTutorList.push({
            selectOptionSubject: {
              label: `${subjTutor.subject_name}`,
              id: `${subjTutor.subject_id}`,
              value: `${subjTutor.subject_id}`,
            },
            selectOptionTutor: {
              label: `${subjTutor.tutor_full_name}`,
              id: `${subjTutor.tutor_id}`,
              value: `${subjTutor.tutor_id}`,
            },
            subjectErr: '',
            tutorErr: '',
          });
          return subjTutor;
        });
      setSubjectTutor(subjectTutorList);
   } else {
     setSubjectTutor([{
       selectOptionSubject: '',
       selectOptionTutor: '',
       subjectErr: '',
       tutorErr: '',
     }]);
      getSubjectData();

    }
  };

  validator.purgeFields();

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Students</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <section className="content-area">
        <div className="mt-5">
          <Card>
            <Card.Header>
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary mt-2 mb-2">{title}</h4>
                {activationDate !== '' && (
                  <>
                    <div className="p-r-2 font-weight-semibold float-end">
                      Activation Date : {activationDate || ''}
                    </div>
                  </>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Form autoComplete="off" onSubmit={(e) => submitForm(e)}>
                <Row>
                  <h5 className="mb-3">Personal Details</h5>
                  <hr />
                  <Col sm={3}>
                    <TextInput
                      label="First Name"
                      name="firstName"
                      containerClass="form-group"
                      placeholder="First Name"
                      value={firstName || ''}
                      onInputChange={(e) => setFirstName(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'firstName',
                        firstName,
                        'required|alpha_space'
                      )}
                    />
                  </Col>

                  <Col sm={3}>
                    <TextInput
                      label="Last Name"
                      name="lastName"
                      containerClass="form-group"
                      placeholder="Last Name"
                      value={lastName || ''}
                      onInputChange={(e) => setLastName(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'lastName',
                        lastName,
                        'required|alpha_space'
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <TextInput
                      label="Email Address"
                      name="emailAddress"
                      containerClass="form-group"
                      placeholder="Email Address"
                      value={emailAddress || ''}
                      onInputChange={(e) => setEmailAddress(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'emailAddress',
                        emailAddress,
                        'required|email'
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                 <Col sm={3}>
                    <TextInput
                      label="Username"
                      name="userName"
                      containerClass="form-group"
                      placeholder="Username"
                      value={userName || ''}
                      onInputChange={(e) => setUserName(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'userName',
                        userName,
                        'required|regex:^(?=.*)(?=.*[A-Za-z ])[A-Za-z0-9 ]*$',
                        {
                          messages: {
                            required: 'The Username field is required',
                            regex:
                            'Username should be either alphabet or alphanumeric.',
                          },
                        }
                      )}
                      disabled={studentId ? 'disabled' : ''}
                      />

                  </Col>
                  <Col sm={3}>
                    <div className="contact-number">
                      <PhoneInput
                        label="Contact No"
                        name="contactNo"
                        asterisk="*"
                        country="gb"
                        onlyCountries={['in', 'gb']}
                        placeholder="Contact No"
                        value={contactNo || ''}
                        onInputChange={(e) => setContactNo(e)}
                        errorMessage={validator.message(
                          'contactNo',
                          contactNo,
                          'required|phone|min:12'
                        )}
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <TextInput
                      label="Address"
                      name="address"
                      containerClass="form-group"
                      placeholder="Address"
                      value={address || ''}
                      Row={5}
                      as="textarea"
                      onInputChange={(e) => setAddress(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'address',
                        address,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      label="Parent/Guardian Name"
                      name="guardianName"
                      containerClass="form-group"
                      placeholder="Parent/Guardian Name"
                      value={guardianName || ''}
                      onInputChange={(e) => setGuardianName(e.target.value)}
                      asterisk="*"
                      errorMessage={validator.message(
                        'guardianName',
                        guardianName,
                        'required|alpha_space'
                      )}
                    />
                  </Col>
                  <Col>
                    <div className="contact-number">
                      <PhoneInput
                        label="Guardian Contact No"
                        name="guardianNo"
                        asterisk="*"
                        country="gb"
                        onlyCountries={['in', 'gb']}
                        placeholder="Parent/Guardian Contact No"
                        value={guardianNo || ''}
                        onInputChange={(e) => setGuardianContactNo(e)}
                        errorMessage={validator.message(
                          'guardianNo',
                          guardianNo,
                          'required|phone|min:12'
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    <TextInput
                      label="Parent/Guardian Email"
                      name="guardianEmail"
                      containerClass="form-group"
                      placeholder="Parent/Guardian Email"
                      value={guardianEmail || ''}
                      asterisk="*"
                      onInputChange={(e) => setGuardianEmail(e.target.value)}
                      errorMessage={validator.message(
                        'guardianEmail',
                        guardianEmail,
                        'required|email'
                      )}
                    />
                  </Col>
                </Row>
                {
                  studentId && (
                  <>
                    <Row>
                      <Col>
                      <h5 className="mb-3">Change Password <small className='text-secondary font-italic'> (Note: Keep empty If you dont want to change password)</small></h5>

                      <hr />
                      </Col>
                    </Row>
                    <Row>
                     <Col md={6}>
                        <PasswordInput
                            onInputChange={(e) => setPassword(e.target.value)}
                            name="password"
                            containerClass="form-group"
                            inputClass="form-control"
                            label="New Password"
                            value={password || ''}
                            type="password"
                            placeholder="Password"
                            errorMessage={passwordErr || '' }
                            switchElement="true"
                          />
                      </Col>
                       <Col md={6}>
                         <PasswordInput
                           onInputChange={(e) => setConfirmPassword(e.target.value)}
                           name="confirmPassword"
                           containerClass="form-group"
                           inputClass="form-control"
                           label="Confirm Password"
                           value={confirmPassword || ''}
                           type="password"
                           placeholder="Confirm Password"
                           errorMessage={confirmPasswordErr || '' }
                           switchElement="true"
                         />
                       </Col>
                     </Row>
                     </>
                )}

                <Row className="mt-3">
                  <Col>
                    <h5>Academic Details</h5>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <TextInput
                      label="Name of School"
                      name="schoolName"
                      placeholder="Name of School"
                      asterisk="*"
                      value={schoolName || ''}
                      onInputChange={(e) => setSchoolName(e.target.value)}
                      errorMessage={validator.message(
                        'schoolName',
                        schoolName,
                        'required'
                      )}
                    />
                  </Col>

                  <Col sm={6}>
                    <DropdownInput
                      label="School Year"
                      options={getYearData() || []}
                      placeholder="Select School Year"
                      asterisk="*"
                      defaultValue={selectOptionYear || ''}
                      onSelectHandler={(select) => {
                          setSelectOptionYear(select);
                          getSubjectTutorList(select.id, studentId);
                        //  resetSubjectTutor(select.id);
                        }
                      }
                      errorMessage={validator.message(
                        'School Year',
                        selectOptionYear,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <TextInput
                      label="Subject topics that requires further attention"
                      name="subjectTopics"
                      containerClass="form-group"
                      placeholder=""
                      value={subjectTopics || ''}
                      Row={5}
                      as="textarea"
                      onInputChange={(e) => setSubjectTopics(e.target.value)}
                    />
                  </Col>
                  <Col sm={6}>
                    <DropdownInput
                      label="Additional Details Required For"
                      options={getAdditionalChoices() || []}
                      name="additionalDetails"
                      asterisk="*"
                      placeholder="Select..."
                      defaultValue={additionalDetails || ''}
                      onSelectHandler={(select) => setAdditionalDetails(select)}
                      errorMessage={validator.message(
                        'Additional Details',
                        additionalDetails,
                        'required'
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <DropdownInput
                      label="Exam Focus"
                      options={getExamFocusList() || []}
                      placeholder="Select ..."
                      defaultValue={examFocus || ''}
                      asterisk="*"
                      isMulti
                      onSelectHandler={(select) => setExamFocus(select)}
                      errorMessage={validator.message(
                        'Exam Focus',
                        examFocus,
                        'required'
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>
                      GCSE/IGCSE Subject Board(if applicable)
                    </Form.Label>
                    <TextInput
                      name="gcseIcse"
                      containerClass="form-group"
                      placeholder=""
                      value={gcseIcse || ''}
                      onInputChange={(e) => setGcseIcse(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h5>Other Details</h5>
                    <hr />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Label>Any Known Learning Disabilities</Form.Label>
                    <span style={{ color: 'red' }}>*</span>
                    <div>
                      <RadioInput
                        onSelectRadioButton={(e) => {
                           setLearningDisabilities(e.target.value);
                           setLearningDisabilitiesFlag(e.target.value);
                        }}
                        name="learningDisabilities"
                        type="radio"
                        value={learningDisabilities || ''}
                        checked={learningDisabilities}
                        options={getLearningDisabilities() || []}
                        inputClass="form-check-inline"
                        asterisk="*"
                        errorMessage={validator.message(
                          'Learning disability',
                          learningDisabilities,
                          'required'
                        )}
                      />
                      {showDisabilityText === 'true' && (
                        <TextInput
                          name="disabilityText"
                          containerClass="form-group"
                          placeholder=""
                          value={disabilityText}
                          onInputChange={(e) =>
                            setDisabilityText(e.target.value)
                          }
                          errorMessage={
                            showDisabilityText === 'true'
                              ? validator.message(
                                  'disabilityText',
                                  disabilityText,
                                  'required',
                                  {
                                    messages: {
                                      required:
                                        'Known learn disability is required',
                                    },
                                  }
                                )
                              : ''
                          }
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Form.Label>
                      Any Known Allergies/ Current Medication{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <div>
                      <RadioInput
                        onSelectRadioButton={(e) => {
                          setAllergies(e.target.value);
                          setShowAllergiesText(e.target.value);
                        }}
                        name="Allergies"
                        type="radio"
                        value={allergies || ''}
                        checked={allergies}
                        options={getAllergies()}
                        inputClass="form-check-inline"
                        errorMessage={validator.message(
                          'allergies',
                          allergies,
                          'required'
                        )}
                      />
                      {showAllergiesText === 'true' && (
                        <TextInput
                          name="allergiesText"
                          containerClass="form-group"
                          placeholder=""
                          value={allergiesText}
                          onInputChange={(e) =>
                            setAllergiesText(e.target.value)
                          }
                          errorMessage={
                            showAllergiesText.toString() === 'true'
                              ? validator.message(
                                  'allergiesText',
                                  allergiesText,
                                  'required',
                                  {
                                    messages: {
                                      required:
                                        'Known allergy/current medication is required',
                                    },
                                  }
                                )
                              : ''
                          }
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <div>
                      <DropdownInput
                        label="Where did Student hear of Peepal Tree Learning?"
                        options={getKnowPPLTreeLearning() || []}
                        placeholder="Select ..."
                        defaultValue={pplTreeLearning || ''}
                        onSelectHandler={(select) => {
                          addOtherPPLText(select);
                          setPPLTreeLearningValue(select);
                        }}
                        asterisk="*"
                        isMulti
                        errorMessage={validator.message(
                          'Where did Student hear of Peepal Tree Learning',
                          pplTreeLearning,
                          'required'
                        )}
                      />
                      {showOtherPTLText === true && (
                        <TextInput
                          name="otherPTLText"
                          containerClass="form-group"
                          placeholder=""
                          value={otherPTLText}
                          onInputChange={(e) => setOtherPTLText(e.target.value)}
                          errorMessage={
                            showOtherPTLText
                              ? validator.message(
                                  'otherPTLText',
                                  otherPTLText,
                                  'required',
                                  {
                                    messages: {
                                      required: 'This field is required',
                                    },
                                  }
                                )
                              : ''
                          }
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                {studentId && (
                  <>
                    <Row className="mt-3">
                      <Col>
                        <h5>Class Details</h5>
                        <hr />
                      </Col>
                    </Row>

                    <Row className="mt-3 align-items-center">
                      {subjectTutor &&
                        subjectTutor.map((st, index) => (
                          <>
                            <Col sm={4}>
                              <DropdownInput
                                label="Subject"
                                options={getSubjectData() || []}
                                asterisk="*"
                                defaultValue={st.selectOptionSubject || ''}
                                onSelectHandler={(select) => {
                                  subjectTutor[index].selectOptionTutor = '';
                                  subjectTutor[index].selectOptionSubject =
                                    select;
                                  subjectTutor[index].subjectErr = '';
                                  setSubjectTutor([...subjectTutor]);
                                  getTutorData(select.id, classId);
                                }}
                                errorMessage={subjectTutor[index].subjectErr}
                              />
                            </Col>
                            <Col sm={4}>
                              <DropdownInput
                                label="Tutor"
                                asterisk="*"
                                options={seltutorOptions || []}
                                defaultValue={st.selectOptionTutor || ''}
                                onSelectHandler={(select) => {
                                  subjectTutor[index].selectOptionTutor =
                                    select;
                                  subjectTutor[index].tutorErr = '';
                                  setSubjectTutor([...subjectTutor]);
                                }}
                                errorMessage={subjectTutor[index].tutorErr}
                              />
                            </Col>
                            <Col sm={2} className="mt-10">
                              {index !== 0 && (
                                <a
                                  href="/"
                                  className="p-r-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    removeSubjectTutor(index);
                                  }}
                                >
                                  <Image src="/images/delete.png" />
                                </a>
                              )}
                            </Col>
                          </>
                        ))}
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Button
                          variant="primary"
                          onClick={() => addSubjectTutor()}
                        >
                          Add More
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                <div className="float-end">
                  <Button variant="secondary" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="ms-4"
                    disabled={disableButton}
                  >
                    {studentId ? 'Update' : 'Add'}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
};

export default withRouter(PersonalDetails);
