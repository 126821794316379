import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function BarGraphComponent(props) {
  const { data, options, labels } = props;

  return (
    <>
      <Bar labels={labels} data={data} options={options} />
    </>
  );
}
